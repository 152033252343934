import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    width: '100%',
    height: '48px',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#4E5968',
    fontFamily: 'Inter',
    fontWeight: 600,
    backgroundColor: '#F2F4F6;',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#E5E8EB',
    },
    '&:active': {
      backgroundColor: '#F2F4F6;',
    },
  },
})(Button);

interface IProps {
  label: string;
  onClick: () => void;
}

const AuthButton = ({ label, onClick }: IProps) => {
  return (
    <CustomButton disableElevation onClick={onClick} variant='contained'>
      {label}
    </CustomButton>
  );
};

export default AuthButton;
