import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, Button } from '@mui/material';

import { rfidStyle } from './rfidStyle';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ROUTES } from '../../types/routes.enum';
import { useDomain } from '../../hooks/useDomain';
import { useDeleteRFIDCardMutation } from '../../services/rfid.api';
import { Image } from '../ui-components/image.component';
import rfidIcon from '../../assets/icons/rfidIcon.svg';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

export const RFIDDeleteComponent = () => {
  const { i18n } = useTranslation();
  const classes = rfidStyle();
  const { domain } = useDomain();
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const [
    triggerDeleteRFIDCard,
    { isSuccess: isDeleteRFIDCardSuccess, isLoading },
  ] = useDeleteRFIDCardMutation();

  const goToRFIDCardPage = () => {
    navigate(
      `/app/${domain}/${ROUTES.RFID}${chargerName ? `?charger=${chargerName}` : ''
      }`
    );
  };

  useEffect(() => {
    if (isDeleteRFIDCardSuccess) {
      goToRFIDCardPage();
    }
  }, [isDeleteRFIDCardSuccess]);

  const deleteRFIDCard = () => {
    triggerDeleteRFIDCard(id);
  };

  const backAction = () => {
    const route = `/app/${domain}/${ROUTES.RFID}`;
    navigate(route);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('delete_rfid_card')}
        onBackArrowClicked={backAction}
      />
      <Box
        component='div'
        p={3}
        style={{
          backgroundColor: '#FAFAFA',
          height: '90%',
        }}
      >
        <Typography
          variant='subtitle1'
          component='span'
          color='textPrimary'
          className={classes.textHeader}
        >
          {i18n.t('delete_rfid_card_confirmation')}
        </Typography>
        <Box marginBottom={2} marginTop={2}>
          <Grid container alignItems='center'>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Image
                src={rfidIcon}
                style={{
                  marginRight: '10px',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' component='span' color='textPrimary'>
                {id}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant='body1' style={{ color: '#202223' }}>
          {i18n.t('rfid_no_longer_usage')}
        </Typography>
      </Box>
      <Grid container className={classes.root}>
        <Grid className={classes.wHalf}>
          <Button
            className={`${classes.button} ${classes.deleteButtonContainer}`}
            onClick={deleteRFIDCard}
            disabled={isLoading}
          >
            {i18n.t('delete')}
            {isLoading && (
              <LoadingDots />
            )}
          </Button>
        </Grid>
        <Grid className={classes.wHalf}>
          <Button
            className={`${classes.button} ${classes.cancelBtn}`}
            onClick={() => navigate(-1)}
            disabled={isLoading}
          >
            {i18n.t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};
