import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Divider } from '@mui/material';

import { Image } from '../ui-components/image.component';
import { ROUTES } from '../../types/routes.enum';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import arrowRight from '../../assets/icons/arrowRight.svg';
import arrowRightDisabled from '../../assets/icons/arrowRightDisabled.svg';
import { useDomain } from '../../hooks/useDomain';
import { useFetchRFIDCardsQuery } from '../../services/rfid.api';
import { NUMBER } from '../../constant/number.constant';
import { rfidStyle } from './rfidStyle';

export const RFIDAddComponent = () => {
  const navigate = useNavigate();
  const { domain } = useDomain();
  const { i18n } = useTranslation();
  const classes = rfidStyle();
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const { data: orderedCard, isLoading: isOrderedCardDataLoading } =
    useFetchRFIDCardsQuery(undefined);

  const isOrderCrossedLimit = orderedCard?.outstandingCardOrders > NUMBER.FOUR;

  const handleOrderCardClick = () => {
    if (!isOrderCrossedLimit)
      navigate({
        pathname: `/app/${domain}/${ROUTES.ORDER_CARD}${
          chargerName ? `?charger=${chargerName}` : ''
        }`,
      });
  };

  const handleActivateCardClick = () => {
    navigate({
      pathname: `/app/${domain}/${ROUTES.ACTIVATE_CARD}${
        chargerName ? `?charger=${chargerName}` : ''
      }`,
    });
  };

  const ACTIVATE_OR_ORDER = [
    {
      title: i18n.t('rfid_activate_existing_card'),
      description: i18n.t('rfid_activate_existing_card_description'),
      action: handleActivateCardClick,
      isDisabled: false,
    },
    {
      title: i18n.t('rfid_order_new_card'),
      description: i18n.t('rfid_order_card_description'),
      action: handleOrderCardClick,
      isDisabled: isOrderCrossedLimit,
    },
  ];
  const backAction = () => {
    navigate(-1);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('add_rfid_card')}
        onBackArrowClicked={backAction}
      />
      {!isOrderedCardDataLoading && (
        <Box
          component='div'
          p={3}
          style={{ cursor: 'pointer' }}
        >
          {ACTIVATE_OR_ORDER.map((ele, index) => (
            <>
              <Grid
                container
                key={index}
                onClick={() => ele.action()}
                justifyContent='space-between'
              >
                <div style={{ width: '80%' }}>
                  <Typography
                    className={classes.subtitle1}
                    component='span'
                    style={
                      ele.isDisabled
                        ? { color: '#D1D6DB' }
                        : { color: 'textPrimary' }
                    }
                  >
                    {ele.title}
                  </Typography>
                  <Typography
                    className={classes.body1}
                    component='div'
                    paragraph
                    style={
                      ele.isDisabled
                        ? { color: '#D1D6DB' }
                        : { color: '#4E5968' }
                    }
                  >
                    {ele.description}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    src={ele.isDisabled ? arrowRightDisabled : arrowRight}
                    style={{
                      marginLeft: '24px',
                      marginBottom: '10px',
                    }}
                  />
                </div>
              </Grid>
              {index !== ACTIVATE_OR_ORDER.length - 1 && (
                <Box mb={3}>
                  <Divider />
                </Box>
              )}
            </>
          ))}
        </Box>
      )}
    </ViewWrapper>
  );
};
