import { Backdrop } from '@mui/material';
import React from 'react';
import { LoadingDots } from './loading-dots/loading-dots.component';

export class LoadingSpinner extends React.Component {
  static Instance;

  static hide() {
    LoadingSpinner.Instance.setState({
      open: false,
    });
  }

  static show() {
    if (LoadingSpinner.Instance) {
      LoadingSpinner.Instance.setState({
        open: true,
      });
    } else {
      console.warn('No modal found');
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    LoadingSpinner.Instance = this;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state as any;

    return (
      <Backdrop style={{ zIndex: 999, color: '#fff', backgroundColor: 'transparent' }} open={open}>
        <LoadingDots />
      </Backdrop>
    );
  }
}
