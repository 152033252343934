export const readableDuration = (durationInSeconds: number) => {
  var totalMinutes = Math.floor(durationInSeconds / 60);
  var hour = Math.floor(totalMinutes / 60);
  var minute = totalMinutes % 60;

  if (hour < 1) {
    return `${minute} min`;
  }
  return `${hour} hr ${minute} min`;
};
