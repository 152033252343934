import {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLazyFetchDriverSupportSettingsByChargerIdQuery } from '../services/env.api';
import { ROUTES } from '../types/routes.enum';

type ContextType = {
  domain: string;
};

const initalState: ContextType = {
  domain: 'chargelab',
};

const domainContext = createContext(initalState);

export const useDomain = () => {
  return useContext(domainContext);
};

export const DomainProvider = (props: any) => {
  const pathname = window.location.pathname;
  const { children } = props;
  const [domain, setDomain] = useState('');
  const [triggerFetchDomainByChargerId, { data, isSuccess }] =
    useLazyFetchDriverSupportSettingsByChargerIdQuery();

  useEffect(() => {
    if (isSuccess) {
      setDomain(data.subdomain);
      window.domainName = data.subdomain;
    }
  }, [isSuccess]);

  useEffect(() => {
    // get domain
    let domainName;
    if (
      /^(?!www|app|uat)([a-zA-Z0-9]+)\.charge\.id$/.test(
        window.location.hostname,
      )
    ) {
      // try to get domainName from hostname: xxx.charge.id
      domainName = window.location.hostname.split('.')[0];
    } else if (window.location.pathname.startsWith('/app/')) {
      // try to get domain from pathname: sudomain.charger.id/app/sudomain/
      domainName = window.location.pathname.split('/')[2];
    }

    if (domainName) {
      // found the domainName
      setDomain(domainName);
      window.domainName = domainName;
      return;
    }

    // Can not found domainName, try to find charger id and fetch the domain name
    const isCharger =
      pathname !== ROUTES.ROOT &&
      !Object.values(ROUTES).some((routename) =>
        pathname.startsWith(`/${routename}`),
      );
    if (isCharger) {
      const chargerName = pathname.substring(1);
      triggerFetchDomainByChargerId(chargerName);
    }
  }, []);

  // Avoid checking receipts path for domain name
  if (!domain && !pathname.startsWith(`/${ROUTES.RECEIPTS}`)) {
    return null;
  }

  return (
    <domainContext.Provider value={{ domain }}>
      {children}
    </domainContext.Provider>
  );
};
