import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import 'moment/locale/fr';
import 'moment/locale/es';

import { SessionStyle } from './styles/styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
interface IProps {
  classes: ClassNameMap;
}
const ChargerRestarted = ({ classes }: IProps) => {
  const { i18n } = useTranslation();

  return (
      <Box style={{ borderRadius: '8px', backgroundColor: '#F2F4F6', padding: '16px 12px 16px 12px',}}>
      <Typography className={classes.heading} style={{
        display: 'flex',
        paddingBottom: '8px',
      }}>
        <WarningRoundedIcon style={{ marginRight: '5px', color: '#FF8F00' }} />
        <Typography className={classes.subHeader}>{i18n.t('charging_restarted')}</Typography>
      </Typography>
      <Typography className={classes.message2}>
        {i18n.t('charging_restarted_message')}
      </Typography>
      </Box>
  );
};

export default withStyles(SessionStyle)(ChargerRestarted);
