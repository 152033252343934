import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDomain } from '../../hooks/useDomain';
import { authStyles } from '../login/authStyles';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import AuthHeader from '../ui-components/auth-header.component';
import AddIdButtons from './add-id-buttons.component';
import clsx from 'clsx';
// import AddPayment from "./add-payment.component";

interface IProps {
  associationId: string;
  error: string;
  isLoading: boolean;
  isSubmitIsSuccess: boolean;
  setAssociationId: Function;
  submit: Function;
  logOut: Function;
}

const AssociationIdForm = ({
  associationId,
  isLoading,
  isSubmitIsSuccess,
  error,
  logOut,
  setAssociationId,
  submit,
}: IProps) => {
  const { i18n } = useTranslation();
  const classes = authStyles();
  const { domain } = useDomain();
  return (
    <div className={classes.authWrapper}>
      {isSubmitIsSuccess ? (
        <AuthHeader
          secondaryText={i18n.t('setup_successfull')}
          description={i18n.t('home_id_successfull_description', {
            companyName: `${domain.charAt(0).toUpperCase()}${domain.slice(1)}`,
          })}
          secondaryTextClassName={classes.ivySecondartyText}
          descriptionTextClassName={classes.ivyDescText}
        />
      ) : (
        <>
          <AuthHeader
            secondaryText={i18n.t('submit_home_id', {
              companyName: `${domain.charAt(0).toUpperCase()}${domain.slice(
                1
              )}`,
            })}
            description={i18n.t('submit_home_id_description', {
              companyName: `${domain.charAt(0).toUpperCase()}${domain.slice(
                1
              )}`,
            })}
            secondaryTextClassName={classes.ivySecondartyText}
            descriptionTextClassName={classes.ivyDescText}
          />
          <label
            style={{
              fontWeight: 600,
              fontFamily: 'Inter',
              fontSize: 16,
              lineHeight: '24px',
            }}
          >
            {i18n.t('ivy_home_id')}
          </label>
          <div className={clsx(classes.formInput, classes.mt8)}>
            <CustomTextField
              style={{ width: '100%' }}
              onChange={(e) => setAssociationId(e.target.value)}
              InputProps={{
                style: {
                  color: '#4E5968',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: 24,
                },
                placeholder: i18n.t('enter_home_id', {
                  companyName: `${domain.charAt(0).toUpperCase()}${domain.slice(
                    1
                  )}`,
                }),
              }}
              variant='outlined'
              required
              autoFocus
              helperText={error}
              value={associationId}
              id='outlined-error-helper-text'
              error={error.length}
              disabled={isLoading}
            />
          </div>
          <AddIdButtons loading={isLoading} logOut={logOut} submit={submit} />
        </>
      )}
    </div>
  );
};

export default AssociationIdForm;
