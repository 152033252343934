import React from 'react';
import { Portal, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export class Alert extends React.Component {
  static Instance;

  static hide() {
    Alert.Instance.setState({
      open: false,
    });
  }

  static show({ message, close = false }) {
    if (Alert.Instance) {
      Alert.Instance.setState({
        open: true,
        message,
        close,
      });
    } else {
      console.warn('No modal found');
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      message: '',
    };

    Alert.Instance = this;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, message, close } = this.state as any;

    return (
      <Portal>
        <Snackbar
          autoHideDuration={close ? 1000 * 60 : 3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          message={message}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Portal>
    );
  }
}
