import * as React from 'react';
const SvgConnectorCcs = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 40 40'
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M25.995 7.468a8.015 8.015 0 0 0-5.567-2.397 8.025 8.025 0 0 0-5.897 2.397 8.013 8.013 0 0 0-2.342 5.608 7.988 7.988 0 0 0 2.342 5.718 7.974 7.974 0 0 0 5.608 2.412h.096c4.451 0 8.089-3.61 8.089-8.047 0-2.15-.827-4.175-2.329-5.69Zm-2.48.193h.027a2.373 2.373 0 0 1 2.398 2.398c0 .647-.248 1.267-.703 1.722a2.364 2.364 0 0 1-1.694.703h-.014a2.42 2.42 0 0 1-2.398-2.412 2.417 2.417 0 0 1 2.384-2.41Zm-8.295.717a2.408 2.408 0 0 1 1.722-.717h.014a2.441 2.441 0 0 1 2.412 2.412c0 .633-.248 1.226-.717 1.694a2.421 2.421 0 0 1-1.722.73h-.014c-1.295 0-2.398-1.102-2.411-2.41a2.52 2.52 0 0 1 .716-1.71Zm.468 8.363h-.04c-.828 0-1.544-.702-1.558-1.543a1.569 1.569 0 0 1 .455-1.143 1.552 1.552 0 0 1 1.116-.469c.854 0 1.557.703 1.57 1.557 0 .854-.688 1.57-1.543 1.598Zm4.603 3.018h-.028c-1.336 0-2.425-1.075-2.425-2.398a2.429 2.429 0 0 1 2.425-2.425h.014c1.309 0 2.384 1.075 2.397 2.398a2.42 2.42 0 0 1-2.383 2.425Zm4.56-3.018h-.04c-.855 0-1.572-.716-1.572-1.57 0-.855.703-1.557 1.557-1.557h.014c.854 0 1.557.689 1.57 1.543.029.84-.66 1.557-1.528 1.584Z'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M16.97 10.761a.704.704 0 0 0 .661-.73c-.013-.372-.358-.675-.73-.661-.372.013-.675.358-.661.73.027.386.358.689.73.661ZM23.57 10.761a.704.704 0 0 0 .661-.73c-.013-.372-.358-.675-.73-.661-.372.013-.675.358-.661.73.027.386.358.689.73.661ZM20.332 18.037c.372-.042.648-.4.62-.772a.716.716 0 0 0-.772-.62.716.716 0 0 0-.62.772c.042.385.4.661.772.62Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M26.353 25.808H14.16c-2.907 0-5.291 2.383-5.291 5.29v1.144c0 2.908 2.384 5.291 5.291 5.291h12.194c2.908 0 5.291-2.383 5.291-5.29v-1.144c-.013-2.908-2.383-5.291-5.29-5.291ZM15.62 36.032c0-.235-.055-.441-.18-.58-.206-.233-.51-.233-.633-.233h-.413c-.11 0-.414 0-.62.234-.125.151-.18.344-.166.578-.014 0-.028-.013-.041-.013-2.37-.565-3.941-2.852-3.583-5.222.358-2.315 2.329-3.996 4.699-3.996.137 0 .261 0 .4.014 2.342.179 4.174 2.204 4.188 4.615.014 2.233-1.488 4.12-3.651 4.603Zm11.298 0c0-.235-.055-.441-.179-.58-.207-.233-.51-.233-.634-.233h-.413c-.11 0-.413 0-.62.234-.124.151-.18.344-.165.578-.014 0-.028-.013-.042-.013-2.37-.565-3.94-2.852-3.582-5.222.358-2.315 2.328-3.996 4.698-3.996.138 0 .262 0 .4.014 2.342.179 4.175 2.204 4.189 4.615.014 2.233-1.488 4.12-3.652 4.603Z'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M14.6 32.89a1.46 1.46 0 1 0 0-2.921 1.46 1.46 0 0 0 0 2.92ZM25.913 32.89a1.46 1.46 0 1 0 0-2.921 1.46 1.46 0 0 0 0 2.92Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M26.74 23.355h-2.88c4.519-1.612 7.688-6.131 7.178-11.299-.4-4.147-3.28-7.62-7.04-9.038V.73c0-.4-.332-.73-.731-.73a.73.73 0 0 0-.717.648h-4.505A.73.73 0 0 0 17.328 0c-.4 0-.73.33-.73.73V2.95C12.409 4.45 9.405 8.446 9.405 13.145c0 4.712 3.032 8.722 7.234 10.21h-2.88a7.51 7.51 0 0 0-7.509 7.51v1.625A7.51 7.51 0 0 0 13.76 40h12.98a7.51 7.51 0 0 0 7.509-7.51v-1.625a7.51 7.51 0 0 0-7.51-7.51Zm6.282 9.135c0 3.459-2.824 6.284-6.283 6.284H13.76c-3.458 0-6.283-2.825-6.283-6.284v-1.625c0-3.459 2.825-6.284 6.283-6.284h4.547a.481.481 0 0 0 .441-.482v-1.075a.491.491 0 0 0-.44-.482v-.014c-4.368-.909-7.675-4.74-7.675-9.383 0-5.305 4.313-9.618 9.617-9.618 5.305 0 9.618 4.313 9.618 9.618 0 4.63-3.307 8.502-7.675 9.411a.481.481 0 0 0-.44.482v1.075c0 .248.192.44.426.468h4.561c3.459 0 6.283 2.825 6.283 6.284v1.625Z'
    />
  </svg>
);
export default SvgConnectorCcs;
