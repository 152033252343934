import { createSelector } from 'reselect';
import { Theme } from '../types/theme.interface';
import { envApi } from '../../services/env.api';

const initStore = {
  primary: '#18A0D7',
  secondary: '#E8F7FC',
  // TODO: CB setup default networkLogo
  // networkLogo: chargelab,
  networkLogo: '',
  chartColor: '#18A0D7',
  navigationSelectedColor: '#18A0D7',
  btnHoverColor: '#117DB8',
  secondaryBtnBgColor: '#E8F7FC',
} as Theme;

export const getTheme = createSelector(
  [envApi.endpoints.fetchThemeSettings.select(undefined)],
  (endpoint) => endpoint.data || initStore,
);
