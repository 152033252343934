import { useContext } from 'react';
import PaymentDetails from './payment-details-component';
import ManagePayment from './manage-payment.component';
import { PaymentMode } from './types/payment-mode.enum';
import { PaymentContext } from '../../hooks/usePayment';
import DeletePayment from './delete-payment.component';

const PaymentWrapper = () => {
  const paymentConsumer = useContext(PaymentContext);
  const { paymentView } = paymentConsumer;

  return (
    <>
      {paymentView === PaymentMode.NONE && <PaymentDetails />}
      {paymentView === PaymentMode.PAYMENT_ADD && <ManagePayment />}
      {paymentView === PaymentMode.PAYMENT_DELETE && <DeletePayment />}
    </>
  );
};

export default PaymentWrapper;
