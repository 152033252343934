import parsePhoneNumber from 'libphonenumber-js';
import * as _ from 'lodash';
import moment from 'moment';

const monthNames = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const submitIfEnterIsClicked = (event, functionToExecute) => {
  if (event.keyCode === 13) {
    functionToExecute();
  }
};

export const API_URL = '/api';
export const Assets_URL = '/assets';

export const decimalToHexColor = (input: number) =>
  `#${input.toString(16).substr(0, 6)}`;

export const lightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const formatPhoneNumber = (phone) =>
  !phone
    ? ''
    : parsePhoneNumber(`+${_.replace(phone, '+', '')}`).formatNational();

/**
 * If the phone number inputted doesn't have a + sign, we will append +1 as a default
 */
export const defaultToPlusOneCountryCode = (phoneNumber) => {
  if (phoneNumber && phoneNumber.indexOf('+') > -1) {
    return phoneNumber;
  }
  return '+1' + phoneNumber;
};

export const parsePhoneNumberWithPlus = (phoneNumber: string) => {
  return `+${_.replace(
    _.replace(_.replace(phoneNumber, '-', ''), ' ', ''),
    '+',
    ''
  )}`;
};

// export const setCookie = (key, value) => {
//   Cookies.set(key, value);
// }

export const getMonthName = (month: number) => monthNames[month];

export const formatDate = (date: Date, dateFormat: string = 'LLL') =>
  moment(date).format(dateFormat);

export const truncateString = (
  fullStr: string,
  strLen: number = 8,
  separator: string = '...'
) => {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
