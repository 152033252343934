import * as React from 'react';
const SvgConnectorChademo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 40 40'
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M25.78 21.454a1.585 1.585 0 1 0 0-3.17 1.585 1.585 0 0 0 0 3.17ZM14.215 21.265a1.585 1.585 0 1 0 0-3.17 1.585 1.585 0 0 0 0 3.17ZM17.62 13.64a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM22.374 13.687a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM19.974 16.04a1.224 1.224 0 1 0 0-2.447 1.224 1.224 0 0 0 0 2.448ZM20.02 11.287a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM17.62 28.342a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM22.374 28.389a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM19.974 30.743a1.224 1.224 0 1 0 0-2.448 1.224 1.224 0 0 0 0 2.448ZM20.02 25.988a1.224 1.224 0 1 0 .001-2.448 1.224 1.224 0 0 0 0 2.448Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='m31.098 12.714.487-2.118-2.668-2.573-1.992.675a13.18 13.18 0 0 0-6.716-1.946 13.362 13.362 0 0 0-7.123 1.946l-1.993-.675-2.667 2.573.486 2.118a12.89 12.89 0 0 0-2.165 7.25c-.047 7.201 5.774 13.18 12.976 13.32h.282c3.578 0 6.92-1.38 9.43-3.89 2.495-2.496 3.86-5.806 3.876-9.337-.032-2.667-.785-5.193-2.213-7.343ZM20.005 9.231a3.224 3.224 0 0 1 3.233 3.217 3.224 3.224 0 0 1-3.233 3.216 3.224 3.224 0 0 1-3.232-3.216 3.224 3.224 0 0 1 3.232-3.217Zm-5.774 15.016H14.2c-2.51-.016-4.629-2.119-4.629-4.582 0-1.208.487-2.338 1.365-3.2a4.641 4.641 0 0 1 3.28-1.334c1.24 0 2.416.486 3.295 1.365.863.863 1.349 1.992 1.333 3.185 0 2.479-2.118 4.566-4.613 4.566Zm5.774 6.103a3.224 3.224 0 0 1-3.232-3.216 3.224 3.224 0 0 1 3.232-3.217 3.224 3.224 0 0 1 3.233 3.217c0 1.773-1.46 3.216-3.233 3.216Zm9.07-7.28a4.693 4.693 0 0 1-3.296 1.35 4.642 4.642 0 0 1-3.295-1.366c-.878-.863-1.349-1.992-1.333-3.185.015-2.479 2.134-4.566 4.628-4.566h.016c2.51 0 4.629 2.103 4.629 4.582a4.466 4.466 0 0 1-1.35 3.185Z'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M19.974 36.25h-.33c-8.833-.157-15.956-7.469-15.894-16.287 0-3.106.33-5.428 1.977-7.986l-.33-3.044L9.87 4.917l2.683.486c2.432-1.46 4.44-1.695 7.704-1.648 2.761.048 4.973.377 7.405 1.836l3.076-.33 3.97 3.939-.33 2.777c1.569 2.495 1.867 4.707 1.867 8.096a16.193 16.193 0 0 1-4.738 11.423c-3.06 3.06-7.155 4.754-11.533 4.754ZM6.56 9.373l.298 2.87-.11.158c-1.6 2.432-1.898 4.534-1.898 7.563-.063 8.221 6.574 15.03 14.811 15.188h.314c4.08 0 7.892-1.585 10.748-4.456a15.09 15.09 0 0 0 4.425-10.638c0-3.264-.283-5.335-1.789-7.673l-.11-.157.298-2.636-3.216-3.185-2.919.314-.157-.094c-2.306-1.412-4.33-1.726-7.029-1.773-3.107-.047-4.99.188-7.28 1.584l-.173.11-2.557-.455-3.656 3.28Z'
    />
  </svg>
);
export default SvgConnectorChademo;
