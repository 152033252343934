import { USER_ROLE } from '../hooks/useAuth';
import { Transaction } from '../stores/types/transaction.interface';
import { API_KEYS, Api } from './base.api';

export const transactionsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchTransactionById: builder.query<Transaction, string>({
      query: (transactionId) => ({
        url: `/core/v1/${API_KEYS.TRANSACTIONS}/${transactionId}`,
        method: 'GET',
        params: {
          role: USER_ROLE.DRIVER,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.TRANSACTIONS, id: result?.transactionId },
      ],
    }),
  }),
});

export const {
  useFetchTransactionByIdQuery,
  useLazyFetchTransactionByIdQuery,
} = transactionsApi;
