import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { State } from 'country-state-city';
import clsx from 'clsx';

import { useAuth } from '../../../hooks/useAuth';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import { ViewWrapper } from '../../ui-components/view-wrapper.component';
import {
  useFetchRFIDCardsQuery,
  useOrderRFIDCardMutation,
} from '../../../services/rfid.api';
import { OrderConfirmation } from './orderConfirmation.component';
import { rfidStyle } from '../rfidStyle';
import ActionButton from '../../user/action-button.component';
import { OrderCardForm } from './orderCardForm.component';
import { NUMBER } from '../../../constant/number.constant';
import { ROUTES } from '../../../types/routes.enum';
import { useFetchDriverSupportSettingsByDomainQuery } from '../../../services/env.api';
import { getAllCountries } from '../../payments/utils';

export const OrderCard = () => {
  const { i18n } = useTranslation();
  const classes = rfidStyle();
  const navigate = useNavigate();
  const auth = useAuth();

  const { data: orderedCard } = useFetchRFIDCardsQuery(undefined);

  const { given_name, family_name, address } = auth?.user?.attributes || {};

  const { data: driverSettings } = useFetchDriverSupportSettingsByDomainQuery();

  const [triggerOrderRFIDCard, { isSuccess, isLoading }] =
    useOrderRFIDCardMutation();

  const isFirstOrder = orderedCard?.outstandingCardOrders <= NUMBER.ZERO;

  const [mailingDetails, setMailingDetails] = useState({
    name: `${given_name ? given_name : ''} ${family_name ? family_name : ''}`,
    street: address?.addressLine1 || '',
    unit: address?.addressLine2 || '',
    city: address?.city || '',
    province: address?.province || '',
    country: address?.country || 'Canada',
    zipCode: address?.postalCode || '',
    whiteLabellingId: '',
    isoCode: address?.isoCode || 'CA',
  });

  const [countryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [provinceDropdownVisible, setProvinceDropdownVisible] = useState(false);

  const handleInputChange = (key: string, value: string) => {
    setMailingDetails({ ...mailingDetails, [key]: value });
  };

  const handleSaveValidation = () => {
    const { name, country, province, city, zipCode, street } = mailingDetails;

    return !name || !country || !province || !city || !zipCode || !street;
  };

  const onButtonClick = () => {
    if (isSuccess) {
      navigate(`/app/${window.domainName}/${ROUTES.ACCOUNT}`);
    } else {
      triggerOrderRFIDCard({
        ...mailingDetails,
        whiteLabellingId: driverSettings?.whiteLabellingId,
      });
    }
  };

  const onClickCountryDropdown = (ele) => {
    setCountryDropdownVisible(false);
    setMailingDetails({
      ...mailingDetails,
      isoCode: ele.isoCode,
      country: ele.name,
      province: '',
    });
  };

  const countryDropdown = () => {
    const countries = getAllCountries();
    return countries.map((ele) => (
      <div
        onClick={() => onClickCountryDropdown(ele)}
        className={clsx({
          [classes.dropdownMenuItem]: true,
          [classes.dropdownMenuItemHover]: true,
          [classes.dropDownSelectedItem]:
            mailingDetails.isoCode === ele.isoCode,
        })}
        style={{ textAlign: 'left' }}
        key={ele.name}
      >
        {ele.name}
      </div>
    ));
  };

  const handleCountryDropdown = () => {
    setCountryDropdownVisible(!countryDropdownVisible);
    setProvinceDropdownVisible(
      provinceDropdownVisible
        ? !provinceDropdownVisible
        : provinceDropdownVisible
    );
  };

  const handleProvinceDropdown = () => {
    setCountryDropdownVisible(
      countryDropdownVisible ? !countryDropdownVisible : countryDropdownVisible
    );
    setProvinceDropdownVisible(!provinceDropdownVisible);
  };

  const onClickProvinceDropdown = (ele) => {
    setProvinceDropdownVisible(false);
    if (!mailingDetails.isoCode) {
      setMailingDetails({
        ...mailingDetails,
        isoCode: 'CA',
        country: 'Canada',
        province: ele.isoCode,
      });
    } else {
      handleInputChange('province', ele.isoCode);
    }
  };

  const provinceDropdown = () => {
    const province = State.getStatesOfCountry(mailingDetails.isoCode || 'CA');
    return province.map((ele) => (
      <div
        onClick={() => onClickProvinceDropdown(ele)}
        className={clsx({
          [classes.dropdownMenuItem]: true,
          [classes.dropdownMenuItemHover]: true,
          [classes.dropDownSelectedItem]:
            mailingDetails.province === ele.isoCode,
        })}
        style={{ textAlign: 'left' }}
        key={ele.isoCode}
      >
        {ele.isoCode}
      </div>
    ));
  };

  const goBackInHistory = () => {
    navigate(-1);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('rfid_order_card')}
        onBackArrowClicked={goBackInHistory}
      />
      <div style={{ padding: '20px', paddingBottom: '104px' }}>
        {isSuccess ? (
          <OrderConfirmation />
        ) : (
          <>
            <OrderCardForm
              mailingDetails={mailingDetails}
              countryDropdownVisible={countryDropdownVisible}
              countryDropdown={countryDropdown}
              handleInputChange={handleInputChange}
              provinceDropdownVisible={provinceDropdownVisible}
              provinceDropdown={provinceDropdown}
              handleProvinceDropdown={handleProvinceDropdown}
              handleCountryDropdown={handleCountryDropdown}
              isFirstOrder={isFirstOrder}
            />
          </>
        )}
      </div>
      <ActionButton
        label={isSuccess ? i18n.t('got_it') : i18n.t('rfid_order_card')}
        action={onButtonClick}
        isDisabled={handleSaveValidation()}
        loading={isLoading}
      />
    </ViewWrapper>
  );
};
