import { makeStyles } from '@mui/styles';

export const languageStyles = makeStyles(() => ({
  formWrapper: {
    maxWidth: 425,
    width: '100%',
    paddingTop: '24px',
  },
  laguageItem: {
    height: '56px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    marginBottom: '8px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  checkBox: {
    border: '2px solid #E5E8EB',
    background: '#ffffff',
  },
  selectedCheckbox: {
    border: '2px solid',
    background: '#E8F7FC',
  },
  label: {},
}));
