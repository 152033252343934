import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Grid } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';

import { ChargerDtoV2, CostBreakdownItem, Session } from '../dto/charger.dto';
import { ReceiptDto, ReceiptLineItemDto } from '../dto/receipt.dto';
import SessionListItem from './session-list-Item.component';
import { DetailsStyles } from './styles/detailsStyles';
import { sessionPricingType } from './utils';
import Receipt from '../../../assets/icons/Receipt.svg';
import { PricingType } from '../../../stores/types/price.interface';
import { RuleTypes } from '../../../stores/types/price.interface';
import clsx from 'clsx';
import { useMemo } from 'react';

interface IProps {
  classes: ClassNameMap;
  session: Session;
  isChargingBySelf?: boolean;
  charger: ChargerDtoV2;
  receipt?: ReceiptDto;
}

const ChargerSessionDetails = ({
  classes,
  session,
  isChargingBySelf = false,
  charger,
  receipt,
}: IProps) => {
  const { i18n } = useTranslation();
  const activeSessionLineItems = session?.runningTotals?.costBreakdown || [];
  const receiptChargingLineItems = receipt?.chargingLineItems || [];
  const receiptIdleLineItems = receipt?.idleLineItems || [];

  const fixedPrice = (value) => value?.toFixed(2);

  // calculate sum of accumulated price of charing sessions
  // If there is no receipt, session is still active and we will use activeSessionLineItems
  // If there is a receipt, session is not active anymore and we will use receiptChargingLineItems
  const chargingPrice = () => {
    let price = 0;
    if (!receipt) {
      for (let i = 0; i < activeSessionLineItems.length; i++) {
        if (activeSessionLineItems[i].pricingType !== PricingType.IDLE) {
          price += activeSessionLineItems[i]?.periodCost;
        }
      }
    } else {
      for (let i = 0; i < receiptChargingLineItems.length; i++) {
        price += receiptChargingLineItems[i]?.totalPrice;
      }
    }
    return price;
  };

  // If there is no receipt, session is still active and we will use activeSessionLineItems
  // If there is a receipt, session is not active anymore and we will use receiptIdleLineItems
  const idlePrice = () => {
    let price = 0;
    if (!receipt) {
      for (let i = 0; i < activeSessionLineItems.length; i++) {
        if (activeSessionLineItems[i].pricingType === PricingType.IDLE) {
          price += activeSessionLineItems[i]?.periodCost;
        }
      }
    } else {
      for (let i = 0; i < receiptIdleLineItems.length; i++) {
        price += receiptIdleLineItems[i]?.totalPrice;
      }
    }
    return price;
  };

  // calculate running cost on charget connector.
  // it must be sum of idling, base and charing free.
  // base and idling is not available at the moment.
  const runningCost = useMemo(() => {
    if (receipt?.total !== undefined) return receipt.total;
    if (session) return chargingPrice() + idlePrice();
    return 0;
  }, [receipt?.total, session]);

  // customise hours details text for session items.

  const readableDuration = (
    durationInSeconds: number,
    pricingType: string,
    energyConsumed: number,
  ) => {
    if (pricingType === PricingType.BILLED_BY_KWH) {
      return `${energyConsumed.toFixed(4)} kWh`;
    }
    var totalMinutes = Math.round(durationInSeconds / 60);
    var hour = Math.floor(totalMinutes / 60);
    var minute = totalMinutes % 60;
    if (hour < 1) {
      return `${minute} min`;
    }
    return `${hour} hr ${minute} min`;
  };

  const hoursDescForActiveSession = (item: CostBreakdownItem) => {
    return hourDesc(
      item.periodStart,
      item.periodEnd,
      item.pricingType,
      item.unitPrice,
      item.periodEnergyKilowattHours,
    );
  };

  const hoursDescForReceipt = (item: ReceiptLineItemDto) => {
    return hourDesc(
      item.startTime,
      item.stopTime,
      item.unitPricingType,
      item.unitPrice,
      item.energyDelivered,
    );
  };

  const hourDesc = (
    startTime: string,
    stopTime: string,
    pricingType: string,
    unitPrice: number,
    energyConsumed: number,
  ) => {
    const start = moment(startTime, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
    const end = moment(stopTime, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
    const formatStart = moment(start).format('h:mm A');
    const formatEnd = moment(end).format('h:mm A');

    const timeDiff = moment
      .duration(
        moment(stopTime, 'YYYY-MM-DDTHH:mm:ssZ').diff(
          moment(startTime, 'YYYY-MM-DDTHH:mm:ssZ'),
        ),
      )
      .asSeconds();

    const isFree = pricingType === RuleTypes.FREE;

    const readablePrice = isFree
      ? sessionPricingType(pricingType)
      : `$${fixedPrice(unitPrice)}/${sessionPricingType(pricingType)}`;

    return {
      details: `${readableDuration(
        timeDiff,
        pricingType,
        energyConsumed,
      )} @ ${readablePrice}`,
      duration: ` ${formatStart} - ${formatEnd}`,
    };
  };

  const readableTaxDescriptions = (taxCode: string, percentage: any) => {
    switch (taxCode) {
      case 'sales tax':
        return `Sales tax (${percentage}%) incl.`;
      default:
        return `${taxCode} (${percentage}%) incl.`;
    }
  };

  const formatNumber = (input: number, decimalCount: number) => {
    try {
      return input.toFixed(decimalCount);
    } catch (e) {
      return '0.00';
    }
  };

  // If there is no receipt, session is still active and we will use activeSessionLineItems
  // If there is a receipt, session is not active anymore and we will use receiptChargingLineItems
  const renderChargingLineItems = () => {
    if (!receipt) {
      // eslint-disable-next-line array-callback-return
      return activeSessionLineItems.map((item: CostBreakdownItem) => {
        const hours = hoursDescForActiveSession(item);
        if (item.pricingType !== PricingType.IDLE) {
          return renderLineItem(hours.details, item.periodCost, hours.duration);
        }
      });
    } else {
      return receiptChargingLineItems.map((item: ReceiptLineItemDto) => {
        const hours = hoursDescForReceipt(item);
        return renderLineItem(hours.details, item.totalPrice, hours.duration);
      });
    }
  };

  // If there is no receipt, session is still active and we will use activeSessionLineItems
  // If there is a receipt, session is not active anymore and we will use receiptIdleLineItems
  const renderIdleLineItems = () => {
    if (!receipt) {
      // eslint-disable-next-line array-callback-return
      return activeSessionLineItems.map((item: CostBreakdownItem) => {
        const hours = hoursDescForActiveSession(item);
        if (
          item.pricingType === PricingType.IDLE &&
          charger.currentPrice.idleRate > 0
        ) {
          return renderLineItem(hours.details, item.periodCost, hours.duration);
        }
      });
    } else {
      return receiptIdleLineItems.map((item: ReceiptLineItemDto) => {
        const hours = hoursDescForReceipt(item);
        return renderLineItem(hours.details, item.totalPrice, hours.duration);
      });
    }
  };

  const renderLineItem = (
    details: string,
    totalPrice: number,
    duration: string,
  ) => {
    return (
      <>
        <SessionListItem
          title={details}
          amount={`$${fixedPrice(totalPrice)}`}
          styleClass={classes.listText}
          duration={duration}
        />
      </>
    );
  };

  const isThereIdleLineItem = () => {
    if (!receipt) {
      return activeSessionLineItems.some(
        (item) => item.pricingType === PricingType.IDLE,
      );
    } else {
      return receiptIdleLineItems.length > 0;
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid container style={{ paddingBottom: '16px' }}>
        <img src={Receipt} alt='receipt_icon' />
        <span
          style={{
            color: '#202223',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            paddingLeft: '8px',
          }}
        >
          {i18n.t('receipt_cost_detail')}
        </span>
      </Grid>
      <Grid className={classes.listWrapper} style={{ width: '100%' }}>
        <SessionListItem
          title={i18n.t('charging')}
          amount={`$${fixedPrice(chargingPrice())}`}
          styleClass={classes.listHead}
        />
        {renderChargingLineItems()}
        {isThereIdleLineItem() && (
          <SessionListItem
            title={'Idle fees'}
            amount={`$${fixedPrice(idlePrice())}`}
            styleClass={classes.listHead}
          />
        )}
        {renderIdleLineItems()}
        <Grid container className={classes.list}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {!isChargingBySelf && (
          <Grid container item className={classes.pt16}>
            <Grid item xs={8}>
              <span className={classes.weight500}>
                {i18n.t('receipt_subtotal')}
              </span>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span className={classes.weight500}>{`$${formatNumber(
                receipt?.subTotal,
                2,
              )}`}</span>
            </Grid>
          </Grid>
        )}
        {receipt?.discountPercentage > 0 && !isChargingBySelf && (
          <Grid container item className={classes.mt12}>
            <Grid item xs={8}>
              <span className={classes.weight500}>
                {i18n.t('receipt_discount')}{' '}
                {`(${
                  receipt.discountPercentage === 100
                    ? i18n.t('free')
                    : receipt.discountPercentage + '%'
                })`}
              </span>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <span
                className={clsx(classes.weight500, classes.green)}
              >{`- $${formatNumber(
                receipt.subTotal - receipt.total,
                2,
              )}`}</span>
            </Grid>
          </Grid>
        )}

        {receipt?.taxRates &&
          !isChargingBySelf &&
          Object.entries(receipt.taxRates).map(([key, value]) => {
            return (
              <Grid container item className={classes.mt12}>
                <Grid item xs={8}>
                  <span className={classes.weight500}>
                    {readableTaxDescriptions(key, value)}
                  </span>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end'>
                  <span className={clsx(classes.weight500)}>{`$${formatNumber(
                    receipt.taxedAmounts[key],
                    2,
                  )}`}</span>
                </Grid>
              </Grid>
            );
          })}
        {!isChargingBySelf && (
          <Grid
            container
            item
            className={clsx(classes.borderBottom2, classes.mt16)}
          ></Grid>
        )}
        <SessionListItem
          title={isChargingBySelf ? i18n.t('running_total') : 'Total'}
          amount={`$${fixedPrice(runningCost)}`}
          styleClass={clsx(classes.pt16, classes.listHead)}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(DetailsStyles)(ChargerSessionDetails);
