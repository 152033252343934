import React, { useContext } from 'react';
import { PaymentMode } from './types/payment-mode.enum';

import { useTranslation } from 'react-i18next';
import { PaymentContext } from '../../hooks/usePayment';
import PaymentCardForm from './payment-card-form.component';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: 16,
    boxShadow: '0px -1px 0px 0px #E5E8EB inset',
    position: 'sticky',
    top: '0px',
    maxWidth: '425px',
    zIndex: 1,
    background: '#FFFFFF',
  },
  header: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#202223',
  },
  subHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#4E5968',
  },
}));

const ManagePayment = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const paymentConsumer = useContext(PaymentContext);

  const { handlePaymentView, editMode, payments } = paymentConsumer;

  const onClickBack = () => {
    handlePaymentView(PaymentMode.NONE);
  };

  // Keep this code because design is not available for it
  const showDeleteIcon = payments?.payload?.length > 1;

  // Keep this code because design is not available for it
  const onIconClicked = () => {
    handlePaymentView(PaymentMode.PAYMENT_DELETE);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Typography className={classes.header}>
          {i18n.t(editMode ? 'edit_payment_method' : 'add_payment_method')}
        </Typography>
        <Typography className={classes.subHeader}>
          {i18n.t('make_payment_sub_header')}
        </Typography>
      </div>
      <PaymentCardForm onClickBack={onClickBack} />
    </>
  );
};

export default ManagePayment;
