import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { ROUTES } from '../../types/routes.enum';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useDomain } from '../../hooks/useDomain';
import { CancelButton } from '../ui-components/button/cancelButton';

const Logout = () => {
  const classes = useStyles();
  const theme = useSelector(getTheme);
  const navigate = useNavigate();
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');
  const { i18n } = useTranslation();
  const auth = useAuth();
  const { domain } = useDomain();
  const redirectSignOut = () => {
    const route = chargerName
      ? `/${chargerName}`
      : `/app/${domain}/${ROUTES.LOGIN}`;

    navigate(route);
  };

  const signout = () => {
    try {
      auth.signOut(redirectSignOut);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const backAction = () => {
    const route = chargerName
      ? `/app/${domain}/${ROUTES.ACCOUNT}?charger=${chargerName}`
      : `/app/${domain}/${ROUTES.ACCOUNT}`;
    navigate(route);
  };

  return (
    <ViewWrapper>
      <Grid className={classes.logoutWrapper}>
        <BackButtonHeader
          title={`${i18n.t('log_out')}?`}
          onBackArrowClicked={backAction}
        />
        <Grid className={classes.container}>
          <Typography className={classes.title} variant='h5' component='h1'>
            {i18n.t('confirm_logout')}
          </Typography>
          <Typography className={classes.subTitle} variant='subtitle1'>
            {i18n.t('confirm_logout_description')}
          </Typography>
        </Grid>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={6}>
            <CancelButton onClick={backAction}>{i18n.t('cancel')}</CancelButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.buttonItem}
              // style={{}}
              sx={{
                height: '48px',
                backgroundColor: theme.secondary,
                color: theme.primary,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                ':hover': {
                  backgroundColor: '#BBE7F6',
                },
              }}
              onClick={signout}
            >
              {i18n.t('log_out')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default Logout;
