import React, { useState } from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import DropdownIcon from '../../../assets/icons/receipt/Dropdown.svg';
import { useStyles } from './styles';
import { withStyles } from '@mui/styles';
import Tippy from '@tippyjs/react';

const Dropdown = ({ onClick, value, options, classes, placeholder }) => {
  const [focus, setFocus] = useState(false);

  const handleClick = (value: boolean) => {
    setFocus(value);
  };

  const handleSelectOption = (element) => {
    setFocus(false);
    onClick(element);
  };

  const dropDownList = () => {
    return options.map((ele) => (
      <div
        onClick={() => handleSelectOption(ele)}
        className={clsx({
          [classes.dropdownMenuItem]: true,
          [classes.dropdownMenuItemHover]: true,
          [classes.dropDownSelectedItem]: value === ele.isoCode,
        })}
        style={{ textAlign: 'left' }}
        key={ele.name}
      >
        {ele.name}
      </div>
    ));
  };

  const renderContent = () =>
    focus ? (
      <div className={clsx(classes.dropDownMenu)}>{dropDownList()}</div>
    ) : null;

  const renderHeader = () => (
    <button
      className={clsx(
        classes.drodDownButton,
        classes.paddingTop,
        classes.labelB2
      )}
      onClick={() => handleClick(!focus)}
    >
      <Grid container item alignItems='center' justifyContent='space-between'>
        <Grid item>
          {value || (
            <>
              <label className={classes.labelB2} style={{ color: '#B0B8C1' }}>
                {placeholder}
              </label>
            </>
          )}
        </Grid>
        <Grid item alignItems='center'>
          <img src={DropdownIcon} alt='' className={classes.displayBlock} />
        </Grid>
      </Grid>
    </button>
  );

  return (
    <Tippy
      render={(attrs) => renderContent()}
      allowHTML
      placement='bottom-start'
      maxWidth='none'
      interactive
      arrow={false}
      visible={focus}
      onClickOutside={() => handleClick(false)}
      duration={[null, null]}
    >
      {renderHeader()}
    </Tippy>
  );
};

export default withStyles(useStyles)(Dropdown);
