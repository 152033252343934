import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { rfidStyle } from '../rfidStyle';
import key_tag_graphic from '../../../assets/images/rfid/key_tag_graphic.svg';
import { getTheme } from '../../../stores/selectors/theme.selector';

export const OrderConfirmation = () => {
  const classes = rfidStyle();
  const { i18n } = useTranslation();
  const theme = useSelector(getTheme);

  return (
    <Box component='div' p={2.5}>
      <Grid
        item
        xs={12}
        style={{ textAlign: 'center', marginTop: 65, marginBottom: 50 }}
      >
        <img src={key_tag_graphic} alt='' />
      </Grid>
      <Typography className={classes.orderHeading} style={{ color: theme.navigationSelectedColor }} >
        {i18n.t('rfid_got_your_card')}
      </Typography>
      <Typography variant='body1' className={classes.orderMessage}>
        {i18n.t('rfid_got_your_card_message')}
      </Typography>
    </Box>
  );
};
