export const getCurrentLanguage = (i18n) => {
  switch (i18n) {
    case 'fr':
      return 'french';
    case 'en':
      return 'english';
    case 'es':
      return 'spanish';
    default:
      return 'english';
  }
};
