import React from 'react';
import PaymentProvider from '../../hooks/usePayment';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import PaymentWrapper from './payment-wrapper.component';

export const Payment = () => {
  return (
    <ViewWrapper>
        <PaymentProvider>
          <PaymentWrapper />
        </PaymentProvider>
    </ViewWrapper>
  );
};
