import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Depolyment } from '../stores/types/deployment.interface';

const DEPLOYMENT_API_KEY = 'deployment';

export const deploymentApi = createApi({
  reducerPath: DEPLOYMENT_API_KEY,
  baseQuery: fetchBaseQuery({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    baseUrl: `/${DEPLOYMENT_API_KEY}`,
  }),
  endpoints: (builder) => ({
    fetchAPIPrefix: builder.query<any, void>({
      query: () => ({
        url: 'api', //?hostname=${window.location.hostname}
        method: 'GET',
      }),
    }),
    fetchStripeKey: builder.query<any, void>({
      query: () => ({
        url: 'stripe', //?hostname=${window.location.hostname}
        method: 'GET',
      }),
    }),
    fetchCognito: builder.query<any, void>({
      query: () => ({
        url: 'cognito', //?hostname=${window.location.hostname}
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchAPIPrefixQuery,
  useFetchStripeKeyQuery,
  useFetchCognitoQuery,
} = deploymentApi;
