import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { envApi } from '../services/env.api';
import { deploymentApi } from '../services/deployment.api';
import { receiptApi } from '../services/receipt.api';
import { Api } from '../services/base.api';
import { rtkQueryLogger } from './rtkQueryLogger';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      Api.middleware,
      envApi.middleware,
      deploymentApi.middleware,
      receiptApi.middleware,
      rtkQueryLogger,
    ]),
});

export { store };
