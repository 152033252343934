import AuthHeader from '../ui-components/auth-header.component';
import { authStyles } from '../login/authStyles';
import { useTranslation } from 'react-i18next';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from './utils';
import { useDomain } from '../../hooks/useDomain';
import AddIdButtons from './add-id-buttons.component';
import { showSnackBar } from '../../util/toast.utility';
import { useEffect, useState } from 'react';
import { useAddPayMethodMutation } from '../../services/payment.api';

interface IProps {
  logOut: Function;
}

const AddPayment = ({ logOut }: IProps) => {
  const classes = authStyles();
  const { i18n } = useTranslation();
  const { domain } = useDomain();

  const [triggerAddPayMethod, { isError, error }] = useAddPayMethodMutation();
  const [isLoading, setIsLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    if (isError) {
      showSnackBar(i18n.t('adding_payment_card_failed'));
    }
  }, [isError, error]);

  const handleSubmit = async () => {
    let stripeResponse;
    setIsLoading(true);
    stripeResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (stripeResponse.error) {
      showSnackBar(stripeResponse.error.message);
      setIsLoading(false);
    } else {
      triggerAddPayMethod({
        paymentMethodId: stripeResponse.paymentMethod.id,
        isDefault: true,
      });
      // There is no setIsLoading here, after we have payment method it will swithc to next page
    }
  };

  return (
    <div className={classes.authWrapper}>
      <AuthHeader
        secondaryText={i18n.t('add_payment_method')}
        description={i18n.t('add_payment_method_description', {
          companyName: domain,
        })}
        secondaryTextClassName={classes.ivySecondartyText}
        descriptionTextClassName={classes.ivyDescText}
      />
      <div className={classes.formInput}>
        <div className={classes.cardwrapper}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div>

      <AddIdButtons loading={isLoading} logOut={logOut} submit={handleSubmit} />
    </div>
  );
};

export default AddPayment;
