import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Grid, Typography, Divider} from '@mui/material';

import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useDomain } from '../../hooks/useDomain';
import { ROUTES } from '../../types/routes.enum';
import {
  useFetchPlugAndChargeKeysQuery,
  useDeleteVehicleMutation,
} from '../../services/plug-and-charge-keys.api';
import { LoadingSpinner } from '../ui-components/loading-spinner.component';
import { rfidStyle } from '../rfid/rfidStyle';
import { getTheme } from '../../stores/selectors/theme.selector';
import { Image } from '../ui-components/image.component';
import rfidIcon from '../../assets/icons/rfidIcon.svg';
import deleteIcon from '../../assets/icons/deleteIcon.svg';
import addPlusDisabled from '../../assets/icons/addPlus_disabled.svg';
import addPlus from '../../assets/icons/addPlus.svg';
import { NUMBER } from '../../constant/number.constant';
import { plugAndChargeKeysStyle } from './plug-and-charge-keys-style';

export const PlugAndChargeKeysComponent = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { domain } = useDomain();
  const classes = plugAndChargeKeysStyle();
  const theme = useSelector(getTheme);

  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const { data: plugAndChargeKeys, isLoading: isPlugAndChargeLoading } =
    useFetchPlugAndChargeKeysQuery(undefined);

  const [
    triggerDeleteVehicleMutation,
    { isSuccess: isDeleteVehicleSuccess, isLoading: isDeleteVehicleLoading },
  ] = useDeleteVehicleMutation();

  const isActivatedKeysLimitCrossed = plugAndChargeKeys?.length > NUMBER.FOUR;

  useEffect(() => {
    if (isPlugAndChargeLoading || isDeleteVehicleLoading) {
      LoadingSpinner.show();
    } else {
      LoadingSpinner.hide();
    }
  }, [isPlugAndChargeLoading, isDeleteVehicleLoading]);

  const backAction = () => {
    const route = `/app/${domain}/${ROUTES.ACCOUNT}${
      chargerName ? `?charger=${chargerName}` : ''
    }`;
    navigate(route);
  };

  const handleDeleteKey = (id) => {
    triggerDeleteVehicleMutation(id);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('plug_and_charge_keys')}
        onBackArrowClicked={backAction}
      />
      <Box
        component='div'
        style={{
          padding: '16px'
        }}
      >
        <Box mb={3.3}>
          <Typography
            variant='subtitle1'
            component='span'
            color='textPrimary'
            className={classes.textHeader}
          >
            {i18n.t('my_plug_and_charge_keys')}
          </Typography>
        </Box>
        <Grid>
          {!isPlugAndChargeLoading && plugAndChargeKeys?.map((ele) => (
            <>
              <Grid container alignItems='center'>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Image
                    src={rfidIcon}
                    style={{
                      marginRight: '22px',
                      marginLeft: '6px',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant='body1'
                    component='span'
                    color='textPrimary'
                  >
                    {ele.pcid}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Image
                    handleClick={() => handleDeleteKey(ele.id)}
                    src={deleteIcon}
                    style={{
                      width: '14px',
                      height: '18px',
                      marginLeft: '188px',
                      cursor: 'pointer',
                    }}
                  />
                </Grid>
              </Grid>
              <Divider
                style={{
                  background: '#E5E8EB',
                  marginTop: '18px',
                  marginBottom: '18px',
                }}
              />
            </>
          ))}
          <Box mt={2.5} mb={4} style={{ display: 'flex' }}>
            <Image
              src={isActivatedKeysLimitCrossed ? addPlusDisabled : addPlus}
              style={{
                marginRight: '22px',
                marginLeft: '6px',
              }}
            />
            <Typography
              variant='subtitle1'
              component='span'
              color='textPrimary'
              style={
                isActivatedKeysLimitCrossed
                  ? { cursor: 'no-drop', color: '#D1D6DB' }
                  : { cursor: 'pointer' }
              }
              onClick={() => {
                if (!isActivatedKeysLimitCrossed)
                  navigate(`/app/${domain}/${ROUTES.PLUG_AND_CHARGE_ADD_VEHICLE}`);
              }}
            >
              {isActivatedKeysLimitCrossed
                ? i18n.t('add_plug_and_charge_vehicle_disabled')
                : i18n.t('add_plug_and_charge_vehicle')}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </ViewWrapper>
  );
};
