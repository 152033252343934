import { Alert } from "../component/ui-components/alert.component";

export const showSnackBar = (message, close = false) => {

    Alert.show({ message, close });

    if (!close) {
        setTimeout(function () {
            Alert.hide();
        }, 3000);
    }
}