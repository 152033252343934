import {
  Session,
  SessionStartPayload,
  SessionStartResponse,
} from '../component/charger/dto/charger.dto';
import { USER_ROLE } from '../hooks/useAuth';
import { API_KEYS, Api } from './base.api';
import { providesListTag } from './utils';

export const sessionsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    sessionStartCharging: builder.mutation<
      SessionStartResponse,
      SessionStartPayload
    >({
      query: (payload: SessionStartPayload) => ({
        url: `/core/v1/${API_KEYS.SESSIONS}`,
        body: payload,
        method: 'POST',
        params: {
          role: USER_ROLE.DRIVER,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.SESSIONS, id: 'LIST' },
      ],
    }),
    // The response of session stop is 'message: Session xxxx stopped'
    sessionStopCharging: builder.mutation<string, string>({
      query: (sessionId) => ({
        url: `/core/v1/${API_KEYS.SESSIONS}/${sessionId}/stop`,
        method: 'POST',
        params: {
          role: USER_ROLE.DRIVER,
        },
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: API_KEYS.CHARGERS, id: 'LIST' },
          { type: API_KEYS.SESSIONS, id: 'LIST' },
        ];
      },
    }),
    fetchSessionById: builder.query<Session, string>({
      query: (sessionId) => ({
        url: `/core/v1/${API_KEYS.SESSIONS}/${sessionId}`,
        method: 'GET',
        params: {
          role: USER_ROLE.DRIVER,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.SESSIONS, id: result?.sessionId },
      ],
    }),
    fetchActiveSessions: builder.query<Session[], any>({
      query: (chargerName) => ({
        url: `/core/v1/${API_KEYS.SESSIONS}?filter_eq[status]=CHARGING`,
        method: 'GET',
        params: {
          role: USER_ROLE.DRIVER,
        },
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.map((res) => ({
            id: res.sessionId,
          })),
          API_KEYS.SESSIONS,
        ),

      transformResponse: (response: any) => {
        return response.entities as Session[];
      },
    }),
  }),
});

export const {
  useSessionStartChargingMutation,
  useSessionStopChargingMutation,
  useFetchSessionByIdQuery,
  useFetchActiveSessionsQuery,
} = sessionsApi;
