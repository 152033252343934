import { Country } from 'country-state-city';
import { PaymentPayload } from '../../stores/types/payment.interface';

export const sortCountries = (country) => {
  const countryArray = [...country];

  const findCanadaIndex = countryArray.findIndex((ele) => ele.isoCode === 'CA');
  const CAObj = countryArray.splice(findCanadaIndex, 1);

  const findUSIndex = countryArray.findIndex((ele) => ele.isoCode === 'US');
  const USObj = countryArray.splice(findUSIndex, 1);
  return [...CAObj, ...USObj, ...countryArray];
};

export const elementFontOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700&display=swap',
    },
  ],
};

// Remove Puerto Rico from list of countries
export const getAllCountries = () =>
  Country.getAllCountries().filter((item) => item.isoCode !== 'PR');

// Check if there are any payloads with expiredStatus of "NO_EXPIRED"
export function hasValidPaymentMethod(payloads: PaymentPayload[]): boolean {
  return payloads.some((payload) => payload.expiredStatus === 'NO_EXPIRED');
}
