import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Divider, Button } from '@mui/material';

import { Image } from '../ui-components/image.component';
import RFID from '../../assets/images/rfid/rfid.svg';
import { rfidStyle } from './rfidStyle';
import { FAQComponent } from './faqs/FAQs';

export const RFIDCardComponent = ({ RFID_BENEFITS }) => {
  const { i18n } = useTranslation();
  const classes = rfidStyle();

  return (
    <>
      <Grid container justifyContent='center'>
        <Box my={1}>
          <Image src={RFID} />
        </Box>
      </Grid>
      <Box my={1} mb={2}>
        <Typography className={classes.h5} component='h5' color='textPrimary'>
          {i18n.t('rfid_heading')}
        </Typography>
      </Box>
      <Grid container>
        {RFID_BENEFITS.map((ele) => (
          <Box mb={2}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Box mr={2.5}>
                {ele.icon}
              </Box>
              <Grid>
                <Typography
                  className={classes.subtitle1}
                  component='span'
                  color='textPrimary'
                >
                  {ele.title}
                </Typography>
                <Typography
                  className={classes.body1}
                  component='p'
                  color='textSecondary'
                >
                  {ele.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <FAQComponent />
      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
    </>
  );
};
