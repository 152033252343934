import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery, providesListTag } from './utils';
import { Api, API_KEYS } from './base.api';
import { UserDto } from '../component/account/dto/user.dto';
import { UpdateUserDto } from '../component/account/dto/update-user.dto';

export const userApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCurrentUser: builder.query<UserDto, void>({
      query: () => ({
        url: `/api/${API_KEYS.USER}/me`,
        method: 'GET',
        legacyApi: true,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.USER, id: result?.id },
      ],
    }),
    updateUser: builder.mutation<any, UpdateUserDto>({
      query: (payload) => ({
        url: `/api/${API_KEYS.USER}`,
        body: payload,
        method: 'PUT',
        legacyApi: true,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.USER, id: result?.id },
      ],
    }),
  }),
});

export const { useFetchCurrentUserQuery, useUpdateUserMutation } = userApi;
