import { ViewWrapper } from './ui-components/view-wrapper.component';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import errorimg from '../assets/images/charger/error.svg';
import { BackButtonHeader } from './ui-components/back-button-header.component';
import { useAuth } from '../hooks/useAuth';
import NetworkLogo from './ui-components/network-logo.component';
import { manageAccountIcon } from '../assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../types/routes.enum';
import CustomButton from './react-native-material-wrapper/button.component';
import { getTheme } from '../stores/selectors/theme.selector';
import { useSelector } from 'react-redux';
export const PageNotFound = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const theme = useSelector(getTheme);

  const handleIconClicked = () => {
    navigate(`/app/${window.domainName}/${ROUTES.ACCOUNT}`);
  };

  const callSupport = () => {
    window.location.href = `tel:1-800-636-0986`;
  };

  return (
    <ViewWrapper>
      {auth.isLogined && (
        <BackButtonHeader
          isBackButtonRequired={false}
          onIconClicked={handleIconClicked}
          icon={auth.isLogined ? manageAccountIcon() : null}
          companyIcon={<NetworkLogo height='28px' width='auto' />}
          height={60}
        />
      )}
      <div
        style={{
          textAlign: 'center',
          display: 'block',
          alignContent: 'center',
          padding: '24px 16px 0px 16px',
        }}
      >
        <img src={errorimg} alt='errorimg' width={240} height={260} />
        <Typography
          variant='h5'
          style={{
            width: '100%',
            display: 'inline-block',
            marginBottom: '16px',
            marginTop: '24px',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
            color: '#202223',
          }}
        >
          {i18n.t('charger_not_registred')}
        </Typography>
        <Typography
          // variant='h5'
          style={{
            width: '100%',
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            fontFamily: 'Inter',
            marginBottom: '24px',
            color: '#202223',
          }}
        >
          {i18n.t('four_o_four_desc')}
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '26px',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h6'
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '20px',
              textAlign: 'center',
              color: '#4E5968',
            }}
          >
            {i18n.t('still_having_trouble')}
          </Typography>
          <CustomButton
            size='large'
            style={{
              color: theme.primary,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '0em',
              textAlign: 'center',
              fontFamily: 'Inter',
              textTransform: 'inherit',
              paddingLeft: '4px',
            }}
            onClick={callSupport}
          >
            {i18n.t('Call_support')}
          </CustomButton>
        </Box>
      </div>
    </ViewWrapper>
  );
};
