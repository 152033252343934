import { Auth } from 'aws-amplify';

import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  getLegacyApiUrlPrefix,
  getNewApiUrlPrefix,
  // getLegacyApiUrlPrefix,
  // getOldDashboardUrl,
} from '../stores/selectors/deployment.selector';
import { deploymentApi } from './deployment.api';
import { ChargerStatusEventType } from '../types/charger-status-event-type.enum';
import { FetchChargerResponseEnum } from '../types/fetch-charger-response.enum';
// import { store } from '../stores/store';
// import { USER_ROLE } from '../hooks';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ConnectorStatus } from '../types/connector-status.enum';
import { LocationDto } from '../component/site/types/location.dto';
import { ChargePointShortDto } from '../component/site/types/charge-point-short.dto';
import { USER_ROLE } from '../hooks/useAuth';
import { i18n } from './translation/i18n';

export const header = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export enum SCOPE_TYPE {
  NONE,
  COMPANY,
  ALL,
}

export const getPath = (path: string, params?: any) => {
  if (params) {
    Object.entries(params)?.forEach(([key, value]) => {
      path += `&${key}=${value}`;
    });
  }
  return path.replaceAll('[', '%5B').replaceAll(']', '%5D');
};

export const getToken = async () => {
  const cognitoUserSession = await Auth.currentSession();
  const token = cognitoUserSession.getAccessToken().getJwtToken();
  return token;
};

const getAcceptHeader = (args: any) => {
  if (args.isCSV) {
    return 'text/csv';
  }
  if (args.isPDF) {
    return 'application/pdf';
  }
  return 'application/json';
};

const rawBaseQuery = async (
  baseUrl: string,
  args: any,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  const baseResult = await fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
      headers.set('Accept', getAcceptHeader(args));
      headers.set(
        'Content-Type',
        args.isPDF ? 'application/pdf' : 'application/json',
      );
      headers.set('Access-Control-Allow-Origin', '*');
      if (!args.noAuthNeeded) {
        // const token = (getState() as RootState).auth.token
        const token = await getToken();
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }
      return headers;
    },
    baseUrl,
  })(args, api, extraOptions);

  return {
    ...baseResult,
    meta: baseResult.meta && {
      ...baseResult.meta,
      toast: args.toast,
    },
  };
};

export const dynamicBaseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  const adjustedArgs = {
    ...args,
  };

  let baseUrl: string;
  if (args.legacyApi) {
    baseUrl = `${getLegacyApiUrlPrefix(api.getState() as any)}`;
  } else {
    // new api
    baseUrl = getNewApiUrlPrefix(api.getState() as any);
  }

  return rawBaseQuery(baseUrl, adjustedArgs, api, extraOptions);
};

export const providesListTag = <
  R extends { id: string | number }[],
  T extends string,
>(
  resultsWithIds: R | undefined,
  tagType: T,
) => {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
};

export const formatRepsonse = (obj: any) => {
  if (!obj.eventType) {
    return obj;
  }

  switch (obj.eventType) {
    case ChargerStatusEventType.V2_CHARGE_POINT_BY_DISPLAY_ID:
      const chargerObject = obj.chargePointDto;
      const chargerPrice =
        chargerObject?.strictPrice > 0
          ? getSymbolFromCurrency(chargerObject.priceCurrency) +
            chargerObject.strictPrice.toFixed(2)
          : i18n.t('free');
      return {
        ...obj,
        userAccessible: !(
          obj?.message === FetchChargerResponseEnum.UNAUTHORIZED
        ),
        chargePointDto: {
          ...obj.chargePointDto,
          price: chargerPrice || obj.chargePointDto?.price,
        },
        selectedConnector: chargerObject.connectorDtoList.find(
          (connector) => connector.computedStatus === ConnectorStatus.AVAILABLE,
        )?.connectorId,
      };
    case ChargerStatusEventType.V2_CHARGE_POINT_STATUS_UPDATE:
      return {
        ...obj,
        newConnectorStatuses: [...obj.connectors],
        status: obj.chargePointStatus,
        chargePointId: obj.chargePointId,
      };
    case ChargerStatusEventType.V2_ACTIVE_SESSIONS:
      return {
        ...obj,
        isSessionActive: obj.activeSessions.length === 1,
        eventType: obj.eventType,
        data: obj.activeSessions.length === 1 ? obj.activeSessions[0] : null,
      };
    case ChargerStatusEventType.V2_NOTIFY_STOPED:
      return {
        ...obj,
        eventType: obj.eventType,
        data: {
          kwattsAmount: obj.kwattsConsumed,
          moneyValue: obj.money,
          timeAmount: obj.timeAmount,
        },
      };
    case ChargerStatusEventType.V2_LOCATION_BY_ID:
      let currentLocation = obj.locationDto as LocationDto;
      let calculatedChargers: ChargePointShortDto[] = [];

      if (currentLocation) {
        currentLocation.chargePointTypeDtos.forEach((type) => {
          calculatedChargers = calculatedChargers.concat(
            type.chargePointShortDtos,
          );
        });
      }

      if (
        calculatedChargers.filter((c) =>
          // c.stationId.toUpperCase() === stationId &&
          c.connectorDtoList.some(
            (d) => d.computedStatus === ConnectorStatus.SESSION,
          ),
        ).length !== 0
      ) {
        currentLocation.siteAvailability = ConnectorStatus.SESSION;
      } else if (
        calculatedChargers.some((c) =>
          c.connectorDtoList.some(
            (d) => d.computedStatus === ConnectorStatus.AVAILABLE,
          ),
        )
      ) {
        currentLocation.siteAvailability = ConnectorStatus.AVAILABLE;
      } else if (
        calculatedChargers.filter((c) =>
          c.connectorDtoList.some(
            (d) => d.computedStatus === ConnectorStatus.SESSION,
          ),
        ).length === calculatedChargers.length
      ) {
        currentLocation.siteAvailability = ConnectorStatus.SESSION;
      } else {
        currentLocation.siteAvailability = ConnectorStatus.UNKNOWN;
      }
      return {
        ...obj,
        site: currentLocation,
        siteChargers: calculatedChargers,
      };
    default:
      return obj;
  }
};

export const processResponseError = (message: string) => {
  // This is a stupid API design.
  if (message.toUpperCase().indexOf('CHARGER NOT FOUND') !== -1) {
    document.location.href = '/404';
  }
};
