import { BillingType } from '../component/site/types/billing-type.enum';
import { ChargePointShortDto } from '../component/site/types/charge-point-short.dto';
import { i18n } from '../services/translation/i18n';

export const fixFloat = (input: any, decimalPlace: number) => {
  try {
    const inputNumber = parseFloat(input.split(' ')[0]);
    if (input.split(' ').length > 1) {
      return `${inputNumber.toFixed(decimalPlace)} ${input.split(' ')[1]}`;
    }

    return inputNumber.toFixed(decimalPlace);
  } catch {
    try {
      return input.toFixed(decimalPlace);
    } catch {
      return input;
    }
  }
};

export const formatChargerPrice = (charger: ChargePointShortDto) => {
  if (charger && charger.price.toLowerCase() === 'free') {
    return i18n.t('free');
  }

  let billingType = '';
  switch (charger.billingType) {
    case BillingType.BILLED_CHARGING:
    case BillingType.BILLED_PLUGGED:
      billingType = '/hr';
      break;

    case BillingType.BILLED_BY_KWT:
    case BillingType.BILLED_ESTIMATED_KWT:
      billingType = '/kWh';
      break;

    default:
      billingType = '';
      break;
  }

  return `${charger.priceCurrencySymbol}${charger.strictPrice.toFixed(
    2,
  )}${billingType}`;
};

export const format2Digits = (value: any) => {
  const num = Number(value);
  return num.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
