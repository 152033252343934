import { makeStyles } from '@mui/styles';

export const userStyles = makeStyles(() => ({
  profile: {
    padding: '16px',
  },
  contentPadding: {
    padding: '16px',
  },
  columnPaddingRight: {
    paddingRight: '4px',
  },
  columnPaddingLeft: {
    paddingLeft: '4px',
  },
  rightAlign: {
    textAlign: 'right',
  },
  pointer: {
    cursor: 'pointer',
  },
  centerAlign: {
    textAlign: 'center',
  },
  paddingBottom: {
    paddingBottom: '15px',
  },
  marginTop: {
    marginTop: '1rem',
  },
  margin: {
    margin: '0 auto !important',
  },
  authButton: {
    padding: 16,
  },
}));
