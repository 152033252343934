import { defaultToPlusOneCountryCode } from "../../util/js-utility";

export const getIsEmail = (value: string) => {
  return value.indexOf("@") > -1;
};

export const getLoginString = (value: string) => {
  const isEmail = getIsEmail(value);
  return (!isEmail ? defaultToPlusOneCountryCode(value) : value).trim();
};
