import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Divider } from '@mui/material';

import { Image } from '../../ui-components/image.component';
import { rfidStyle } from '../rfidStyle';
import { FAQComponent } from '../faqs/FAQs';
import rfidIcon from '../../../assets/icons/rfidIcon.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { ROUTES } from '../../../types/routes.enum';
import { AddRfidButton } from './addRfidButton.component';
import { NUMBER } from '../../../constant/number.constant';

export const RFIDListingComponent = ({ activatedCards }) => {
  const isActivatedCardLimitCrossed = activatedCards?.length > NUMBER.FOUR;

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const classes = rfidStyle();

  const handleDeleteRfid = (cardNumber) => {
    navigate(`/app/${window.domainName}/${ROUTES.DELETE_RFID}/${cardNumber}`);
  };

  return (
    <>
      <Box mb={3.3}>
        <Typography
          variant='subtitle1'
          component='span'
          color='textPrimary'
          className={classes.textHeader}
        >
          {i18n.t('my_rfid_card')}
        </Typography>
      </Box>
      <Grid>
        {activatedCards.map((ele) => (
          <>
            <Grid container alignItems='center'>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  src={rfidIcon}
                  style={{
                    marginRight: '22px',
                    marginLeft: '6px',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body1'
                  component='span'
                  color='textPrimary'
                >
                  {ele.number}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Image
                  handleClick={() => handleDeleteRfid(ele.number)}
                  src={deleteIcon}
                  style={{
                    width: '14px',
                    height: '18px',
                    marginLeft: '188px',
                    cursor: 'pointer',
                  }}
                />
              </Grid>
            </Grid>
            <Divider
              style={{
                background: '#E5E8EB',
                marginTop: '18px',
                marginBottom: '18px',
              }}
            />
          </>
        ))}
        <AddRfidButton
          isActivatedCardLimitCrossed={isActivatedCardLimitCrossed}
        />
      </Grid>
      <FAQComponent isLost />
    </>
  );
};
