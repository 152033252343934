import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import 'moment/locale/es';
import 'moment/locale/fr';
import moment from 'moment/moment';
import { useLazyFetchTransactionByIdQuery } from '../../../services/transactions.api';
import { useLazyFetchReceiptQuery } from '../../../services/receipt.api';
import { useUserPreference } from '../../../hooks/useUserPreference';
import { ROUTES } from '../../../types/routes.enum';
import { BUTTON_STATUS } from '../charger.component';
import ChargerSessionWarningComponent from './charger-session-warning.component';
import { CostBreakdownItem, IdleFeesStatus, Session } from '../dto/charger.dto';
import ChargerCostDetails from './charger-cost-details.components';
import SessionButton from './charger-session-button.component';
import ChargerSessionDetails from './charger-session-details.components';
import { SessionStyle } from './styles/styles';
import ChargerIdlingComponent from './charger-idling.component';
import { PricingType } from '../../../stores/types/price.interface';
import ChargerRestartedComponent from './charger-restarted.component';
import FeedbackWithOptions from '../../feedback/feedback-with-options.component';

// Charger Session card parent component.
// Higher order component for session card datails.

interface IProps {
  classes: ClassNameMap;
  buttonStatus: BUTTON_STATUS;
  activeSession: Session;
  transactionId: string;
  onSessionStopped: Function;
  charger: any;
  showTechView: boolean;
  transaction: any;
  isChargingBySelf?: boolean;
}

const ChargerSession = ({
  buttonStatus,
  classes,
  activeSession,
  transactionId,
  onSessionStopped,
  charger,
  showTechView = false,
  transaction,
  isChargingBySelf = false,
}: IProps) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const formatDate = (dateString: string) => {
    return moment(dateString, 'YYYY-MM-DDTHH:mm:ssZ')
      .locale(i18n.language)
      .format('h:mm A, MMMM DD, YYYY');
  };

  const handleReceiptClick = () => {
    navigate(
      `/${ROUTES.RECEIPTS}/${transaction?.receiptId}?transaction=${transactionId}`,
    );
  };

  const subTitle = transaction?.stopTime
    ? `${i18n.t('completed_at')} ${formatDate(transaction?.stopTime)}`
    : `${i18n.t('started_at')} ${formatDate(activeSession?.createTime)}`;

  const receiptId = transaction?.receiptId;

  const [
    triggerFetchReceipt,
    { data: receipt, isSuccess: isFetchReceiptSuccess },
  ] = useLazyFetchReceiptQuery();

  useEffect(() => {
    if (receiptId) {
      // we got receipt id means the charger is stopped.
      triggerFetchReceipt(receiptId);
      onSessionStopped();
    }
  }, [transaction]);

  // Check receiptId to make sure we are using the final transaction information when the session is stopped
  const sessionDetails = useMemo(() => {
    return {
      cost: !receiptId
        ? activeSession.runningTotals.cost
        : transaction?.billedTotalAmount,
      consumedEnergyKilowattHours: !receiptId
        ? activeSession.runningTotals.consumedEnergyKilowattHours
        : transaction?.consumedEnergyKilowattHours,
      pluggedInTimeSeconds: !receiptId
        ? activeSession.runningTotals.pluggedInTimeSeconds
        : transaction?.pluggedInTimeSeconds,
    };
  }, [activeSession, transaction]);

  // BF-2556 "costBreakdown" doesn't always have a value, sometimes it may return null.
  const lastCostItem: CostBreakdownItem | null =
    activeSession.runningTotals.costBreakdown?.length > 0
      ? activeSession.runningTotals.costBreakdown[
          activeSession.runningTotals.costBreakdown.length - 1
        ]
      : null;
  const costItemsLength: number =
    activeSession.runningTotals.costBreakdown?.length || 0;

  const {allowDriverImprovements} = useUserPreference();

  return (
    <Grid container>
      <Grid className={classes.head}>
        {charger?.maintenanceFlag && !showTechView && activeSession && (
          <ChargerSessionWarningComponent />
        )}
      </Grid>
      {!transaction?.stopTime &&
        (activeSession.idleFeeStatus === IdleFeesStatus.GRACE_PERIOD ||
          activeSession.idleFeeStatus === IdleFeesStatus.IMPOSED) && (
          <Grid className={classes.head}>
            {lastCostItem && (
              <ChargerIdlingComponent
                session={activeSession}
                charger={charger}
                lastCostItem={lastCostItem}
              />
            )}
          </Grid>
        )}
      {!transaction?.stopTime &&
        costItemsLength > 1 &&
        lastCostItem &&
        activeSession.idleFeeStatus === IdleFeesStatus.NOT_IMPOSED &&
        lastCostItem.pricingType !== PricingType.IDLE &&
        lastCostItem.periodEnergyKilowattHours > 0 && (
          <Grid className={classes.head} style={{ marginTop: '24px' }}>
            <ChargerRestartedComponent />
          </Grid>
        )}
      <ChargerSessionDetails session={sessionDetails} />
      <ChargerCostDetails
        session={activeSession}
        isChargingBySelf={isChargingBySelf}
        charger={charger}
        receipt={receipt}
      />
      {transaction?.receiptId && allowDriverImprovements && (
        <Grid item xs={12} sx={{ my: 2 }}>
          <FeedbackWithOptions transaction={transaction} />
        </Grid>
      )}
      {transaction?.receiptId && (
        <Grid className={classes.buttonContainer}>
          <SessionButton
            label={i18n.t('view_full_script')}
            handleReceiptClick={handleReceiptClick}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(SessionStyle)(ChargerSession);
