import { Invoice } from '../stores/types/invoice.interface';
import { Api, API_KEYS } from './base.api';

export const invociesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceBalance: builder.query<Invoice, void>({
      query: () => ({
        url: `${API_KEYS.INVOICES}/balance`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetInvoiceBalanceQuery } = invociesApi;
