import { ListItemText } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ChargerDtoV2 } from '../dto/charger.dto';
import PricingList from './pricing-list.component';
import { getDurationCount, getRateCount } from './utils';

const styles = {
  listWrapper: {
    marginLeft: '-15px',
  },
};

interface TieredPricingInterface {
  classes: ClassNameMap;
  charger: ChargerDtoV2;
}

const TieredPricing = ({ classes, charger }: TieredPricingInterface) => {
  const { tierPrices } = charger?.priceSchedule;
  const { i18n } = useTranslation();

  return (
    <ListItemText>
      <PricingList column1={i18n.t('charging')} type='listHead' />
      {tierPrices?.map((pricePeriod, i) => {
        const { duration, unitPrice, pricingType, currency, durationUnit } =
          pricePeriod;
        return (
          <PricingList
            column1={getDurationCount(
              duration,
              pricingType,
              i,
              durationUnit,
              i18n
            )}
            column2={getRateCount(unitPrice, pricingType, currency)}
          />
        );
      })}
    </ListItemText>
  );
};

export default withStyles(styles)(TieredPricing);
