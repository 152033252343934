import React, { useMemo } from 'react';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CustomButton from '../react-native-material-wrapper/button.component';
import { makeStyles } from '@mui/styles';
import { questionHelpIcon } from '../../assets/icons/icons';
import { useTranslation } from 'react-i18next';
import { ConnectorStatus } from '../../types/connector-status.enum';

const useStyles = makeStyles(() => ({
      buttonStyles: {
        color: '#ffffff',
        bottom: 0,
        position: 'sticky',
        height: 48,
        width: '100%',
        fontSize: '16px',
        fontFamily: 'Inter',
        fontWeight: 600,
        lineHeight: '24px',
        borderRadius: 4,
        textTransform: 'inherit',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
      helpHeader: {
        color: '#4E5968',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        paddingLeft: '8px',
      },
      helpIcon: {
        display: 'flex',
        width: '20px',
        height: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '20px',
      },
      headerFrame: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        padding: '0px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      padding8: {
        padding: '0px 8px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      helpInfo: {
        color: '#202223',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      padding0: {
        padding: '24px 0 0 0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }
}));
const EmergencyStop = ({
    stopCharge,
    isChargingBySelf,
    charger,
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const unavailableCharger = charger.ports.every(
    (ele) =>
      ele.statusWithoutMaintenance?.toLowerCase() === // statusWithoutMaintenance is optional now
        ConnectorStatus.UNAVAILABLE.toLowerCase() ||
      ele.status.toLowerCase() === ConnectorStatus.UNKNOWN.toLowerCase(),
  );

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {(
        <Box
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            borderRadius: '8px',
            backgroundColor: '#F2F4F6',
            padding: '12px 8px',
          }}
        >
          <ListItemButton onClick={handleClick} className={classes.headerFrame}>
            <ListItemIcon className={classes.helpIcon}>
                {questionHelpIcon()}
            </ListItemIcon>
            <ListItemText primary={i18n.t('help')} classes={{ primary: classes.helpHeader}} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton className={classes.padding8}>
            <ListItemText primary={i18n.t('help_info')} classes={{ primary: classes.helpInfo}}/>
          </ListItemButton>
          <ListItemButton className={classes.padding0}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: '#F44336',
          }}
          onClick={stopCharge}
          disabled={isChargingBySelf && unavailableCharger}
        >
          {i18n.t('emergency_stop')}
        </CustomButton>
          </ListItemButton>
        </List>
      </Collapse>
        </Box>
      )}
    </>
  );
};

export default EmergencyStop;
