export const formatCardNumber = (value: string) => {
  const regex = /^[0-9a-zA-Z]+$/;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (_regex, $1, $2) =>
    [$1, $2].filter((group) => !!group).join(' ')
  );
};

export const formatRfidCardNumber = (inputString: string) => {
  // Remove all characters except numbers and letters

  const formattedString = inputString.replace(/[^a-zA-Z0-9]/g, '');

  return formattedString;
};
