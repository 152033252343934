import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CancelButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    width: '100%',
    height: '48px',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Inter',
    padding: '6px 12px',
    lineHeight: 1.5,
    color: '#4E5968',
    backgroundColor: '#F2F4F6',
    '&:hover': {
      backgroundColor: '#E5E8EB',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F2F4F6',
    },
  },
})(Button);
