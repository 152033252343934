import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(() => ({
  logoutWrapper: {
    height: '100vh',
    background: '#FAFAFA',
  },
  container: {
    padding: '24px 24px 24px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: 16,
    lineHeight: '20px',
  },
  root: {
    maxWidth: '425px',
    width: '100%',
    height: '90px',
    position: 'fixed',
    bottom: 0,
    margin: 0,
    padding: '8px 20px',
    background: '#fff',
    boxSizing: 'border-box',
  },
  buttonItem: {
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: '4px !important',
    textTransform: 'inherit',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
