export enum PaymentMode {
  NONE = 'NONE',
  PAYMENT_SETTING = 'PAYMENT_SETTING',
  PAYMENT_ADD = 'PAYMENT_ADD',
  PAYMENT_DELETE = 'PAYMENT_DELETE',
}

export enum CardStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  NOT_EXPIRED = 'NOT_EXPIRED',
}
