import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';

const styles = {
  listWrapper: {
    marginLeft: '-15px',
  },
  list: {
    marginBottom: '6px',
  },
  listHead: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
  },
  freeCharging: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
  },
  listTitle: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 400,
    color: '#202223',
  },
  listText: {
    fontSize: 16,
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#6B7684',
  },
  active: {
    fontSize: 16,
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#202223',
  },
  alignTextEnd: {
    display: 'flex',
    justifyContent: 'end',
  },
};

interface IProps {
  classes: ClassNameMap;
  column1: string;
  column2?: string;
  type?: string;
  active?: boolean;
}

const PricingList = ({ classes, column1, column2, type, active }: IProps) => {
  return (
    column1 && (
      <Grid container className={classes.list}>
        <Grid item xs={6}>
          {column1 && (
            <Typography className={active ? classes.active : classes[type]}>
              {column1}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.alignTextEnd}>
          <Typography className={active ? classes.active : classes[type]}>
            {column2}
          </Typography>
        </Grid>
      </Grid>
    )
  );
};

PricingList.defaultProps = {
  column2: '',
  type: 'listText',
};

export default withStyles(styles)(PricingList);
