import { ListItem, IconButton, Grid, Typography } from '@mui/material';
import { withStyles, WithStyles, ClassNameMap } from '@mui/styles';

import { userStyles } from './styles';
import { forwardIcon } from '../../assets/icons/icons';
import { useTranslation } from 'react-i18next';
import { UserAddress } from '../../stores/types/user.types';
import clsx from 'clsx';

interface IProps {
  title: string;
  value?: string;
  onClickBack?: Function;
  subTitle?: string;
  icon: (value) => any;
  isRouteGetHelp?: boolean;
  isVerified?: boolean;
  addressValue?: UserAddress;
  classes: ClassNameMap;
}

const UserField = ({
  classes,
  onClickBack,
  title,
  value,
  isVerified,
  addressValue = {} as UserAddress,
}: IProps) => {
  const { i18n } = useTranslation();

  const handleRoute = () => {
    if (onClickBack) {
      onClickBack();
    }
  };

  const verifiedText = () => (
    <Typography
      className={clsx(classes.labelS, {
        [classes.verified]: isVerified,
        [classes.notVerified]: !isVerified,
      })}
    >
      {isVerified ? i18n.t('verified') : i18n.t('not_verified')}
    </Typography>
  );

  return (
    <ListItem button onClick={handleRoute}>
      <Grid container className={classes.wrapper}>
        <Grid item xs={8}>
          <Typography className={classes.title}>{title}</Typography>
          {addressValue.addressLine1 ? (
            <>
              <Grid container>
                <Grid container>
                  <Typography
                    className={clsx(classes.description, classes.textOverflow)}
                  >
                    {addressValue.addressLine1}
                  </Typography>
                  {addressValue.addressLine2 && (
                    <>
                      <Typography className={classes.description}>
                        {','}
                      </Typography>
                      <Typography className={classes.description}>
                        &nbsp;
                      </Typography>
                      <Typography className={classes.description}>
                        {addressValue.addressLine2}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid container>
                  <Typography className={classes.description}>
                    {addressValue.city}
                    {','}
                  </Typography>
                  <Typography className={classes.description}>
                    &nbsp;
                  </Typography>
                  <Typography className={classes.description}>
                    {addressValue.province}
                  </Typography>
                  <Typography className={classes.description}>
                    &nbsp;
                  </Typography>
                  <Typography className={classes.description}>
                    {addressValue.postalCode}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography
                className={clsx(classes.description, classes.textOverflow)}
              >
                {value && value}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={3} className={classes.item} justifyContent='right'>
          {isVerified !== undefined && verifiedText()}
        </Grid>
        <Grid item xs={1} className={classes.item}>
          <IconButton
            className={classes.disableRipple}
            disableRipple={true}
            edge='end'
            onClick={handleRoute}
          >
            {forwardIcon()}
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default withStyles(userStyles)(UserField);
