import * as React from 'react';
const SvgConnectorType2Ccs = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    fill='none'
    viewBox='0 0 40 40'
    {...props}
  >
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M26.852 23.844H13.657c-3.145 0-5.725 2.579-5.725 5.725v1.237c0 3.146 2.58 5.725 5.725 5.725h13.195c3.145 0 5.725-2.58 5.725-5.725V29.57c-.015-3.146-2.58-5.725-5.725-5.725ZM15.238 34.906c0-.253-.06-.477-.194-.626-.224-.254-.552-.254-.686-.254H13.91c-.118 0-.446 0-.67.254-.134.164-.194.373-.179.626-.015 0-.03-.015-.045-.015-2.564-.611-4.264-3.086-3.876-5.65.388-2.505 2.52-4.324 5.084-4.324.149 0 .283 0 .432.015 2.535.194 4.518 2.386 4.533 4.995.014 2.415-1.61 4.457-3.951 4.98Zm12.225 0c0-.253-.06-.477-.194-.626-.224-.254-.552-.254-.686-.254H26.136c-.12 0-.447 0-.67.254-.135.164-.195.373-.18.626-.015 0-.03-.015-.045-.015-2.564-.611-4.263-3.086-3.876-5.65.388-2.505 2.52-4.324 5.084-4.324.15 0 .283 0 .432.015 2.535.194 4.518 2.386 4.533 4.995.015 2.415-1.61 4.457-3.951 4.98Z'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M14.134 31.507a1.58 1.58 0 1 0 0-3.16 1.58 1.58 0 0 0 0 3.16ZM26.375 31.507a1.58 1.58 0 1 0 0-3.16 1.58 1.58 0 0 0 0 3.16ZM20.038 10.86c.376-.041.654-.402.627-.777a.723.723 0 0 0-.78-.626.723.723 0 0 0-.626.778c.041.39.403.667.78.626ZM22.606 6.731a.543.543 0 0 0 .464-.576.536.536 0 0 0-.577-.464.535.535 0 0 0-.464.577c.03.288.299.494.577.463ZM17.43 6.731a.543.543 0 0 0 .464-.576.536.536 0 0 0-.578-.464.535.535 0 0 0-.464.577.528.528 0 0 0 .578.463Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      fillRule='evenodd'
      d='M19.972 18.951c4.973 0 9.005-3.808 9.005-8.505 0-2.367-.467-4.065-2.119-5.607-1.341-1.252-1.537-1.25-4.556-1.21-.642.008-1.412.018-2.347.018-.936 0-1.705-.01-2.347-.018-3.02-.04-3.215-.042-4.557 1.21-1.651 1.542-2.084 3.24-2.084 5.607 0 4.697 4.032 8.505 9.005 8.505Zm-.011-6.7a2.091 2.091 0 1 0 0-4.183 2.091 2.091 0 0 0 0 4.183Zm-1.23-6.04a1.36 1.36 0 1 1-2.718 0 1.36 1.36 0 0 1 2.719 0Zm3.818 1.36a1.36 1.36 0 1 0 0-2.719 1.36 1.36 0 0 0 0 2.72Z'
      clipRule='evenodd'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      fillRule='evenodd'
      d='M31.986 10.298c0 4.286-2.449 7.96-6.033 9.923-.65.386-1.339.712-2.06.97h3.116a8.125 8.125 0 0 1 8.126 8.124v1.76a8.125 8.125 0 0 1-8.126 8.125H12.965a8.125 8.125 0 0 1-8.125-8.125v-1.76a8.125 8.125 0 0 1 8.125-8.125h3.116c-.538-.19-1.06-.42-1.559-.684-3.884-1.876-6.587-5.705-6.587-10.208 0-2.989.564-5.46 2.918-7.658.464-.433.926-.85 1.445-1.16.574-.343 1.152-.518 1.822-.603.617-.078 1.351-.082 2.233-.074.277.002.573.006.894.01.742.01 1.617.021 2.693.021s1.951-.011 2.693-.02c.321-.005.617-.009.894-.011.882-.008 1.616-.004 2.233.074.671.085 1.248.26 1.823.603.519.31.98.727 1.445 1.16 2.342 2.187 2.958 4.646 2.958 7.658ZM17.9 19.983c-4.789-.904-8.397-4.896-8.397-9.685 0-2.748.502-4.72 2.42-6.511 1.558-1.454 1.785-1.451 5.292-1.406.745.01 1.639.022 2.725.022 1.087 0 1.98-.012 2.726-.022 3.506-.045 3.733-.048 5.291 1.406 1.918 1.79 2.46 3.763 2.46 6.511 0 4.435-3.093 8.187-7.354 9.435-.273.08-.552.148-.835.207-.272.043-.647.28-.65.563v1.507c0 .268.21.477.463.507h4.968c3.742 0 6.799 3.056 6.799 6.798v1.76c0 3.742-3.057 6.798-6.799 6.798H12.965c-3.742 0-6.798-3.056-6.798-6.798v-1.76c0-3.742 3.056-6.798 6.798-6.798H17.9a.52.52 0 0 0 .477-.522v-1.492a.53.53 0 0 0-.477-.52Z'
      clipRule='evenodd'
    />
  </svg>
);
export default SvgConnectorType2Ccs;
