import React from 'react';

interface UnhappyFaceProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const UnhappyFace: React.FC<UnhappyFaceProps> = ({
  width = 48,
  height = 48,
  color = '#B0B8C1',
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M29.6 22.4C30.2667 22.4 30.8333 22.1667 31.3 21.7C31.7667 21.2333 32 20.6667 32 20C32 19.3333 31.7667 18.7667 31.3 18.3C30.8333 17.8333 30.2667 17.6 29.6 17.6C28.9333 17.6 28.3667 17.8333 27.9 18.3C27.4333 18.7667 27.2 19.3333 27.2 20C27.2 20.6667 27.4333 21.2333 27.9 21.7C28.3667 22.1667 28.9333 22.4 29.6 22.4ZM18.4 22.4C19.0667 22.4 19.6333 22.1667 20.1 21.7C20.5667 21.2333 20.8 20.6667 20.8 20C20.8 19.3333 20.5667 18.7667 20.1 18.3C19.6333 17.8333 19.0667 17.6 18.4 17.6C17.7333 17.6 17.1667 17.8333 16.7 18.3C16.2333 18.7667 16 19.3333 16 20C16 20.6667 16.2333 21.2333 16.7 21.7C17.1667 22.1667 17.7333 22.4 18.4 22.4ZM24 26.4C22.1867 26.4 20.54 26.9133 19.06 27.94C17.58 28.9667 16.5067 30.32 15.84 32H18.48C19.0667 31.0133 19.8467 30.2333 20.82 29.66C21.7933 29.0867 22.8533 28.8 24 28.8C25.1467 28.8 26.2067 29.0867 27.18 29.66C28.1533 30.2333 28.9333 31.0133 29.52 32H32.16C31.4933 30.32 30.42 28.9667 28.94 27.94C27.46 26.9133 25.8133 26.4 24 26.4ZM24 40C21.7867 40 19.7067 39.58 17.76 38.74C15.8133 37.9 14.12 36.76 12.68 35.32C11.24 33.88 10.1 32.1867 9.26 30.24C8.42 28.2933 8 26.2133 8 24C8 21.7867 8.42 19.7067 9.26 17.76C10.1 15.8133 11.24 14.12 12.68 12.68C14.12 11.24 15.8133 10.1 17.76 9.26C19.7067 8.42 21.7867 8 24 8C26.2133 8 28.2933 8.42 30.24 9.26C32.1867 10.1 33.88 11.24 35.32 12.68C36.76 14.12 37.9 15.8133 38.74 17.76C39.58 19.7067 40 21.7867 40 24C40 26.2133 39.58 28.2933 38.74 30.24C37.9 32.1867 36.76 33.88 35.32 35.32C33.88 36.76 32.1867 37.9 30.24 38.74C28.2933 39.58 26.2133 40 24 40ZM24 36.8C27.5733 36.8 30.6 35.56 33.08 33.08C35.56 30.6 36.8 27.5733 36.8 24C36.8 20.4267 35.56 17.4 33.08 14.92C30.6 12.44 27.5733 11.2 24 11.2C20.4267 11.2 17.4 12.44 14.92 14.92C12.44 17.4 11.2 20.4267 11.2 24C11.2 27.5733 12.44 30.6 14.92 33.08C17.4 35.56 20.4267 36.8 24 36.8Z'
      fill={color}
    />
  </svg>
);

export default UnhappyFace;
