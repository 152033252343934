import { ListItemText } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { RuleTypes } from '../../../stores/types/price.interface';
import { ChargerDtoV2 } from '../dto/charger.dto';
import PricingDetails from './pricing-details.component';
import PricingList from './pricing-list.component';
import TieredPricing from './tiered-pricing.component';

const styles = {
  listWrapper: {
    marginLeft: -15,
  },
};

interface ChargerPricingInterface {
  classes: ClassNameMap;
  charger: ChargerDtoV2;
}

const ChargerPricing = ({ classes, charger }: ChargerPricingInterface) => {  
  const { type, dayPrices } = charger?.priceSchedule;
  const { i18n } = useTranslation();

  const renderFreeDayPrice = (
    <ListItemText>
      <PricingList column1={i18n.t('free_charging')} type='freeCharging' />
    </ListItemText>
  );

  const renderPriceView = () => {
    let view;
    switch (type) {
      case RuleTypes.FREE:
        view = renderFreeDayPrice;
        break;
      case RuleTypes.TIERED:
        view = <TieredPricing charger={charger} />;
        break;
      default:
        view = dayPrices ? (
          <PricingDetails charger={charger} />
        ) : (
          renderFreeDayPrice
        );
    }
    return view;
  };

  return renderPriceView();
};

export default withStyles(styles)(ChargerPricing);
