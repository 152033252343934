import { Grid } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  sessionDurationIcon,
  sessionEnergyIcon,
  sessionMoneyIcon,
} from '../../../assets/icons/icons';
import SessionItem from './session-item.component';
import { DetailsStyles } from './styles/detailsStyles';
import { readableDuration } from '../../../util/date-utils';

interface IProps {
  classes: ClassNameMap;
  session: {
    cost: number;
    consumedEnergyKilowattHours: number;
    pluggedInTimeSeconds: number;
  };
}

const ChargerSessionDetails = ({ classes, session }: IProps) => {
  const { i18n } = useTranslation();
  const { cost, consumedEnergyKilowattHours, pluggedInTimeSeconds } = session;
  const fixedPrice = (value) => value?.toFixed(2);
  const fixedConsumedEnergy = (value) => value?.toFixed(1);

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.itemContainer}>
        <SessionItem
          label={i18n.t('duration')}
          details={readableDuration(pluggedInTimeSeconds)}
          Icon={sessionDurationIcon}
        />
        <SessionItem
          label={i18n.t('energy')}
          details={`${fixedConsumedEnergy(consumedEnergyKilowattHours)} kWh`}
          Icon={sessionEnergyIcon}
        />
        <SessionItem
          label={i18n.t('cost')}
          details={`$${fixedPrice(cost)}`}
          Icon={sessionMoneyIcon}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(DetailsStyles)(ChargerSessionDetails);
