import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { useAuth } from '../../hooks/useAuth';
import {
  useSubmitAssociationIdMutation,
  useFetchAssociationsQuery,
} from '../../services/association.api';
import { useNavigate } from 'react-router-dom';
import AssociationIdForm from './associationid-form';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useDomain } from '../../hooks/useDomain';
import { ROUTES } from '../../types/routes.enum';
import { useLocation } from 'react-router-dom';
import AddPayment from './add-payment.component';
import { useFetchListOfPayMethodsQuery } from '../../services/payment.api';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

const SubmitHomeId = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const association = new URLSearchParams(location.search).get('associationId');
  const [associationId, setAssociationId] = useState<string>(association || '');
  const [error, setError] = useState('');
  const auth = useAuth();
  const theme = useSelector(getTheme);
  const { domain } = useDomain();
  const { data: payment, isLoading: isPaymentLoading } =
    useFetchListOfPayMethodsQuery();
  const navigate = useNavigate();
  const { data, isLoading: isAssociationDataLoading } =
    useFetchAssociationsQuery();
  const associationData = data?.association?.find(
    (item) => item.type.toLowerCase() === domain.toLowerCase(),
  );

  useEffect(() => {
    if (associationData?.associationId) {
      setAssociationId(associationData?.associationId);
    }
  }, [associationData?.associationId]);

  const [
    triggerSubmitId,
    { isSuccess: isSubmitIsSuccess, isError: isSubmitIdError, isLoading },
  ] = useSubmitAssociationIdMutation();

  const errorMessage = useMemo(() => {
    if (isSubmitIdError) {
      return i18n.t('enter_valid_home_id', { companyName: domain });
    } else {
      return error;
    }
  }, [isSubmitIdError, error, i18n]);

  const isValidId = () => {
    let res = true;
    setError('');
    const regEx = /^[0-9a-zA-Z]+$/;
    const isAlpha = regEx.test(associationId);
    const is18Char = associationId.length === 18;
    if (!isAlpha || !is18Char) {
      res = false;
      setError(
        i18n.t('enter_valid_home_id', {
          companyName: `${domain.charAt(0).toUpperCase()}${domain.slice(1)}`,
        }),
      );
    }
    return res;
  };

  const submit = async () => {
    if (isValidId()) {
      const obj = {
        type: domain,
        associationId: associationId,
      };
      await triggerSubmitId(obj);
    }
  };

  const redirectSignOut = () => {
    const path = association
      ? `/app/${domain}/${ROUTES.LOGIN_HOME_ID}?associationId=${association}`
      : `/app/${domain}/${ROUTES.LOGIN_HOME_ID}`;
    navigate(path);
  };

  const logOut = () => {
    try {
      auth.signOut(redirectSignOut);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const inputHandler = (id: string) => {
    setError('');
    setAssociationId(id);
  };

  // const [triggerRemovePayMethod] = useRemovePayMethodsMutation();

  return (
    <ViewWrapper>
      {isAssociationDataLoading || !payment ? (
        <div style={{ minHeight: '100vh' }}>
          <LoadingDots />
        </div>
      ) : !payment?.hasPaymentMethod ? (
        <AddPayment logOut={logOut} />
      ) : (
        <AssociationIdForm
          associationId={associationId}
          error={errorMessage}
          isLoading={isLoading}
          isSubmitIsSuccess={isSubmitIsSuccess}
          logOut={logOut}
          setAssociationId={inputHandler}
          submit={submit}
        />
      )}

      {/* temp button for debug this change.
      <CustomButton
        onClick={() => {
          triggerRemovePayMethod({
            ids: payments.map((payment) => payment.sourceId),
          });
        }}
      >
        Delete
      </CustomButton> */}
    </ViewWrapper>
  );
};

export default SubmitHomeId;
