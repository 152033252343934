import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { submitIfEnterIsClicked } from '../../util/js-utility';
import CustomButton from '../react-native-material-wrapper/button.component';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import { Auth } from 'aws-amplify';
import parsePhoneNumber from 'libphonenumber-js';
import { showSnackBar } from '../../util/toast.utility';
import { useTranslation } from 'react-i18next';
import { useFetchReceiptSettingsQuery } from '../../services/env.api';
import { useAuth } from '../../hooks/useAuth';
import { authStyles } from '../login/authStyles';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { usePostHog } from 'posthog-js/react';

export const CodeVerification = ({
  phoneNumberOrEmailLogin,
  loginIsEmail,
  codeSentToEmail,
  close = null,
  theme = null,
  chargerName = '',
  user = {},
  confirm = false,
}) => {
  const [otp, setOtp] = useState('');
  const { i18n } = useTranslation();
  const classes = authStyles();
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();
  // For some reason, when the OTP submitted is not correct, the user session will be invalid the next time the user tried to re-enter the correct code,
  // so the user needs to click the resend link and we need to set the new user returned by cognito
  const [otpNotReceivedUser, setOtpNotReceivedUser] = useState(user);

  const [resendOtpClicked, setResendOtpClicked] = useState<boolean>(false);
  const [otpTimer, setOtpTimer] = useState<string>('');

  // For UI display
  const [formattedPhoneNumberOrEmail, setFormattedPhoneNumberOrEmail] =
    useState('');

  const { data: receiptSettings } = useFetchReceiptSettingsQuery();
  const auth = useAuth();

  const submit = useCallback(async () => {
    if (otp.length !== 5) {
      return;
    }

    setLoading(true);
    const loggedInUser = await Auth.sendCustomChallengeAnswer(
      otpNotReceivedUser,
      otp,
    ).catch((error) => {
      setLoading(false);
      if (error.message === 'Incorrect username or password.') {
        // Happens when the first attempt the user input is invalid code (didn't type correctly)
        showSnackBar(i18n.t('Sign_in_failed'));
      } else if (error.message === 'Invalid session for the user.') {
        // Happens when the first attempt is invalid code, then on the second correct the user tries correct code
        showSnackBar(i18n.t('one_time_code_is_no_longer_valid'), true);
        setOtp('');
      } else {
        showSnackBar(error.message);
      }
    });

    if (loggedInUser) {
      const sessionToken = loggedInUser.signInUserSession.accessToken.jwtToken;
      auth.setToken(sessionToken);

      // Capture the sign-in event using PostHog
      posthog.capture('automatic_sign_in', {
        method: 'verification code',
        timestamp: new Date(), // Capture the timestamp of when the event occurred
      });
      if (close) {
        await close();
      }
    }
  }, [close, otp, otpNotReceivedUser, chargerName, i18n]);

  const resendOtp = async () => {
    setResendOtpClicked(true);
    timer(60);

    // Login the user again to resend the otp, the user returned by this call should be set to replace the old user object
    const user = await Auth.signIn(phoneNumberOrEmailLogin);
    await Auth.sendCustomChallengeAnswer(user, phoneNumberOrEmailLogin, {
      isEmail: '' + loginIsEmail, // make the boolean value as a string
    });
    // Replace the old user with the new returned user
    setOtpNotReceivedUser(user);
    setOtp('');
  };

  const formatPhoneNumber = (phone) => {
    const cleanPhone = phone.indexOf('+') < 0 ? '+' + phone : phone;
    const phoneNumber = parsePhoneNumber(cleanPhone);
    return phoneNumber.formatNational();
  };

  const timer = (remaining: number) => {
    const m = Math.floor(remaining / 60);
    const s = remaining % 60;

    const minuteString: string = m < 10 ? `${m}` : `${m}`;
    const secondsString: string = s < 10 ? `0${s}` : `${s}`;
    setOtpTimer(`${minuteString}:${secondsString}`);
    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(() => {
        timer(remaining);
      }, 1000);
    } else {
      setResendOtpClicked(false);
    }
  };

  useEffect(() => {
    setFormattedPhoneNumberOrEmail(
      loginIsEmail
        ? phoneNumberOrEmailLogin
        : formatPhoneNumber(phoneNumberOrEmailLogin),
    );
  }, [loginIsEmail, phoneNumberOrEmailLogin]);

  const renderCodeSentInfo = () => {
    // This is required in cases where a code has been re-routed to email for users with intl phone numbers
    if (codeSentToEmail && !loginIsEmail) {
      return (
        <Typography className={classes.verifySubHeader}>
          {i18n.t('sent_code_to_email')}
        </Typography>
      );
    }

    return (
      <div>
        {codeSentToEmail ? (
          <Typography className={classes.verifySubHeader}>
            {i18n.t('enter_the_code_you_received')}
          </Typography>
        ) : (
          <Typography className={classes.verifySubHeader}>
            {i18n.t('five_digit_verification_code')}
          </Typography>
        )}

        <Typography
          className={classes.formatNumber}
          style={{
            color: theme.primary,
          }}
        >
          {formattedPhoneNumberOrEmail}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.authWrapper}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <Typography className={classes.verifyHeader}>
          {i18n.t('enter_verification_code')}
        </Typography>
        {renderCodeSentInfo()}
      </div>
      <div className={classes.formInput} style={{ margin: '24px 0px' }}>
        <CustomTextField
          value={otp}
          style={{ width: '100%' }}
          onChange={(e) => setOtp(e.target.value)}
          onKeyDown={(e) => submitIfEnterIsClicked(e, submit)}
          onKeyUp={(e) => submit()}
          variant='outlined'
          required
          autoFocus
          placeholder={i18n.t('enter_code')}
          inputProps={{ inputMode: 'numeric', maxLength: 5 }}
          disabled={loading}
        />
        <Typography
          style={{ marginTop: '8px' }}
          className={classes.description}
          variant='subtitle2'
        >
          {i18n.t('passwordless_help_text', {
            network: receiptSettings.companyName || '',
          })}
        </Typography>
      </div>
      <div className={classes.buttonGroup}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Typography className={classes.doNotReceiveText}>
            {resendOtpClicked
              ? `${i18n.t('send_new_code_disabled_for')} ${otpTimer}`
              : i18n.t('did_not_receive_code')}
          </Typography>
          <CustomButton
            className={classes.doNotReceiveBtn}
            style={{
              color: theme.primary,
            }}
            size='medium'
            onClick={() => {
              if (!resendOtpClicked) {
                resendOtp();
              }
            }}
          >
            {resendOtpClicked
              ? i18n.t('verification_code_sent')
              : i18n.t('resend_code')}
          </CustomButton>
        </div>

        <CustomButton
          className={classes.submitSignInBtn}
          variant='contained'
          size='medium'
          style={{
            backgroundColor:
              loading || otp?.length !== 5 ? theme.brand_0 : theme.primary,
            color: otp?.length !== 5 ? '#D1D6DB' : '#ffffff',
          }}
          onClick={submit}
        >
          {loading ? (
            <LoadingDots className={classes.absolute} />
          ) : confirm ? (
            i18n.t('confirm')
          ) : (
            i18n.t('sign_in')
          )}
        </CustomButton>
      </div>
    </div>
  );
};
