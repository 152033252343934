import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import pluginCharger from '../../assets/images/charger/charger-time-to-plugin.svg';
import { useTranslation } from 'react-i18next';
import { getTheme } from '../../stores/selectors/theme.selector';
import { ChargerQuestionHelpDropdown } from './charger-question-help-dropdown.component';
import { ChargerStickyFooter } from './charger-sticky-footer.component';
import { ChargerDtoV2 } from './dto/charger.dto';
import posthog from 'posthog-js';
import { useAuth } from '../../hooks/useAuth';
import { usePostHog } from 'posthog-js/react';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '40px 24px 0px',
    height: 'calc(100vh - 192px)',
  },
  header: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
  },
  subHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  dropdownWrapper: {},
  contentSection: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '16px',
  },
}));

interface ChargerTimeToPluginProps {
  charger: ChargerDtoV2;
  selectedConnectorId: number | null;
  sessionTimeout: () => void;
  sessionError: () => void;
  isStartSessionError?: any;
}

export const ChargerTimeToPlugin = ({
  charger,
  selectedConnectorId,
  sessionTimeout,
  sessionError,
  isStartSessionError,
}: ChargerTimeToPluginProps) => {
  const auth = useAuth();
  const theme = useSelector(getTheme);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const posthog = usePostHog();

  // 90 second timeout for charger plugin time
  const [timeLeft, setTimeLeft] = useState(90);

  useEffect(() => {
    // Only set an interval if there's time left
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(timerId);
    } else if (timeLeft === 0) {
      sessionTimeout();
      // Capture the session timeout event using PostHog
      posthog.capture('session_timed_out', {
        method: 'charging',
        timestamp: new Date(), // Capture the timestamp of when the event occurred
      });
    } else if (timeLeft > 0 && timeLeft < 90 && isStartSessionError) {
      sessionError();
    }
  }, [timeLeft]);

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container flexDirection='column' justifyContent='center'>
          <img src={pluginCharger} alt='Unplug Logo' />
          <Typography className={classes.header} style={{ marginTop: 16 }}>
            {t('time_to_plugin')}
          </Typography>
          <Grid container justifyContent='center' style={{ marginTop: 16 }}>
            <Typography className={classes.subHeader}>
              {t('session_time_out_text_1')}
            </Typography>
            <Typography
              className={classes.subHeader}
              style={{
                color: theme.primary || '#18A0D7',
                fontWeight: 600,
                paddingLeft: '2px',
              }}
            >
              {i18n.t('session_time_out_text_2', {
                timeoutSecond: timeLeft,
              })}
            </Typography>
            <Typography className={classes.subHeader}>
              {t('session_time_out_text_3')}
            </Typography>
          </Grid>
          <Typography className={classes.contentSection}>
            {t('question_help_text_desc_1')}
          </Typography>
        </Grid>
        <ChargerQuestionHelpDropdown />
      </div>
      <ChargerStickyFooter
        selectedConnectorId={selectedConnectorId}
        charger={charger}
      />
    </>
  );
};
