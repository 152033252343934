import { Button as Btn } from '@mui/material';
import React from 'react';

const CustomButton = (props) => {
  return React.createElement(Btn, {
    ...props,
    disableElevation: true,
  });
};

export default CustomButton;
