import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import CustomButton from '../react-native-material-wrapper/button.component';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { ROUTES } from '../../types/routes.enum';

interface IProps {
  classes: ClassNameMap;
}

const styles = {
  container: {
    padding: 72,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: '#18A0D7',
  },
};

const NoPageFound = ({ classes }: IProps) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <ViewWrapper>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.root}>
          <Typography variant='h6'>{i18n.t('oops')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <Typography variant='h5'>{i18n.t('no_page_found')}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <CustomButton
            size='large'
            style={{ color: '#18A0D7' }}
            onClick={() => navigate(ROUTES.ROOT, { replace: true })}
          >
            <Home />
          </CustomButton>
        </Grid>
      </Grid>
    </ViewWrapper>
  );
};

export default withStyles(styles)(NoPageFound);
