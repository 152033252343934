import { CreateZendeskTicketDto } from '../stores/types/zendesk.interface';
import { Api, API_KEYS } from './base.api';

export const zendeskApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    createTicketMethod: builder.mutation<CreateZendeskTicketDto, any>({
      query: (payload) => ({
        url: `/core/v1/${API_KEYS.ZENDESK}/ticket`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.ZENDESK, id: 'LIST' },
      ],
    }),
  }),
});

export const { useCreateTicketMethodMutation } = zendeskApi;
