import * as React from 'react';
const SvgConnectorJ1772 = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    width='100%'
    height='100%'
    viewBox='0 0 32 32'
    {...props}
  >
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M22.357 10.35a8.87 8.87 0 0 0-6.16-2.653A8.88 8.88 0 0 0 9.67 10.35a8.867 8.867 0 0 0-2.592 6.206 8.84 8.84 0 0 0 2.592 6.328 8.825 8.825 0 0 0 6.206 2.668h.107c4.925 0 8.95-3.995 8.95-8.905 0-2.378-.915-4.62-2.576-6.297Zm-2.745.213h.03a2.626 2.626 0 0 1 2.654 2.654c0 .716-.275 1.402-.778 1.905a2.616 2.616 0 0 1-1.876.778h-.015a2.678 2.678 0 0 1-2.653-2.668 2.675 2.675 0 0 1 2.638-2.668Zm-9.18.793a2.664 2.664 0 0 1 1.907-.793h.015a2.701 2.701 0 0 1 2.668 2.669c0 .701-.274 1.357-.793 1.875a2.68 2.68 0 0 1-1.905.808h-.016c-1.433 0-2.653-1.22-2.668-2.668.015-.701.29-1.372.793-1.89Zm.52 9.256h-.046c-.915 0-1.708-.778-1.724-1.708a1.736 1.736 0 0 1 .504-1.266 1.718 1.718 0 0 1 1.235-.518c.945 0 1.723.778 1.738 1.723 0 .945-.762 1.738-1.708 1.769Zm5.092 3.339h-.03c-1.48 0-2.684-1.19-2.684-2.653a2.688 2.688 0 0 1 2.684-2.684h.015c1.448 0 2.638 1.19 2.653 2.653 0 1.464-1.174 2.669-2.638 2.684Zm5.047-3.34h-.046a1.757 1.757 0 0 1-1.738-1.738c0-.945.778-1.723 1.723-1.723h.015c.946 0 1.723.763 1.738 1.708.031.93-.731 1.723-1.692 1.754Z'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M12.37 13.994a.78.78 0 0 0 .731-.809c-.015-.411-.396-.747-.808-.731-.412.015-.747.396-.732.808.03.427.396.762.808.732ZM19.672 13.994a.78.78 0 0 0 .732-.809c-.015-.411-.397-.747-.808-.731-.412.015-.747.396-.732.808.03.427.396.762.808.732ZM16.09 22.045c.412-.045.717-.442.687-.854a.793.793 0 0 0-.854-.686.793.793 0 0 0-.686.854.781.781 0 0 0 .854.686Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M20.102 5.333V3.808A.813.813 0 0 0 19.294 3a.807.807 0 0 0-.793.717h-4.986A.807.807 0 0 0 12.722 3a.813.813 0 0 0-.808.808v1.525C7.309 7.01 4 11.432 4 16.616c0 6.618 5.382 12 12 12 6.617 0 12-5.382 12-12 .015-5.169-3.278-9.606-7.898-11.283Zm-4.087 21.926c-5.855 0-10.627-4.772-10.627-10.628 0-5.855 4.772-10.627 10.627-10.627s10.628 4.772 10.628 10.627c0 5.856-4.773 10.628-10.628 10.628Z'
    />
  </svg>
);
export default SvgConnectorJ1772;
