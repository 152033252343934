import { PlugAndChargeKey } from '../stores/types/plug-and-charge-keys.interface';
import { Api, API_KEYS } from './base.api';

export const plugAndChargeKeys = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPlugAndChargeKeys: builder.query<any, PlugAndChargeKey>({
      query: () => ({
        url: API_KEYS.PLUG_AND_CHARGE_KEYS,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.PLUG_AND_CHARGE_KEYS, id: 'LIST' },
      ],
    }),
    addVehicle: builder.mutation<any, any>({
      query: (payload) => ({
        url: API_KEYS.PLUG_AND_CHARGE_KEYS,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PLUG_AND_CHARGE_KEYS, id: 'LIST' }
      ],
    }),
    deleteVehicle: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_KEYS.PLUG_AND_CHARGE_KEYS}/${encodeURIComponent(payload)}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PLUG_AND_CHARGE_KEYS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchPlugAndChargeKeysQuery,
  useAddVehicleMutation,
  useDeleteVehicleMutation,
} = plugAndChargeKeys;
