import { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useDomain } from '../../hooks/useDomain';
import { ROUTES } from '../../types/routes.enum';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { UserMode } from './types/user-mode.enum';
import UserName from './user-name/user-name.component';
import UserOverview from './user-overview.component';
import EditEmail from './edit-email/edit-email.component';
import EditPhone from './edit-phone/edit-phone.component';
import UserAddress from './user-address/user-address.component';
import { useLocation, useNavigate } from 'react-router-dom';

const User = () => {
  const [view, setView] = useState(UserMode.NONE);
  const auth = useAuth();
  const navigate = useNavigate();
  const { domain } = useDomain();
  const location = useLocation();
  const chargerName = new URLSearchParams(location.search).get('charger');

  const onClickBack = () => {
    navigate(
      `/app/${domain}/${ROUTES.ACCOUNT}${
        chargerName ? `?charger=${chargerName}` : ''
      }`
    );
  };

  const handleUserView = (value: UserMode) => {
    setView(value);
  };

  return (
    <ViewWrapper>
      {view === UserMode.NONE && (
        <UserOverview
          currentUser={auth.user?.attributes}
          onClickBack={onClickBack}
          handleUserView={handleUserView}
        />
      )}
      {view === UserMode.USER_EMAIL && (
        <EditEmail
          currentUser={auth.user?.attributes}
          handleUserView={handleUserView}
        />
      )}
      {view === UserMode.USER_PHONE && (
        <EditPhone
          currentUser={auth.user?.attributes}
          handleUserView={handleUserView}
        />
      )}
      {view === UserMode.USER_NAME && (
        <UserName setView={handleUserView} currentUser={auth.user.attributes} />
      )}
      {view === UserMode.USER_ADDRESS && (
        <UserAddress
          setView={handleUserView}
          currentUser={auth.user.attributes}
        />
      )}
    </ViewWrapper>
  );
};

export default User;
