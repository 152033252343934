import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { ViewWrapper } from '../../ui-components/view-wrapper.component';
import ActionButton from '../../user/action-button.component';
import { useDomain } from '../../../hooks/useDomain';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { useAuth } from '../../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../../util/js-utility';

const useStyles = makeStyles(() => ({
  orderHeading: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 600,
    fontFamily: 'Inter',
    paddingBottom: '16px',
  },
  orderMessage: {
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontSize: 14,
  },
}));

const TechnicianView = () => {
  const classes = useStyles();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { domain } = useDomain();

  const theme = useSelector(getTheme);

  const { i18n } = useTranslation();

  const chargerId = location?.state?.chargerId;
  const currentUserEmail = auth?.user?.attributes?.email;

  const [technicianViewValue, setTechnicianViewValue] = useState<boolean>(
    () => {
      const savedTechnicianViewValue = localStorage.getItem(
        `technicianView_${currentUserEmail}-${chargerId}`,
      );
      return savedTechnicianViewValue === 'true';
    },
  );

  const handleChange = () => {
    setTechnicianViewValue((prevValue) => {
      const updatedValue = !prevValue;
      localStorage.setItem(
        `technicianView_${currentUserEmail}-${chargerId}`,
        updatedValue.toString(),
      );
      return updatedValue;
    });
    navigate(-1);
  };

  const text = `<style> h{ font-size: 14px; font-weight : 600; line-height: 20px } a{ font-size: 14px; font-weight : 400; line-height: 20px } </style> ${i18n.t(
    technicianViewValue
      ? 'technicianViewOffMessage'
      : 'technicianViewOnMessage',
    {
      org: capitalizeFirstLetter(domain) || '',
    },
  )}`;

  return (
    <ViewWrapper>
      <Box component='div' p={3} pr={5.5}>
        <Typography className={classes.orderHeading}>
          {technicianViewValue
            ? i18n.t('technicianViewOffHeading')
            : i18n.t('technicianViewOnHeading')}
        </Typography>
        <Typography variant='body1' className={classes.orderMessage}>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </Typography>
      </Box>
      <ActionButton
        label={i18n.t(
          technicianViewValue
            ? 'technicianViewTurnOffLabel'
            : 'technicianViewTurnOnLabel',
        )}
        action={handleChange}
        containerStyle={{ bottom: '58px' }}
        buttonStyle={{
          backgroundColor: theme.secondaryBtnBgColor,
          color: theme.primary,
        }}
      />
      <ActionButton
        label={i18n.t('technicianViewReturnPageLabel')}
        action={() => navigate(-1)}
        buttonWrapperStyle={{ paddingTop: '8px' }}
      />
    </ViewWrapper>
  );
};

export default TechnicianView;
