import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NetworkLogo from './network-logo.component';
import { SUB_ROUTES } from '../../types/routes.enum';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: 16,
    color: '#202223',
    alignSelf: 'left',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineheight: 28,
  },
  description: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#202223',
  },
  primaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  secondaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

interface IProps {
  primaryText?: string;
  secondaryText?: string;
  description?: string;
  primaryTextClassName?: string;
  secondaryTextClassName?: string;
  descriptionTextClassName?: string;
}

const AuthHeader = ({
  primaryText,
  secondaryText,
  description,
  primaryTextClassName,
  secondaryTextClassName,
  descriptionTextClassName,
}: IProps) => {
  const classes = useStyles();
  const isIvy =
    window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase();
  return (
    <div className={classes.header}>
      <NetworkLogo width={'auto'} />
      {primaryText && (
        <Typography
          className={primaryTextClassName || classes.primaryText}
          style={{
            marginTop: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {primaryText}
        </Typography>
      )}
      {secondaryText && (
        <div
          className={secondaryTextClassName || classes.secondaryText}
          style={{
            marginTop: isIvy ? 32 : 16,
            alignSelf: 'center',
          }}
        >
          {secondaryText}
        </div>
      )}

      {description && (
        <div
          className={descriptionTextClassName || classes.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </div>
  );
};

export default AuthHeader;
