export const useStyles = {
  paddingBottom8: {
    paddingBottom: '8px',
  },
  dropDownSelectedItem: {
    backgroundColor: '#F2F4F6',
    color: '#18A0D7',
    borderRadius: '0.25rem',
  },
  dropdownMenuItem: {
    minWidth: '110px',
    padding: 16,
    cursor: 'pointer',
    borderRadius: '4px',
    color: '#4e5968',
  },
  dropdownMenuItemHover: {
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropDownMenu: {
    backgroundColor: '#fff',
    position: 'absolute' as 'absolute',
    display: 'block',
    listStyleType: 'none',
    left: 0,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '30rem',
    overflow: 'auto',
    zIndex: 1,
  },
  labelM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  displayBlock: {
    display: 'block',
  },
  paddingTop: {
    paddingTop: 8,
  },
  drodDownButton: {
    background: '#F2F4F6',
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    padding: '12px 8px 12px 16px',
    cursor: 'pointer',
    border: 0,
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  container: {
    padding: '24px 16px',
    background: '#FAFAFA',
    height: 'calc(100vh - 160px)',
  },
  Mb: {
    marginBottom: '16px',
  },
  PL8: {
    paddingLeft: '8px',
  },
  PR8: {
    paddingRight: '8px',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '24px',
    color: '#202223',
    marginBottom: '8px',
  },
  cardInput: {
    borderRadius: 4,
    // boxSizing: 'border-box',
    height: 48,
    padding: '16px 16px',
    background: '#F2F4F6',
  },
  errorLabel: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#E53935',
  },
  inputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
};
