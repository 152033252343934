import React from "react";
import PhoneInput from "react-phone-input-2";

const CustomPhoneInput = (props) => {
  return React.createElement(PhoneInput, {
    ...props,
  });
};

export default CustomPhoneInput;
