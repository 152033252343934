import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { getTheme } from '../../stores/selectors/theme.selector';
import CustomButton from '../react-native-material-wrapper/button.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    boxSizing: 'border-box',
    '& button': {
      width: '100% !important',
    },
  },
  buttonWrapper: {
    padding: '20px 16px',
  },
  buttonStyles: {
    color: '#ffffff',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
  },
}));

interface IProps {
  label: string;
  action: Function;
  isDisabled?: boolean;
  loading?: boolean;
  containerStyle?: any;
  buttonWrapperStyle?: any;
  buttonStyle?: any;
}
const ActionButton = ({
  label,
  action,
  isDisabled = false,
  loading = false,
  containerStyle = {},
  buttonWrapperStyle = {},
  buttonStyle = {},
}: IProps) => {
  const classes = useStyles();
  const theme = useSelector(getTheme);

  return (
    <Grid className={classes.root} style={{ ...containerStyle }}>
      <div className={classes.buttonWrapper} style={{ ...buttonWrapperStyle }}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: isDisabled
              ? '#F9FAFB'
              : theme.navigationSelectedColor,
            ...buttonStyle,
          }}
          onClick={action}
          disabled={isDisabled}
          loading={true}
        >
          {loading ? (
            <LoadingDots />
          ) : (
            label
          )}
        </CustomButton>
      </div>
    </Grid>
  );
};

export default ActionButton;
