import { authStyles } from '../login/authStyles';
import CustomButton from '../react-native-material-wrapper/button.component';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useTranslation } from 'react-i18next';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

interface IProps {
  loading: boolean;
  logOut: Function;
  submit: Function;
}
const AddIdButtons = ({ logOut, loading, submit }: IProps) => {
  const classes = authStyles();
  const theme = useSelector(getTheme);
  const { i18n } = useTranslation();

  return (
    <div className={classes.buttonGroup}>
      <CustomButton
        size='large'
        style={{
          color: theme.primary,
        }}
        onClick={logOut}
      >
        {i18n.t('log_out')}
      </CustomButton>
      <CustomButton
        variant='contained'
        size='large'
        style={{
          backgroundColor: loading ? '#a4a4a4' : theme.primary,
          color: '#ffffff',
        }}
        onClick={submit}
        disabled={loading}
      >
        {i18n.t('submit')}
        {loading && <LoadingDots />}
      </CustomButton>
    </div>
  );
};

export default AddIdButtons;
