import { useDomain } from '../../hooks/useDomain';
import { Assets_URL } from '../../util/js-utility';

const NetworkLogo = ({ networkId = null, width = '100%', marginRight = 0, height = '100%' }) => {
  const { domain } = useDomain();
  return (
    <img
      src={`${Assets_URL}?resourceId=logo-svg&domainName=${domain}`}
      // This is a quick fix to handle IVY logo (square shape), needs to be revisited to implement a robust logo display that can handle different logo shapes
      // networkId is only being provided in charger.component.tsx:192
      style={{
        width: networkId && networkId === 'IVY' ? 'auto' : width,
        maxHeight: 54,
        marginRight: networkId && networkId === 'IVY' ? 0 : marginRight,
        height,
        objectFit: 'contain',
      }}
      alt='Company Logo'
    />
  );
};

export default NetworkLogo;
