import TextField from '@mui/material/TextField';
import React from 'react';

const CustomTextField = (props) => {
  return React.createElement(TextField, {
    ...props,
  });
};

export default CustomTextField;
