import { useState } from 'react';
import { Grid, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { forwardIcon } from '../../assets/icons/icons';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import UserField from './user-field.component';
import { UserMode } from './types/user-mode.enum';
import { UserPersonalInfo } from '../../stores/types/user.types';
import { otpStyles } from './otp-verification/styles';

interface IProps {
  onClickBack: Function;
  handleUserView: Function;
  currentUser: UserPersonalInfo;
}
const UserOverview = ({ onClickBack, handleUserView, currentUser }: IProps) => {
  const { i18n } = useTranslation();
  const [errMessage, setErrMessage] = useState('');
  const classes = otpStyles();

  const clickUserPhone = () => {
    if (currentUser?.email_verified) {
      handleUserView(UserMode.USER_PHONE);
    } else {
      setErrMessage('email_must_verified_to_edit_phone');
    }
  };

  return (
    <div>
      <BackButtonHeader
        title={i18n.t('personal_info')}
        onBackArrowClicked={onClickBack}
      />
      {errMessage && (
        <Grid className={classes.errorContainer}>
          <Typography className={classes.errorMessage}>
            {i18n.t(errMessage)}
          </Typography>
        </Grid>
      )}
      <Grid>
        <List>
          <UserField
            onClickBack={() => handleUserView(UserMode.USER_NAME)}
            title={i18n.t('name')}
            value={`${currentUser?.given_name || ''} ${currentUser?.family_name || ''
              }`}
            icon={forwardIcon}
          />
          <UserField
            onClickBack={() => handleUserView(UserMode.USER_EMAIL)}
            title={i18n.t('email')}
            value={currentUser?.email}
            icon={forwardIcon}
            isVerified={currentUser?.email_verified}
          />
          <UserField
            onClickBack={clickUserPhone}
            title={i18n.t('phone_number')}
            value={currentUser?.phone_number}
            icon={forwardIcon}
            isVerified={currentUser?.phone_number_verified}
          />
          <UserField
            onClickBack={() => handleUserView(UserMode.USER_ADDRESS)}
            title={i18n.t('address')}
            addressValue={currentUser?.address}
            icon={forwardIcon}
          />
        </List>
      </Grid>
    </div>
  );
};

export default UserOverview;
