import { withStyles } from '@mui/styles';
import { Button, ButtonProps } from '@mui/material/';
import { useSelector } from 'react-redux';
import { getTheme } from '../../../stores/selectors/theme.selector';

const CustomButton = withStyles({
  root: {
    display: 'flex',
    width: '400px',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    height: '48px',
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: '#E8F7FC',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#E8F7FC',
    },
  },
})(Button);

interface IProps extends ButtonProps {
  label: string;
  handleReceiptClick: () => void;
}

const SessionButton = ({ label, handleReceiptClick }: IProps) => {
  const theme = useSelector(getTheme);
  return (
    <CustomButton
      variant='contained'
      color='primary'
      onClick={handleReceiptClick}
      style={{ fontWeight: 600, backgroundColor: theme.brand_0, color: theme.brand_2 }}
    >
      {label}
    </CustomButton>
  );
};

export default SessionButton;
