import React, { useState } from 'react';
import OtpVerification from '../otp-verification/otp-verification.component';
import EmailForm from './email-form.component';
import { UserPersonalInfo } from '../../../stores/types/user.types';
import { i18n } from '../../../services/translation/i18n';
import { useAuth } from '../../../hooks/useAuth';
import { UserMode } from '../types/user-mode.enum';
import { Auth } from 'aws-amplify';

interface IProps {
  currentUser: UserPersonalInfo;
  handleUserView: Function;
}

const EditEmail = ({ currentUser, handleUserView }: IProps) => {
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [email, setEmail] = useState(currentUser?.email || '');
  const [error, setError] = useState<boolean>(false);

  const auth = useAuth();

  const { refreshUserData } = auth;
  const isCurrentEmail = email === currentUser?.email;

  const verifyUserEmail = async () => {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      email: email.trim(),
    })
      .then(() => {
        setVerifyOtp(true);
        refreshUserData();
        setError(false);
      })
      .catch((e) => {
        setError(true);
      });
  };

  const verifyEmailValidationCode = async (code) => {
    await Auth.verifyCurrentUserAttributeSubmit('email', code)
      .then(async () => {
        refreshUserData();
        handleUserView(UserMode.NONE);
      })
      .catch((e) => {
        setError(true);
      });
  };

  const resendOtp = () => {
    Auth.verifyCurrentUserAttribute('email');
  };
  return (
    <>
      {verifyOtp ? (
        <OtpVerification
          otpLength={6}
          headerTitle={i18n.t('verify_email')}
          verifyUser={email}
          setVerifyOtp={setVerifyOtp}
          handleVerifyOtp={verifyEmailValidationCode}
          sendNewCode={resendOtp}
          error={error}
          setError={setError}
          description={'we_have_sent_a_code'}
          description1={i18n.t('enter_code_to_verify_email')}
        />
      ) : (
        <EmailForm
          isCurrentEmail={isCurrentEmail}
          setView={handleUserView}
          setVerifyOtp={setVerifyOtp}
          email={email}
          setEmail={setEmail}
          handleSubmitEmail={verifyUserEmail}
          isEmailVerified={currentUser?.email_verified}
        />
      )}
    </>
  );
};

export default EditEmail;
