import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import 'moment/locale/fr';
import 'moment/locale/es';

import { SessionStyle } from './styles/styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import unplugCharger from '../../../assets/images/charger/charger-time-to-unplug.svg';
import {
  ChargerDtoV2,
  CostBreakdownItem,
  IdleFeesStatus,
  Session,
} from '../dto/charger.dto';
import {
  getRateCountWithoutType,
  getTimeinAMPMFormat,
} from '../charger-pricing/utils';
import { PricingType } from '../../../stores/types/price.interface';
import { useMemo } from 'react';
interface IProps {
  classes: ClassNameMap;
  session: Session;
  charger: ChargerDtoV2;
  lastCostItem: CostBreakdownItem;
}
const ChargerIdling = ({ classes, session, charger, lastCostItem }: IProps) => {
  const { i18n } = useTranslation();
  // to check whether charger has idle fees
  const timeTounplugWithoutIdleFees = () => {
    return (
      charger.currentPrice.idleRate === 0 &&
      (session.idleFeeStatus === IdleFeesStatus.GRACE_PERIOD ||
        session.idleFeeStatus === IdleFeesStatus.IMPOSED)
    );
  };
  // to check no idle fees, time plugged in pricing
  const timePluggedInWithoutIdleFees = () => {
    return (
      timeTounplugWithoutIdleFees() &&
      charger.currentPrice.pricingType === PricingType.BILLED_BY_TIME_PLUGGED_IN
    );
  };

  const isFreeAndPublic = useMemo(() => {
    return (
      charger.owner.type === 'COMPANY' &&
      charger.currentPrice.pricingType === 'FREE'
    );
  }, [charger.owner.type, charger.currentPrice.pricingType]);

  return (
    <Box
      style={{
        padding: '16px 12px 16px 12px',
      }}
    >
      <Grid container item justifyContent='center'>
        <img src={unplugCharger} alt='Unplug Logo' />
      </Grid>
      <Box style={{ paddingBottom: '16px' }}>
        <Typography
          style={{
            padding: '8px',
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '32px',
              fontFamily: 'Inter',
              textAlign: 'center',
            }}
          >
            {i18n.t('time_to_unplug')}
          </Typography>
        </Typography>
        <Typography className={classes.message}>
          {timePluggedInWithoutIdleFees()
            ? i18n.t('no_idle_fees_message')
            : isFreeAndPublic
            ? i18n.t('idle_fees_enabled_message_free_public')
            : i18n.t('idle_fees_enabled_message')}
        </Typography>
      </Box>
      {!timeTounplugWithoutIdleFees() && (
        <Box
          style={{
            borderRadius: '8px',
            backgroundColor: '#F2F4F6',
            padding: '16px 12px 16px 12px',
          }}
        >
          <Typography
            className={classes.heading}
            style={{
              display: 'flex',
              paddingBottom: '8px',
            }}
          >
            <WarningRoundedIcon
              style={{ marginRight: '5px', color: '#FF8F00' }}
            />
            <Typography className={classes.subHeader}>
              {session.idleFeeStatus === IdleFeesStatus.GRACE_PERIOD
                ? i18n.t('idle_fees_start', {
                    time: getTimeinAMPMFormat(session.idleFeeStartTime),
                  })
                : i18n.t('idle_fees_active')}
            </Typography>
          </Typography>
          <Typography className={classes.message2}>
            {session.idleFeeStatus === IdleFeesStatus.GRACE_PERIOD ? (
              i18n.t('idle_fees_start_warning', {
                cost: getRateCountWithoutType(
                  charger.currentPrice.idleRate,
                  charger.currentPrice.currency,
                ),
                time: getTimeinAMPMFormat(session.idleFeeStartTime),
              })
            ) : (
              <>
                <span className={classes.warning}>
                  {i18n.t('idle_fees_active_message', {
                    cost: getRateCountWithoutType(
                      charger.currentPrice.idleRate,
                      charger.currentPrice.currency,
                    ),
                  })}
                </span>{' '}
                <span>{i18n.t('idle_fees_active_end')}</span>
              </>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(SessionStyle)(ChargerIdling);
