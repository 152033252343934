import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuth } from '../../hooks/useAuth';
import { getTheme } from '../../stores/selectors/theme.selector';
import { PricingType, RuleTypes } from '../../stores/types/price.interface';
import CustomButton from '../react-native-material-wrapper/button.component';
import { BUTTON_STATUS } from './charger.component';
import { useFetchListOfPayMethodsQuery } from '../../services/payment.api';
import { ConnectorStatus } from '../../types/connector-status.enum';
import { ChargerDtoV2, IdleFeesStatus, Session } from './dto/charger.dto';
import { useUserPreference } from '../../hooks/useUserPreference';
import { ErrorRounded, Info, InfoRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../types/routes.enum';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
    '& button': {
      width: '100% !important',
    },
  },

  bank: {
    backgroundColor: '#F44336',
    color: 'white',
    padding: '8px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'Inter',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '56px',
  },
  addcard: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none !important',
    float: 'right',
    fontSize: '14px',
    fontWeight: 600,
  },
  cardLink: {
    display: 'inline-block',
    width: '15%',
    verticalAlign: 'bottom',
  },
  cardWrapper: {
    display: 'inline-block',
    width: '80%',
  },
  buttonWrapper: {
    padding: '20px 24px',
    backgroundColor: '#EBF8FF',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  buttonWrapperInfo: {
    display: 'flex',
    gap: '8px',
    '& .info-text': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#202223',
    },
  },
  buttonStyles: {
    color: '#ffffff',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  addPaymentContainer: {
    padding: '16px 24px 24px 24px',
    background: '#EBF8FF',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
  },
  addPaymentLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addPaymentRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addPaymentTypography: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#202223',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  sessionErrorMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#202223',
    alignItems: 'center',
  },
  sessionErrorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#FDEDED',
    padding: '16px 24px 24px 24px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.10))',
  },
  startSession: {
    display: 'flex',
    height: '24px',
    gap: '8px',
    alignItems: 'center',
  },
}));

/**
 * Sticky buttons at the bottom of the page
 *
 */

interface ChargerButtonProp {
  startCharge: Function;
  charger: ChargerDtoV2;
  stopCharge: Function;
  buttonStatus: BUTTON_STATUS;
  loading: boolean;
  showTechView: boolean;
  isChargingBySelf: boolean;
  selectedConnectorId: string | null;
  paymentPageRedirect: any;
  sessionStartErrorMsg: string;
  activeSession: Session;
}
const ChargerButtons = ({
  startCharge,
  charger,
  stopCharge,
  buttonStatus,
  loading,
  showTechView = false,
  isChargingBySelf,
  selectedConnectorId,
  paymentPageRedirect,
  sessionStartErrorMsg = '',
  activeSession,
}: ChargerButtonProp) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const auth = useAuth();

  const theme = useSelector(getTheme);
  const { data: payment } = useFetchListOfPayMethodsQuery();
  const { userIsNotSelectedForPayGo } = useUserPreference();
  const navigate = useNavigate();

  const needBankCard = useMemo(() => {
    return (
      !payment?.hasPaymentMethod &&
      charger &&
      charger.priceSchedule.type !== RuleTypes.FREE
    );
  }, [charger, payment]);

  const renderLoading = () => {
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: theme?.brand_0,
          }}
          disabled
        >
          <LoadingDots />
        </CustomButton>
      </div>
    );
  };

  const renderAddPaymentMethod = () => {
    return (
      <div className={classes.addPaymentContainer}>
        <div
          style={{
            paddingBottom: '16px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={classes.addPaymentLeft}>
            <InfoRounded style={{ color: theme.brand_3 }} />
          </div>
          <div className={classes.addPaymentRight}>
            <Typography className={classes.addPaymentTypography}>
              {i18n.t('add_payment_method_start_charging')}
            </Typography>
          </div>
        </div>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{ backgroundColor: theme.brand_2, padding: '12px 16px' }}
          // search param is added in order to redirect user directly to add payment page
          onClick={() =>
            navigate(
              `/app/${window.domainName}/${ROUTES.PAYMENTS}?view=add${
                charger?.name ? `&charger=${charger.name}` : ''
              }`,
            )
          }
        >
          {i18n.t('payment_method_add')}
        </CustomButton>
      </div>
    );
  };

  const renderSelectPortsButon = () => {
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: '#F9FAFB',
            color: '#D1D6DB',
          }}
          disabled
        >
          {i18n.t('select_port')}
        </CustomButton>
      </div>
    );
  };

  const renderStartSessionButton = () => {
    const disabled =
      needBankCard || (charger?.maintenanceFlag && !showTechView);
    return (
      <div className={classes.buttonWrapper}>
        <div className={classes.buttonWrapperInfo}>
          <Info style={{ color: theme.primary }} />
          <div className='info-text'>{i18n.t('vehical_unplug_warning')}</div>
        </div>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: disabled ? '#F9FAFB' : theme.primary,
          }}
          onClick={startCharge}
          disabled={disabled}
          disableElevation
        >
          {i18n.t('start_session')}
        </CustomButton>
      </div>
    );
  };

  const renderSessionStartErrorMsg = () => {
    return (
      <div className={classes.sessionErrorContainer}>
        <div style={{ paddingRight: '8px' }}>
          <ErrorRounded
            style={{ color: '#E53935', width: '24px', height: '24px' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.sessionErrorMessage}>
            {i18n.t(sessionStartErrorMsg)}
          </Typography>
        </div>
      </div>
    );
  };

  // For MVP of PayGo :
  // userIsNotSelectedForPayGo : false ---> user will get error msg for failed session start
  const renderStart = () => {
    // Add payment page should be on top of Start Session / Select Port btn
    if (needBankCard && payment) {
      return <div>{renderAddPaymentMethod()}</div>;
    }
    return (
      <div>
        {!userIsNotSelectedForPayGo &&
          sessionStartErrorMsg &&
          renderSessionStartErrorMsg()}
        {selectedConnectorId || charger.ports?.length === 1
          ? renderStartSessionButton()
          : renderSelectPortsButon()}
      </div>
    );
  };

  const renderStop = () => {
    const unavailableCharger = charger.ports.every(
      (ele) =>
        ele.statusWithoutMaintenance?.toLowerCase() === // statusWithoutMaintenance is optional now
          ConnectorStatus.UNAVAILABLE.toLowerCase() ||
        ele.status.toLowerCase() === ConnectorStatus.UNKNOWN.toLowerCase(),
    );
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: '#F44336',
          }}
          onClick={stopCharge}
          disabled={isChargingBySelf && unavailableCharger}
        >
          {i18n.t('stop')}
        </CustomButton>
      </div>
    );
  };

  if (
    !auth.isLogined ||
    !payment ||
    // driver login but don't have access
    !charger.userHasAccess ||
    // no available chargers and in session charging by others, not me.
    (!charger.isAnyConnectorAvailable && !isChargingBySelf)
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      {loading
        ? renderLoading()
        : buttonStatus === BUTTON_STATUS.IN_SESSION &&
          activeSession.idleFeeStatus !== IdleFeesStatus.IMPOSED
        ? renderStop()
        : buttonStatus !== BUTTON_STATUS.IN_SESSION
        ? renderStart()
        : null}
    </div>
  );
};

export default ChargerButtons;
