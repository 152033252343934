import {
  // createSlice,
  // createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { deploymentApi } from '../../services/deployment.api';

export const getNewApiUrlPrefix = createSelector(
  [deploymentApi.endpoints.fetchAPIPrefix.select(undefined)],
  (endpoint) => {
    return endpoint.data?.newApiUrlPrefix || '';
  },
);
export const getLegacyApiUrlPrefix = createSelector(
  [deploymentApi.endpoints.fetchAPIPrefix.select(undefined)],
  (endpoint) => {
    return endpoint.data?.apiUrlPrefix || '';
  },
);

export const getStripeKey = createSelector(
  [deploymentApi.endpoints.fetchStripeKey.select(undefined)],
  (endpoint) => endpoint.data.api_key || '',
);

export const getCognito = createSelector(
  [deploymentApi.endpoints.fetchCognito.select(undefined)],
  (endpoint) => endpoint.data || {},
);
