export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const portsOrder = ['LEFT', 'MIDDLE', 'RIGHT'];

export const sortChargerPorts = (ports) => {
  const portArray = [...ports];
  return portArray?.sort((prev: any, next: any) => {
    // A port may have multi-connectors, currently we only use default one.
    const prevLocation = prev?.connectorLocations[0]?.toUpperCase();
    const nextLocation = next?.connectorLocations[0]?.toUpperCase();

    if (prevLocation && nextLocation) {
      return (
        portsOrder.indexOf(prevLocation) - portsOrder.indexOf(nextLocation)
      );
    } else if (prevLocation) {
      return -1;
    } else if (nextLocation) {
      return 1;
    } else {
      return 0;
    }
  });
};
