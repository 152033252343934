import { makeStyles } from '@mui/styles';

export const otpStyles = makeStyles(() => ({
  formWrapper: {
    padding: 24,
    maxWidth: 425,
  },
  formInput: {
    marginTop: 20,
    marginBottom: 10,
  },
  textMedium: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    textAlign: 'center',
  },
  otpMargin: {
    margin: '24px 0px',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  confirmText: {
    color: '#6B7684',
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    zIndex: -1,
  },
  cardwrapper: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    padding: '16px',
  },
  errorContainer: {
    height: '52px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px 20px',
    background: '#E53935',
  },
  errorMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#FFFFFF',
  },
  codeSent: {
    color: '#7CB342',
    textDecoration: 'underline',
  },
  buttonText: {
    fontWeight: 500,
    textTransform: 'initial',
  },
}));

export const otpInputStyle = {
  width: '50px',
  height: '50px',
  borderRadius: 8,
  boxSizing: 'border-box',
  border: '2px solid #D1D6DB',
  fontSize: '1.5rem',
  fontWeight: 500,
  marginRight: '.5rem',
};

export const otpFocusStyle = {
  border: '2px solid #000000',
  borderRadius: '8px',
  boxShadow: '0 0 #000000',
  outline: '2px solid transparent !important',
  outlineOffset: '2px',
};
