import { Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import CustomButton from '../../react-native-material-wrapper/button.component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import { otpFocusStyle, otpInputStyle, otpStyles } from './styles';
import ActionButton from '../action-button.component';
import OtpInput from 'react-otp-input-visio';
import clsx from 'clsx';

interface IProps {
  otpLength: number;
  verifyUser: string;
  headerTitle: string;
  setVerifyOtp: Function;
  handleVerifyOtp: Function;
  sendNewCode: Function;
  description: string;
  description1: string;
  error?: boolean;
  setError?: Function;
}

const OtpVerification = ({
  otpLength,
  headerTitle,
  verifyUser,
  setVerifyOtp,
  handleVerifyOtp,
  sendNewCode,
  description,
  description1,

  error,
  setError,
}: IProps) => {
  const { i18n } = useTranslation();
  const [otp, setOtp] = useState<string>('');
  const [resendOtpClicked, setResendOtpClicked] = useState<boolean>(false);
  const [otpTimer, setOtpTimer] = useState<string>('');

  const theme = useSelector(getTheme);
  const classes = otpStyles();
  const otpInputRef = useRef(null);

  const onClickBack = () => {
    setVerifyOtp(false);
  };

  const timer = (remaining: number) => {
    const m = Math.floor(remaining / 60);
    const s = remaining % 60;

    const minuteString: string = m < 10 ? `${m}` : `${m}`;
    const secondsString: string = s < 10 ? `0${s}` : `${s}`;
    setOtpTimer(`${minuteString}:${secondsString}`);
    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(() => {
        timer(remaining);
      }, 1000);
    } else {
      setResendOtpClicked(false);
    }
  };

  const otpChanged = async (evt: any) => {
    setOtp(evt);
    setError(false);
  };

  const clickResendOtp = () => {
    sendNewCode();
    setOtp('');
    setResendOtpClicked(true);
    timer(60);
  };

  return (
    <>
      <BackButtonHeader title={headerTitle} onBackArrowClicked={onClickBack} />
      {error && (
        <Grid className={classes.errorContainer}>
          <Typography className={classes.errorMessage}>
            {i18n.t('code_is_not_correct')}
          </Typography>
        </Grid>
      )}
      <Grid container className={classes.formWrapper}>
        <Grid item xs={12}>
          <Typography className={classes.textMedium}>
            {i18n.t(description, { number: otpLength })}{' '}
            <span style={{ color: theme.navigationSelectedColor }}>
              {verifyUser}
            </span>
          </Typography>
          <Typography className={classes.textMedium}>{description1}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={clsx(classes.otpMargin, classes.alignCenter)}
        >
          <OtpInput
            ref={otpInputRef}
            value={otp}
            onChange={otpChanged}
            numInputs={otpLength}
            shouldAutoFocus
            inputStyle={otpInputStyle}
            focusStyle={otpFocusStyle}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container className={classes.alignCenter}>
            <Typography
              className={clsx(classes.textMedium, classes.confirmText)}
            >
              {resendOtpClicked
                ? `${i18n.t('send_new_code_disabled_for')} ${otpTimer}`
                : i18n.t('did_not_receive_code')}
            </Typography>
            {resendOtpClicked ? (
              <Typography
                className={clsx(classes.textMedium, classes.codeSent)}
              >
                {i18n.t('verification_code_sent')}
              </Typography>
            ) : (
              <CustomButton
                className={clsx(
                  classes.textMedium,
                  classes.textUnderline,
                  classes.buttonText,
                )}
                size='large'
                style={{
                  color: theme.primary,
                }}
                onClick={clickResendOtp}
              >
                {i18n.t('send_new_code')}
              </CustomButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ActionButton
        label={i18n.t('Submit')}
        action={() => handleVerifyOtp(otp)}
        isDisabled={otp.length !== 6}
      />
    </>
  );
};

export default OtpVerification;
