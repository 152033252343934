import { Grid } from '@mui/material';
import { settinStyles } from './styles';
import AccountMenu from './account-menu.component';
import { ClassNameMap, withStyles } from '@mui/styles';
interface IProps {
  classes: ClassNameMap;
}
const AccountSetting = ({ classes }: IProps) => {
  return (
    <Grid className={classes.container}>
      <AccountMenu />
    </Grid>
  );
};

export default withStyles(settinStyles)(AccountSetting);
