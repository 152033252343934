import { Image } from '../ui-components/image.component';
import { Button, Typography } from '@mui/material';
import googleSvg from '../../assets/icons/google.svg';
import { useTranslation } from 'react-i18next';
const GoogleRateButton: React.FC = () => {
  const { t } = useTranslation();

  const handleRateUs = () => {
    window.open('https://g.page/r/CXVjx4TGwulmEBM/review');
  };
  return (
    <Button
      onClick={handleRateUs}
      variant='outlined'
      endIcon={
        <Image
          src={googleSvg}
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      }
      sx={{
        width: '100%',
        height: '48px',
        backgroundColor: 'white',
        borderColor: '#d1d6db',
        borderRadius: '4px',
        padding: '12px 16px 12px 16px',
        border: '1px 0px 0px 0px',
        opacity: '0px',
      }}
    >
      <Typography
        sx={{
          color: '#202223',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Inter',
          lineHeight: '24px',
          textTransform: 'none',
          textAlign: 'center',
        }}
      >
        {t('feedback_rate_us_on_google')}
      </Typography>
    </Button>
  );
};

export default GoogleRateButton;
