import { Trans, useTranslation } from 'react-i18next';
import { Box, ListItem, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import 'moment/locale/fr';
import 'moment/locale/es';

import { SessionStyle } from './styles/styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
interface IProps {
  classes: ClassNameMap;
}
const ChargerSessionWarning = ({ classes }: IProps) => {
  const { i18n } = useTranslation();

  const warning = [
    i18n.t('session_vehicleProtection'),
    i18n.t('session_sessionDetail'),
    i18n.t('session_unplug'),
  ];

  return (
    <Box
      style={{
        backgroundColor: '#F2F4F6',
        padding: '16px 12px 16px 12px',
        borderRadius: '8px',
      }}
    >
      <Typography className={classes.heading} style={{
        display: 'flex',
        paddingBottom: '8px',
      }}>
        <WarningRoundedIcon style={{ marginRight: '10px', color: '#FF8F00' }} />
        <span style={{ fontWeight: 600 }}>{i18n.t('charger_out_of_service')}</span>
      </Typography>
      <Typography className={classes.label}>
        {i18n.t('charger_out_of_service_desc')}
      </Typography>
      <Typography className={classes.label}>
        {i18n.t('charger_means')}
      </Typography>
      {warning.map((desc) => (
        <ListItem style={{ padding: '0px' }}>
          <ul style={{ margin: '0px', paddingLeft: '20px' }}>
            <div>
              <li>
                <Typography className={classes.label}>
                  <Trans i18nKey={desc} />
                </Typography>
              </li>
            </div>
          </ul>
        </ListItem>
      ))}
    </Box>
  );
};

export default withStyles(SessionStyle)(ChargerSessionWarning);
