export const CodeError = {
  ERR_US_0003: 'ERR-US-0003',
  ERR_US_0004: 'ERR-US-0004',
  ERR_US_0005: 'ERR-US-0005',
  // The card issuer declined the payment, try another payment method or contact the card issuer
  ERR_BP_0006: 'ERR-BP-0006',
  // The pre-authorized payment was declined, try another payment method or contact the card issuer
  ERR_BP_0022: 'ERR-BP-0022',
  ERR_CS_0002: 'ERR_CS_0002',
  ERR_CS_0008: 'ERR-CS-0008',
};
