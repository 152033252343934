import { combineReducers } from 'redux';
import { envApi } from '../services/env.api';
import { deploymentApi } from '../services/deployment.api';
import { receiptApi } from '../services/receipt.api';
import { Api } from '../services/base.api';

const rootReducer = combineReducers({
  // event: EventsReducer,
  [Api.reducerPath]: Api.reducer,
  [envApi.reducerPath]: envApi.reducer,
  [deploymentApi.reducerPath]: deploymentApi.reducer,
  [receiptApi.reducerPath]: receiptApi.reducer,
});
export default rootReducer;
