import liteOnSC3 from '../../assets/images/charger/charger-lite-on-sc3.svg';
import phihongAH32 from '../../assets/images/charger/charger-phihong-ah32.svg';
import phihongAW32 from '../../assets/images/charger/charger-phihong-aw32.jpg';
import tritiumVeefilRT from '../../assets/images/charger/charger-tritium-veefil-rt.svg';
import evboxIqon from '../../assets/images/charger/charger-evbox-iqon.svg';
import genericCharger from '../../assets/images/charger/charger-generic.svg';

/**
 * TODO images currently baked in the code, this needs to be externalized
 *
 * Mapped to the charger.config.ts
 * "modelCode" field
 */
const chargerModelImages = Object.freeze({
  liteOnSC3,
  phihongAH32,
  phihongAW32,
  tritiumVeefilRT,
  evboxIqon,
});

const ChargerImage = ({ charger, size = 176, showGenericCharger = false }) => {
  const getChargerImage = () => {
    if (charger && charger.imageUrl) {
      return charger.imageUrl;
    }

    if (charger && charger.imageUrlList && charger.imageUrlList[0]) {
      return charger.imageUrlList[0];
    }

    return chargerModelImages['liteOnSC3'];
  };
  return (
    <img
      style={{ height: size, width: size }}
      src={showGenericCharger ? genericCharger : getChargerImage()}
      alt=''
    />
  );
};

export default ChargerImage;
