import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const plugAndChargeKeysStyle = makeStyles((theme: Theme) => ({
  textHeader: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 600,
  },
  paddingBottom8: {
    paddingBottom: '8px',
  },
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  addressConfirmationText: {
    fontWeight: 400,
    display: 'flex',
    textAlign: 'center',
    marginBottom: '32px',
    fontFamily: 'Inter',
  },
  addVehicleInputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
      backgroundColor: '#F2F4F6',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  button: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
    backgroundColor: '#E8F7FC',
    color: '#18A0D7',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#E8F7FC',
      boxShadow: 'none',
    },
    pointer: {
      cursor: 'pointer',
    },
    boxShadow: 'none',
  },
  orderButtonContainer: {
    backgroundColor: '#18A0D7',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#18A0D7',
      boxShadow: 'none',
    },
  },
}));
