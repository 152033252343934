import React, { createContext, useEffect, useState } from 'react';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentMode } from '../component/payments/types/payment-mode.enum';
import {
  useAddPayMethodMutation,
  useEditPaymentMethodMutation,
  useFetchListOfPayMethodsQuery,
  useRemovePayMethodsMutation,
} from '../services/payment.api';

import { showSnackBar } from '../util/toast.utility';
import {
  EditPaymentPayload,
  Payment,
  PaymentPayload,
} from '../stores/types/payment.interface';
import { useGetInvoiceBalanceQuery } from '../services/invoices.api';
import { Invoice } from '../stores/types/invoice.interface';
import { CodeError } from '../constant/text.constant';
import { useSearchParams } from 'react-router-dom';

type ContextType = {
  paymentView: string;
  handlePaymentView: Function;
  payments: Payment;
  balance: Invoice;
  isPrimary: boolean;
  setIsPrimary: Function;
  handleSavePayment: Function;
  selectedPayment: PaymentPayload;
  handleSelectPayment: Function;
  handleDeletePayment: Function;
  handleEditPayment: Function;
  addQueryResult: queryResultType;
  removeQueryResult: queryResultType;
  updateQueryResult: queryResultType;
  errorMsg: string;
  successMsg: string;
  editMode: boolean;
};

type queryResultType = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
};

const intialSelectValue = {
  last4: null,
  brand: null,
  funding: null,
  currency: null,
  isDefault: false,
  paymentMethodId: null,
  expiredDate: null,
  sourceId: null,
  expiredStatus: null,
  billingDetails: {
    name: null,
    email: null,
    address: {
      line1: null,
      line2: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
    },
  },
};
const initalState: ContextType = {
  paymentView: PaymentMode.NONE,
  handlePaymentView: () => {},
  payments: null,
  balance: null,
  isPrimary: false,
  setIsPrimary: () => {},
  handleSavePayment: () => {},
  selectedPayment: intialSelectValue,
  handleSelectPayment: () => {},
  handleDeletePayment: () => {},
  handleEditPayment: () => {},
  addQueryResult: null,
  removeQueryResult: null,
  updateQueryResult: null,
  errorMsg: '',
  successMsg: '',
  editMode: false,
};

export const PaymentContext = createContext(initalState);

const PaymentProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [isPrimary, setIsPrimary] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  // search param used to set the view of Payment
  const [paymentView, setPaymentView] = useState(
    searchParams && searchParams.get('view') === 'add'
      ? PaymentMode.PAYMENT_ADD
      : PaymentMode.NONE,
  );
  const [selectedPayment, setSelectedPayment] = useState(intialSelectValue);
  const [editMode, setEditMode] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const { refetch, data: payments } = useFetchListOfPayMethodsQuery();

  const handlePaymentView = (value: PaymentMode, edit: boolean = false) => {
    setIsPrimary(false);
    setPaymentView(value);
    setErrorMsg('');
    setSuccessMsg('');
    setEditMode(edit);
  };

  const handleSelectPayment = (value: PaymentPayload) => {
    setSelectedPayment(value);
  };

  const { data: balance } = useGetInvoiceBalanceQuery();

  const [triggerAddPayMethod, addQueryResult] = useAddPayMethodMutation();

  const [triggerRemovePayMethod, removeQueryResult] =
    useRemovePayMethodsMutation();

  const [triggerEditPayment, updateQueryResult] =
    useEditPaymentMethodMutation();

  const addPaymentMethodMobileApp = async (paymentMethod) => {
    triggerAddPayMethod({
      paymentMethodId: paymentMethod.id,
      isDefault: isPrimary,
    });
  };

  const isResultLoading =
    addQueryResult?.isLoading ||
    removeQueryResult?.isLoading ||
    updateQueryResult?.isLoading;

  const isResultSuccess =
    addQueryResult?.isSuccess ||
    removeQueryResult?.isSuccess ||
    updateQueryResult?.isSuccess;

  useEffect(() => {
    if (!isResultLoading && isResultSuccess) {
      handlePaymentView(PaymentMode.NONE);
      handleSelectPayment(intialSelectValue);
      setIsPrimary(false);
      // Need to show success message only for add payment success
      if (
        addQueryResult?.isSuccess &&
        !removeQueryResult?.isSuccess &&
        !updateQueryResult?.isSuccess
      ) {
        setSuccessMsg('add_payment_verify_success');
      }
      // Need to show success message only for edit payment success
      if (
        !addQueryResult?.isSuccess &&
        !removeQueryResult?.isSuccess &&
        updateQueryResult?.isSuccess
      ) {
        setSuccessMsg('edit_payment_success_msg');
      }
      // Reset mutation state manually to show correct success / error msg
      addQueryResult.reset();
      removeQueryResult.reset();
      updateQueryResult.reset();
    }
  }, [isResultLoading, isResultSuccess]);

  const getErrorMessage = (error: any) => {
    let errorMsg = 'failed_add_error_message';
    // Error occured due to preauth failed while adding payment card
    if (error?.data?.errorCode === CodeError.ERR_BP_0006) {
      errorMsg = 'add_payment_verify_error';
    }
    return errorMsg;
  };
  useEffect(() => {
    if (!isResultLoading && addQueryResult?.isError) {
      // User should stay on add payment page if there's payment error
      handlePaymentView(PaymentMode.PAYMENT_ADD);
      handleSelectPayment(intialSelectValue);
      setErrorMsg(getErrorMessage(addQueryResult?.error));
      // User should stay on add payment page with existing details
      setIsPrimary(isPrimary);
      // Reset mutation state manually to show correct success / error msg
      addQueryResult.reset();
    }
  }, [isResultLoading, addQueryResult?.isError]);

  const handleSavePayment = async (billinDetails: any) => {
    const cardNumder = await elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumder,
      billing_details: billinDetails,
    });
    if (error) {
      showSnackBar(error.message);
    } else {
      await addPaymentMethodMobileApp(paymentMethod);
      refetch();
    }
  };

  const handleDeletePayment = () => {
    triggerRemovePayMethod({
      id: selectedPayment?.paymentMethodId,
    });
  };

  const handleEditPayment = (editPayment: EditPaymentPayload) => {
    triggerEditPayment(editPayment);
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentView,
        selectedPayment,
        payments,
        balance,
        handlePaymentView,
        handleSavePayment,
        handleDeletePayment,
        handleEditPayment,
        handleSelectPayment,
        isPrimary,
        setIsPrimary,
        addQueryResult,
        removeQueryResult,
        updateQueryResult,
        errorMsg,
        successMsg,
        editMode,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
