import { ListItemText } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ScheduleType } from '../../../stores/types/price.interface';
import DayPricing from './day-pricing.component';
import PricingList from './pricing-list.component';
import { getRateCount } from './utils';
import { ChargerDtoV2 } from '../dto/charger.dto';

const styles = {
  listWrapper: {
    marginLeft: '-15px',
  },
  dividerTop: {
    marginBottom: '12px',
  },
  dividerBottom: {
    marginTop: '12px',
  },
};

interface PricingDetailsInterface {
  classes: ClassNameMap;
  charger: ChargerDtoV2;
}

const PricingDetails = ({ classes, charger }: PricingDetailsInterface) => {
  const { i18n } = useTranslation();
  const { type, dayPrices } = charger?.priceSchedule;

  const isFullDay = (startTime: string, endTime: string) =>
    startTime === endTime;

  const isEveryDayFullDay = () => {
    let str;
    if (type === ScheduleType.SAME_EVERYDAY) {
      const [prices] = dayPrices;
      if(prices?.pricePeriods.length > 0) {
        const [pricePeriod] = prices?.pricePeriods;

        const { unitPrice, pricingType, endTime, startTime, currency } =
          pricePeriod;
        if (isFullDay(startTime, endTime)) {
          str = getRateCount(unitPrice, pricingType, currency);
        }
      }
    }
    return str;
  };
  return (
    <ListItemText>
      <PricingList
        column1={i18n.t('charging')}
        column2={isEveryDayFullDay()}
        type='listHead'
      />
      {!isEveryDayFullDay() && <DayPricing charger={charger} />}
    </ListItemText>
  );
};

export default withStyles(styles)(PricingDetails);
