import { cardOrders, RfidCard } from '../stores/types/rfid.interface';
import { Api, API_KEYS } from './base.api';

const encodeRfidCardNumber = (cardNumber: string | undefined) => {
  if (cardNumber) {
    return encodeURIComponent(cardNumber);
  }
};

export const rfid = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRFIDCards: builder.query<any, cardOrders>({
      query: () => ({
        url: API_KEYS.CARD_ORDER,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CARD_ORDER, id: 'LIST' },
      ],
    }),
    fetchActivatedRFIDCards: builder.query<any, cardOrders>({
      query: () => ({
        url: API_KEYS.RFID_API_KEY,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.RFID_API_KEY, id: 'LIST' },
      ],
    }),
    activateCard: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_KEYS.RFID_API_KEY}/${payload}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CARD_ORDER, id: 'LIST' },
        { type: API_KEYS.RFID_API_KEY, id: 'LIST' },
      ],
    }),
    validateRFIDCard: builder.query<void, RfidCard>({
      query: (payload) => ({
        url: `${API_KEYS.RFID_API_KEY}/${encodeRfidCardNumber(
          payload.cardNumber,
        )}/activate:validate`,
        method: 'POST',
      }),
    }),
    orderRFIDCard: builder.mutation<any, any>({
      query: (payload) => ({
        url: API_KEYS.CARD_ORDER,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CARD_ORDER, id: 'LIST' },
      ],
    }),
    deleteRFIDCard: builder.mutation<any, any>({
      query: (payload) => ({
        url: `${API_KEYS.RFID_API_KEY}/${payload}/unlink`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.RFID_API_KEY, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useOrderRFIDCardMutation,
  useFetchRFIDCardsQuery,
  useFetchActivatedRFIDCardsQuery,
  useActivateCardMutation,
  useLazyValidateRFIDCardQuery,
  useDeleteRFIDCardMutation,
} = rfid;
