import React, { useState, useEffect, useCallback } from 'react';
import {
  Divider,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import CustomPhoneInput from '../react-native-material-wrapper/phone-input.component';
import CustomButton from '../react-native-material-wrapper/button.component';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { Image } from '../ui-components/image.component';
import EmptyProfile from '../../assets/images/empty_profile.png';
import EditIcon from '@mui/icons-material/Edit';
import { formatPhoneNumber } from '../../util/js-utility';
// import { updateUser } from "../../services/authentication.service";
import { UpdateUserDto } from './dto/update-user.dto';
import ListItemIcon from '@mui/material/ListItemIcon';
import TranslateIcon from '@mui/icons-material/Translate';
import InfoIcon from '@mui/icons-material/Info';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { showSnackBar } from '../../util/toast.utility';
import clsx from 'clsx';
// import { AppNavigation } from "../ui-components/app-navigation.component";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountSetting from './setting/account-setting.component';
import AuthButton from './auth-button';
import { getTheme } from '../../stores/selectors/theme.selector';
import {
  useFetchCurrentUserQuery,
  useUpdateUserMutation,
} from '../../services/user.api';
import { useTranslation } from 'react-i18next';
import { userStyles } from './styles';
import { ROUTES } from '../../types/routes.enum';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';

export const UserProfile = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = userStyles();
  const [editMode, setEditMode] = useState(false);
  const { data: currentUser } = useFetchCurrentUserQuery();
  // this state is used to hide extra design on page as per design
  const [disableAccountDesign] = useState(false);
  const [
    triggerUpdateUser,
    { isSuccess: isUpdateUserSuccess, isError: isUpdateUserError, isLoading },
  ] = useUpdateUserMutation();

  const chargerName = new URLSearchParams(location.search).get('charger');

  const theme = useSelector(getTheme);

  const [registrationForm, setRegistrationForm] = useState<UpdateUserDto>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const handleInputChange = (key, value) => {
    setRegistrationForm({ ...registrationForm, [key]: value });
  };

  const validateForm = useCallback(() => {
    if (
      !registrationForm.firstName ||
      !registrationForm.lastName ||
      !registrationForm.email ||
      !registrationForm.phone
    ) {
      showSnackBar('Please fill up all the required fields.');
      return false;
    }
    return true;
  }, [registrationForm]);

  useEffect(() => {
    isUpdateUserSuccess && showSnackBar('Account has been updated');
    isUpdateUserError && showSnackBar('Account update faield');
  }, [isUpdateUserSuccess, isUpdateUserError]);

  const updateProfile = () => {
    if (!validateForm()) return;
    triggerUpdateUser(registrationForm);
  };

  useEffect(() => {
    if (currentUser) {
      setRegistrationForm(currentUser);
    }
  }, [currentUser]);

  return (
    <ViewWrapper>
      {editMode ? (
        <>
          <BackButtonHeader title={`${i18n.t('edit')} ${i18n.t('account')}`} />
          <Grid
            container
            className={clsx(classes.marginTop, classes.contentPadding)}
          >
            <Grid item xs={6} className={classes.columnPaddingRight}>
              <CustomTextField
                className={classes.columnPaddingRight}
                value={registrationForm.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                label={i18n.t('first_name')}
                variant='outlined'
                required
                autoFocus
              />
            </Grid>
            <Grid item xs={6} className={classes.columnPaddingLeft}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                label={i18n.t('last_name')}
                variant='outlined'
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                disabled={true}
                value={registrationForm.email}
                className={classes.paddingBottom}
                onChange={(e) => handleInputChange('email', e.target.value)}
                label={i18n.t('email')}
                variant='outlined'
                required
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomPhoneInput
                style={{ display: 'flex', marginBottom: 15, opacity: 0.7 }}
                inputProps={{
                  required: true,
                }}
                disabled={true}
                inputStyle={{ width: '85vw', height: 55, fontSize: 16 }}
                country={'ca'}
                value={registrationForm.phone}
                onChange={(e) => handleInputChange('phoneNumber', e)}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.addressLine1}
                onChange={(e) =>
                  handleInputChange('addressLine1', e.target.value)
                }
                label={i18n.t('l_address_line_one')}
                variant='outlined'
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.addressLine2}
                onChange={(e) =>
                  handleInputChange('addressLine2', e.target.value)
                }
                label={i18n.t('l_address_line_two')}
                variant='outlined'
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
                label={i18n.t('city')}
                variant='outlined'
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={6} className={classes.columnPaddingRight}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.province}
                onChange={(e) => handleInputChange('province', e.target.value)}
                label={i18n.t('province')}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={6} className={classes.columnPaddingLeft}>
              <CustomTextField
                className={classes.paddingBottom}
                value={registrationForm.postalCode}
                onChange={(e) =>
                  handleInputChange('postalCode', e.target.value)
                }
                label={i18n.t('postal_code')}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <CustomTextField
                style={{
                  paddingBottom: 15,
                }}
                value={registrationForm.country}
                onChange={(e) => handleInputChange('country', e.target.value)}
                label={i18n.t('country')}
                variant='outlined'
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} className={classes.centerAlign}>
              <CustomButton
                variant='contained'
                size='large'
                style={{
                  backgroundColor: theme.primary,
                  color: '#ffffff',
                }}
                onClick={updateProfile}
              >
                {i18n.t('update')}
                {isLoading && (
                  <LoadingDots />
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <BackButtonHeader
            title={i18n.t('account')}
            onBackArrowClicked={
              chargerName ? () => navigate(`/${chargerName}`) : null
            }
          />
          {!currentUser ? (
            <Grid container justifyContent='center' alignItems='center'>
              <LoadingDots />
            </Grid>
          ) : (
            <div style={{ paddingTop: '16px' }}>
              <AccountSetting />
              {disableAccountDesign && (
                <>
                  <Grid container className={classes.profile}>
                    <Grid item xs={2}>
                      <Image src={EmptyProfile} />
                    </Grid>

                    <Grid item xs={8}>
                      <Typography variant='body2'>
                        <strong>
                          {registrationForm.firstName}{' '}
                          {registrationForm.lastName}
                        </strong>
                        <br />
                        {registrationForm.email}
                        <br />
                        {formatPhoneNumber(registrationForm.phone)}
                      </Typography>
                    </Grid>

                    <Grid item xs={2} className={classes.rightAlign}>
                      <Link
                        className={classes.pointer}
                        onClick={() => setEditMode(true)}
                      >
                        <EditIcon style={{ color: theme.primary }} />
                      </Link>
                    </Grid>
                  </Grid>

                  <Divider />

                  <List className={classes.marginTop}>
                    <ListItem button>
                      <ListItemIcon>
                        <TranslateIcon style={{ color: theme.primary }} />
                      </ListItemIcon>
                      <ListItemText primary='Language: English' />
                    </ListItem>
                    <Divider variant='inset' component='li' />

                    <ListItem
                      button
                      onClick={() =>
                        navigate(`/app/${window.domainName}/terms`)
                      }
                    >
                      <ListItemIcon>
                        <InfoIcon style={{ color: theme.primary }} />
                      </ListItemIcon>
                      <ListItemText primary={i18n.t('terms_of_service')} />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          onClick={() =>
                            navigate(`/app/${window.domainName}/terms`)
                          }
                        >
                          <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </>
              )}
              <Grid container>
                <Grid item xs={12} className={classes.authButton}>
                  <AuthButton
                    label={i18n.t('log_out')}
                    onClick={() =>
                      navigate(
                        chargerName
                          ? `/app/${window.domainName}/${ROUTES.LOGOUT}?charger=${chargerName}`
                          : `/app/${window.domainName}/${ROUTES.LOGOUT}`
                      )
                    }
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </ViewWrapper>
  );
};
