import { ChargerDtoV2 } from '../../component/charger/dto/charger.dto';
import { Payment } from './payment.interface';

export const enum ZENDESK_TICKET_TYPE {
  AUXILIARY_SUPPORT_CALL_TICKET = 'AUXILIARY_SUPPORT_CALL_TICKET',
}

export interface CreateZendeskTicketDto {
  ticketType: ZENDESK_TICKET_TYPE;
  payload: any;
}

export interface AuxiliarySupportCallTicketPayload {
  userApplication: string;
  hasValidPaymentMethod: boolean;
  rfidTag?: string;
  chargerId: string;
  chargerName: string;
  locationName: string;
  contactPhoneNumber: string;
  companyId: string;
}

export function createAuxiliarySupportCallTicketPayload(
  chargerDetail: ChargerDtoV2,
  payment: Payment,
  activatedCards: any,
): AuxiliarySupportCallTicketPayload {
  return {
    userApplication: 'WEB_APP',
    hasValidPaymentMethod: payment.hasValidPaymentMethod,
    rfidTag: activatedCards?.[0]?.number,
    chargerId: chargerDetail.chargerId,
    chargerName: chargerDetail.name,
    locationName: chargerDetail.location.name,
    contactPhoneNumber: chargerDetail.contactInfo,
    companyId: chargerDetail.owner.id,
  } as AuxiliarySupportCallTicketPayload;
}
