import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { showSnackBar } from '../util/toast.utility';

const ignoreList = [
  {
    endPointName: 'fetchTransactionById',
    status: [404],
  },
];

const shouldDisplayError = (action) => {
  const obj = ignoreList.find(
    (act) => act.endPointName === action?.meta?.arg?.endPointName,
  );
  if (!obj || !obj.status.includes(action?.payload?.status)) {
    return false;
  }
  return true;
};
/**
 * Log a warning and show a toast!
 */
export const rtkQueryLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!:', action);
      if (shouldDisplayError(action)) {
        showSnackBar(
          `${action.meta?.arg?.endpointName} Error: ${
            action.payload?.originalStatus || action.payload?.status
          } - ${action.payload?.data?.message}`,
        );
      }
    }

    return next(action);
  };
