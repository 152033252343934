import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

import addPlusDisabled from '../../../assets/icons/addPlus_disabled.svg';
import addPlus from '../../../assets/icons/addPlus.svg';
import { Image } from '../../ui-components/image.component';
import { ROUTES } from '../../../types/routes.enum';
import { useDomain } from '../../../hooks/useDomain';

export const AddRfidButton = ({ isActivatedCardLimitCrossed = false }) => {
  const navigate = useNavigate();
  const { domain } = useDomain();
  const { i18n } = useTranslation();

  return (
    <Box mt={2.5} mb={4} style={{ display: 'flex' }}>
      <Image
        src={isActivatedCardLimitCrossed ? addPlusDisabled : addPlus}
        style={{
          marginRight: '22px',
          marginLeft: '6px',
        }}
      />
      <Typography
        variant='subtitle1'
        component='span'
        color='textPrimary'
        style={
          isActivatedCardLimitCrossed
            ? { cursor: 'no-drop', color: '#D1D6DB' }
            : { cursor: 'pointer' }
        }
        onClick={() => {
          if (!isActivatedCardLimitCrossed)
            navigate(`/app/${domain}/${ROUTES.ADD_RFID}`);
        }}
      >
        {isActivatedCardLimitCrossed
          ? i18n.t('add_rfid_card_disabled')
          : i18n.t('add_rfid_card')}
      </Typography>
    </Box>
  );
};
