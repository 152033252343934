import { useMemo } from 'react';
import { Box } from '@mui/material';

import { ChargerConnector } from './charger-connector/charger-connector.component';

const ChargerStatus = ({
  connector,
  userAccessible,
  underMaintenance = false,
  showTechnicianIcon = false,
  numberOfPorts = 0,
  isChargingBySelf,
}) => {
  
  if (!connector || connector.length === 0) {
    return null;
  }
  return (
    <>
      {userAccessible && (
        <Box
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 8,
          }}
        >
          <ChargerConnector
            connector={connector}
            underMaintenance={underMaintenance}
            hasMySession={isChargingBySelf}
            showTechnicianIcon={showTechnicianIcon}
            numberOfPorts={numberOfPorts}
          />
        </Box>
      )}
    </>
  );
};

export default ChargerStatus;
