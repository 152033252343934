import React, { useState } from 'react';
import OtpVerification from '../otp-verification/otp-verification.component';
import { UserPersonalInfo } from '../../../stores/types/user.types';
import { i18n } from '../../../services/translation/i18n';
import { useAuth } from '../../../hooks/useAuth';
import { UserMode } from '../types/user-mode.enum';
import { Auth } from 'aws-amplify';
import PhoneForm from './phone-form.component';
import { parsePhoneNumberWithPlus } from '../../../util/js-utility';

interface IProps {
  currentUser: UserPersonalInfo;
  handleUserView: Function;
}

const EditPhone = ({ currentUser, handleUserView }: IProps) => {
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [phone, setPhone] = useState(currentUser?.phone_number || '');
  const [error, setError] = useState<boolean>(false);

  const isCurrentPhone =
    parsePhoneNumberWithPlus(phone) === currentUser?.phone_number;

  const auth = useAuth();

  const { refreshUserData } = auth;

  const verifyUserPhone = async () => {
    if (currentUser?.phone_number !== parsePhoneNumberWithPlus(phone)) {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        phone_number: parsePhoneNumberWithPlus(phone),
      })
        .then(() => {
          setVerifyOtp(true);
          refreshUserData();
        })
        .catch((e) => {
          setError(true);
        });
    } else {
      Auth.verifyCurrentUserAttribute('phone_number')
        .then(() => {
          setVerifyOtp(true);
          refreshUserData();
        })
        .catch((e) => {
          setError(true);
        });
    }
  };

  const verifyPhoneValidationCode = async (code) => {
    await Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
      .then(() => {
        refreshUserData(phone);
        handleUserView(UserMode.NONE);
      })
      .catch((e) => {
        setError(true);
      });
  };

  return (
    <>
      {verifyOtp ? (
        <OtpVerification
          otpLength={6}
          error={error}
          setError={setError}
          headerTitle={i18n.t('verify_phone_number')}
          verifyUser={parsePhoneNumberWithPlus(phone)}
          setVerifyOtp={setVerifyOtp}
          handleVerifyOtp={verifyPhoneValidationCode}
          sendNewCode={verifyUserPhone}
          description={'we_have_sent_code'}
          description1={i18n.t('enter_code_to_verify_phone')}
        />
      ) : (
        <PhoneForm
          isCurrentPhone={isCurrentPhone}
          setView={handleUserView}
          setVerifyOtp={setVerifyOtp}
          phone={phone}
          setPhone={setPhone}
          handleSubmitEmail={verifyUserPhone}
          isPhoneVerified={currentUser?.phone_number_verified}
        />
      )}
    </>
  );
};

export default EditPhone;
