export enum ChargerStatusEventType {
    V2_CHARGE_POINT_STATUS_UPDATE = "V2_CHARGE_POINT_STATUS_UPDATE",
    V2_ACTIVE_SESSIONS = "V2_ACTIVE_SESSIONS",
    V2_START_CHARGE = "V2_START_CHARGE",
    V2_NOTIFY_STARTED = "V2_NOTIFY_STARTED",
    V2_NOTIFY_STOPED = "V2_NOTIFY_STOPED",
    V2_CHARGE_POINT_BY_DISPLAY_ID = "V2_CHARGE_POINT_BY_DISPLAY_ID",
    V2_STOP_CHARGE = "V2_STOP_CHARGE",
    V2_LOCATIONS = "V2_LOCATIONS",
    V2_LOCATION_BY_ID = "V2_LOCATION_BY_ID",
    V2_EXPENSES = "V2_EXPENSES",
    V2_PAYMENT_HISTORY = "V2_PAYMENT_HISTORY",
    V2_CHARGE_SESSIONS_BY_MONTHS="V2_CHARGE_SESSIONS_BY_MONTHS",
}