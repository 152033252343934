import * as React from 'react';
const SvgConnectorType2 = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 30 26'
    fill='none'
    {...props}
  >
    <path
      fill={props.color}
      fillOpacity={0.87}
      fillRule='evenodd'
      d='M29.72 11.794c0 7.84-6.695 14.01-14.72 14.01S.28 19.634.28 11.794c0-3.658.69-6.683 3.572-9.373C4.42 1.89 4.986 1.379 5.621 1 6.324.58 7.03.367 7.85.263 8.607.167 9.505.162 10.585.17c.338.003.701.008 1.094.013.908.012 1.98.026 3.296.026 1.317 0 2.388-.014 3.296-.026.393-.005.756-.01 1.095-.013 1.08-.01 1.978-.004 2.733.092.82.104 1.528.318 2.23.737.636.379 1.201.89 1.77 1.42 2.866 2.677 3.621 5.688 3.621 9.374ZM15 23.884c7.07 0 12.8-5.413 12.8-12.09 0-3.364-.664-5.778-3.012-7.97-1.907-1.78-2.184-1.776-6.477-1.72-.912.012-2.006.026-3.336.026s-2.424-.014-3.336-.026c-4.292-.056-4.57-.06-6.477 1.72C2.815 6.016 2.2 8.43 2.2 11.794c0 6.677 5.73 12.09 12.8 12.09Z'
      clipRule='evenodd'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M8.375 12.483c.46-.051.8-.494.767-.953a.885.885 0 0 0-.954-.765.885.885 0 0 0-.767.952.873.873 0 0 0 .954.766ZM15.095 12.483c.46-.051.8-.494.767-.953a.885.885 0 0 0-.954-.765.885.885 0 0 0-.767.952.873.873 0 0 0 .954.766ZM18.238 7.428c.341-.038.593-.365.568-.706a.656.656 0 0 0-.707-.567.655.655 0 0 0-.567.706.646.646 0 0 0 .706.567ZM11.902 7.428c.341-.038.594-.365.568-.706a.656.656 0 0 0-.707-.567.655.655 0 0 0-.567.706.646.646 0 0 0 .706.567ZM21.815 12.483c.46-.051.8-.494.767-.953a.885.885 0 0 0-.954-.765.885.885 0 0 0-.767.952.873.873 0 0 0 .954.766Z'
      opacity={0.7}
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      fillRule='evenodd'
      d='M15.014 22.386c6.088 0 11.022-4.661 11.022-10.41 0-2.898-.572-4.977-2.593-6.864-1.642-1.533-1.881-1.53-5.577-1.481-.786.01-1.728.022-2.873.022-1.145 0-2.087-.012-2.873-.022-3.696-.048-3.935-.052-5.577 1.481C4.52 7 3.992 9.078 3.992 11.975c0 5.75 4.935 10.41 11.022 10.41ZM8.28 14.184a2.56 2.56 0 1 0 0-5.12 2.56 2.56 0 0 0 0 5.12Zm16-2.56a2.56 2.56 0 1 1-5.12 0 2.56 2.56 0 0 1 5.12 0ZM15 14.184a2.56 2.56 0 1 0 0-5.12 2.56 2.56 0 0 0 0 5.12Zm-1.504-7.392a1.664 1.664 0 1 1-3.328 0 1.664 1.664 0 0 1 3.328 0Zm4.672 1.664a1.664 1.664 0 1 0 0-3.328 1.664 1.664 0 0 0 0 3.328Zm-4.032 8.896a2.56 2.56 0 1 1-5.12 0 2.56 2.56 0 0 1 5.12 0Zm4.288 2.56a2.56 2.56 0 1 0 0-5.12 2.56 2.56 0 0 0 0 5.12Z'
      clipRule='evenodd'
    />
    <path
      fill={props.color}
      fillOpacity={0.87}
      d='M11.671 18.21c.46-.05.8-.493.767-.952a.885.885 0 0 0-.954-.765.884.884 0 0 0-.767.952.873.873 0 0 0 .954.766ZM18.519 18.21c.46-.05.8-.493.767-.952a.885.885 0 0 0-.954-.765.884.884 0 0 0-.767.952.873.873 0 0 0 .954.766Z'
      opacity={0.7}
    />
  </svg>
);
export default SvgConnectorType2;
