import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  InputAdornment,
} from '@mui/material';

import { useDomain } from '../../../hooks/useDomain';
import { rfidStyle } from '../rfidStyle';
import { ViewWrapper } from '../../ui-components/view-wrapper.component';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import card from '../../../assets/images/rfid/card.png';
import { Image } from '../../ui-components/image.component';
import errorIcon from '../../../assets/icons/error.svg';
import {
  useActivateCardMutation,
  useLazyValidateRFIDCardQuery,
} from '../../../services/rfid.api';
import { FAQComponent } from '../faqs/FAQs';
import { ROUTES } from '../../../types/routes.enum';
import CustomTextField from '../../react-native-material-wrapper/text-field.component';
import { CodeError } from '../../../constant/text.constant';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { formatRfidCardNumber } from '../../../util/regex-utility';

export const ActivateCardComponent = () => {
  const { i18n } = useTranslation();
  const [RFID, setRFID] = useState('');
  const navigate = useNavigate();
  const { domain } = useDomain();
  const classes = rfidStyle();
  const theme = useSelector(getTheme);

  const [triggerActivateCard, { isSuccess: isActivateCardSuccess, isLoading }] =
    useActivateCardMutation();

  const [
    validateRfidCard,
    {
      error: rfidCardValidationError,
      isError: isRfidCardNotValid,
      isLoading: isRFIDCardLoading,
    },
  ] = useLazyValidateRFIDCardQuery();

  const errorObj: any = rfidCardValidationError;

  useEffect(() => {
    if (isActivateCardSuccess) {
      navigate(`/app/${domain}/${ROUTES.RFID}`);
    }
  }, [isActivateCardSuccess]);

  const handleInputChange = (value) => {
    validateRfidCard({ cardNumber: value?.replace(/ /g, '') });

    setRFID(formatRfidCardNumber(value));
  };

  const handleSubmit = () => {
    triggerActivateCard(RFID);
  };

  const goBackInnavigate = () => {
    navigate(-1);
  };

  const renderRdifCardError = () => {
    if (
      errorObj?.data?.code === CodeError.ERR_US_0003 ||
      errorObj?.data?.code === CodeError.ERR_US_0004
    ) {
      return (
        <Trans
          i18nKey='rfid_valid_rfid_card_error_message'
          components={{
            br: <br />,
          }}
        />
      );
    } else if (errorObj?.data?.code === CodeError.ERR_US_0005) {
      return (
        <Trans
          i18nKey='rfid_claimed_rfid_card_error_message'
          components={{
            br: <br />,
          }}
        />
      );
    }
  };

  const isDisabled = isRFIDCardLoading || isLoading || isRfidCardNotValid;

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('rfid_activate_card')}
        onBackArrowClicked={goBackInnavigate}
      />
      <Box component='div' p={2.5}>
        <Grid container justifyContent='center'>
          <Box my={1}>
            <Image src={card} />
          </Box>
        </Grid>
        <Typography
          variant='subtitle1'
          component='span'
          color='textPrimary'
          className={classes.addressConfirmationText}
        >
          {i18n.t('rfid_enter_card_number')}
        </Typography>
        <Grid item xs={12} className={classes.paddingBottom8}>
          <CustomTextField
            className={clsx(classes.activeCardInputWrapper, classes.labelB2)}
            fullWidth
            value={RFID}
            onChange={(e) => handleInputChange(e.target.value)}
            variant='filled'
            required
            error={isRfidCardNotValid}
            placeholder={i18n.t('rfid_card_number_placeholder')}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  {isRfidCardNotValid ? (
                    <Image
                      src={errorIcon}
                      style={{ width: '20px', height: '20px' }}
                    />
                  ) : (
                    ''
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography
            variant='body1'
            component='span'
            className={classes.errorMessage}
          >
            {renderRdifCardError()}
          </Typography>
        </Grid>
        <Button
          onClick={() => handleSubmit()}
          className={`${classes.button} ${classes.orderButtonContainer}`}
          style={{
            backgroundColor: isDisabled
              ? '#F9FAFB'
              : theme.navigationSelectedColor,
            color: isDisabled ? '#D1D6DB' : '#FFF',
          }}
          variant='contained'
          color='primary'
          disabled={isDisabled}
        >
          {i18n.t('submit')}
        </Button>
        <Divider
          style={{
            background: '#F5F5F5',
            marginTop: '24px',
            marginBottom: '10px',
          }}
        />
        <FAQComponent isLost />
      </Box>
    </ViewWrapper>
  );
};
