import React, { useContext } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PaymentContext } from '../../hooks/usePayment';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { CrediCardIcon } from '../ui-components/credit-card-icon.component';
import DeleteButtons from './delete-buttons.component';
import { PaymentMode } from './types/payment-mode.enum';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '20px 16px',
    background: '#FAFAFA',
    height: 'calc(100vh - 160px)',
  },
  listItem: {
    padding: 0,
  },
  title: {
    fontSize: '18px',
    lineHeight: '26px',
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#202223',
  },
  cardNumber: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#202223',
  },
  description: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#202223',
  },
  itemText: {
    fontSize: 14,
    maxHeight: '40px',
  },
  margin24: {
    margin: '24px 0px',
  },
}));

const DeletePayment = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const paymentConsumer = useContext(PaymentContext);
  const {
    removeQueryResult,
    selectedPayment,
    handleDeletePayment,
    handlePaymentView,
  } = paymentConsumer;

  const onClickBack = () => {
    handlePaymentView(PaymentMode.PAYMENT_ADD, true);
  };

  const onDeletePayment = () => {
    handleDeletePayment();
  };

  return (
    <>
      <BackButtonHeader
        onBackArrowClicked={onClickBack}
        title={i18n.t('delete_payment_method')}
      />
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            disableTypography
            className={classes.title}
            primary={i18n.t('you_want_to_delete')}
          />
        </ListItem>
        <ListItem className={clsx([classes.listItem, classes.margin24])}>
          <ListItemIcon>
            <CrediCardIcon brand={`${selectedPayment.brand}`} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.cardNumber}
            primary={`${i18n.t('ending_in')} ${selectedPayment.last4}`}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            disableTypography
            className={classes.description}
            primary={i18n.t('no_longer_to_pay_balance')}
          />
        </ListItem>
      </List>
      <DeleteButtons
        loading={removeQueryResult.isLoading}
        isDisabled={removeQueryResult.isLoading}
        onDelete={onDeletePayment}
        onCancel={onClickBack}
      />
    </>
  );
};

export default DeletePayment;
