import { Association } from '../stores/types/association.interface';
import { Api } from './base.api';

export const associationApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    submitAssociationId: builder.mutation<Association, any>({
      query: (payload) => ({
        url: `/internal/core/v2/associations`,
        body: payload,
        method: 'POST',
      }),
    }),
    fetchAssociations: builder.query<Association, void>({
      query: () => ({
        url: `/internal/core/v2/associations?filter_eq[type]=${window.domainName}`,
        method: 'GET',
      }),
    }),
    submitAssociationData: builder.mutation<Association, any>({
      query: (payload) => ({
        url: `/internal/core/v2/associations`,
        body: payload,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useSubmitAssociationIdMutation,
  useSubmitAssociationDataMutation,
  useFetchAssociationsQuery,
} = associationApi;
