import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackOptions } from './constants';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import SubmitButtonFrame from './submit-button-frame.component';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import HappyFace from './happy-face.component';
import UnhappyFace from './unhappy-face.component';
import AverageFace from './average-face.component';
import { usePostHog } from 'posthog-js/react';

const MAX_COMMENT_LEN = 160;

interface FeedbackProps {
  selectedFeedback: FeedbackOptions;
  startTime: string;
  endTime: string;
  location: string;
  chargerName: string;
  energyDelivered: string;
  cost: string;
  transactionId: string;
  onClose: () => void;
  onSubmit: () => void;
}

const Feedback: React.FC<FeedbackProps> = ({
  selectedFeedback,
  startTime,
  endTime,
  location,
  chargerName,
  energyDelivered,
  cost,
  transactionId,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [issues, setIssues] = useState<string[]>([]);
  const [additionalComments, setAdditionalComments] = useState('');
  const posthog = usePostHog();

  const handleIssueToggle = (issue: string) => {
    setIssues((prev) =>
      prev.includes(issue) ? prev.filter((i) => i !== issue) : [...prev, issue],
    );
  };

  const feedbackCheckBoxTitle = useMemo(() => {
    if (selectedFeedback === FeedbackOptions.Happy) {
      return t('feedback_checkbox_title_happy');
    } else {
      return t('feedback_checkbox_title_unhappy_average');
    }
  }, [selectedFeedback, t]);

  const feedbackReasons = useMemo(() => {
    return selectedFeedback === FeedbackOptions.Happy
      ? []
      : [
          'feedback_reason_start_charge',
          'feedback_reason_location',
          'feedback_reason_malfunction',
          'feedback_reason_app',
        ];
  }, [selectedFeedback]);

  const handleSubmit = () => {
    posthog.capture('session_feedback', {
      transactionId: transactionId,
      charger: chargerName,
      startTime: startTime,
      endTime: endTime,
      location: location,
      energyDelivered: energyDelivered,
      cost: cost,
      satisfaction: selectedFeedback.toString(),
      reasons: issues,
      comments: additionalComments,
    });
    onSubmit();
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={t('feedback_title')}
        onBackArrowClicked={onClose}
      />
      <Box
        sx={{
          p: 3,
          bgcolor: 'white',
          borderRadius: 2,
          maxWidth: 425,
          mx: 'auto',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            textAlign: 'center',
            color: '#4E5968',
          }}
          align='center'
        >
          {t('feedback_how_today')}
        </Typography>

        <Stack direction='row' spacing={5} justifyContent='center' my={2}>
          {Object.values(FeedbackOptions).map((option) => (
            <IconButton
              key={option}
              sx={{
                width: 58,
                height: 56,
                bgcolor: 'background.paper',
                borderRadius: 4,
                border: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
                pointerEvents: 'none',
              }}
            >
              {option === FeedbackOptions.Happy && (
                <HappyFace
                  color={selectedFeedback === option ? '#7CB342' : '#B0B8C1'}
                />
              )}
              {option === FeedbackOptions.Average && (
                <AverageFace
                  color={selectedFeedback === option ? '#FFB300' : '#B0B8C1'}
                />
              )}
              {option === FeedbackOptions.Unhappy && (
                <UnhappyFace
                  color={selectedFeedback === option ? '#C62828' : '#B0B8C1'}
                />
              )}
            </IconButton>
          ))}
        </Stack>

        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '26px',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            color: '#202223',
          }}
        >
          {feedbackCheckBoxTitle}
        </Typography>
        {feedbackReasons.map((issue) => (
          <FormControlLabel
            key={issue}
            control={
              <Checkbox
                checked={issues.includes(issue)}
                onChange={() => handleIssueToggle(issue)}
              />
            }
            label={
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: '#202223',
                }}
              >
                {t(issue)}
              </Typography>
            }
          />
        ))}
        <TextField
          multiline
          rows={4}
          placeholder={t('feedback_additional_comments')}
          value={additionalComments}
          onChange={(e) => {
            const input = e.target.value;
            if (input.length <= MAX_COMMENT_LEN) {
              setAdditionalComments(e.target.value);
            }
          }}
          sx={{
            mt: '8px',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#B0B8C1',
            background: '#F2F4F6',
            width: '100%',
          }}
        />
        <Typography
          align='right'
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#6B7684',
          }}
        >
          {`${additionalComments.length}/${MAX_COMMENT_LEN}`}
        </Typography>
      </Box>
      <SubmitButtonFrame onSubmit={handleSubmit} />
    </ViewWrapper>
  );
};

export default Feedback;
