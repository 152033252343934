import { Box } from '@mui/material';
import { ChargerConnectorStatus } from './charger-connector-status.component';

export const ChargerConnector = ({
  connector,
  underMaintenance = false,
  hasMySession,
  showTechnicianIcon = false,
  numberOfPorts = 0,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: `${numberOfPorts > 1 ? 'flex-start' : 'baseline'}`,
        gap: '4px'
      }}
    >
      <ChargerConnectorStatus
        connector={connector}
        underMaintenance={underMaintenance}
        hasMySession={hasMySession}
        showTechnicianIcon={showTechnicianIcon}
      />
    </Box>
  );
};
