import { Notification } from '../stores/types/notification.interface';
import { Api, API_KEYS } from './base.api';

export const notification = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotification: builder.query<Notification, void>({
      query: () => ({
        url: `core/v1/users/me/preferences/${API_KEYS.NOTIFICATIONS}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.NOTIFICATIONS, id: 'LIST' },
      ],
    }),
    updateNotification: builder.mutation<any, Notification>({
        query: (payload) => ({
          url: `core/v1/users/me/preferences/${API_KEYS.NOTIFICATIONS}`,
          body: payload,
          method: 'PATCH',
        }),
        invalidatesTags: (result, error, arg) => [
            { type: API_KEYS.NOTIFICATIONS, id: 'LIST' },
          ],
    }),
  }),
});

export const {
  useFetchNotificationQuery,
  useUpdateNotificationMutation,
} = notification;
