import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
// import Amplify from "aws-amplify";
import App from './App';
import { Provider } from 'react-redux';
import { store } from './stores/store';
import './index.css';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

declare global {
  interface Window {
    domainName: string;
  }
}

const render = async () => {
  ReactDOM.render(
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <App />
      </Provider>
    </PostHogProvider>,
    document.getElementById('root'),
  );
};

(async () => {
  await render();
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
