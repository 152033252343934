import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const notificationStyle = makeStyles((theme: Theme) => ({
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#202223',
  },
  labelM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  sessionLabel: {
    color: '#6B7684',
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '20px',
  },
  containerClass: {
    width: '40px',
    height: '24px', 
    padding: '2px 0px 2px 0px',
  },
  containerWrapper: {
    display: 'flex', 
    justifyContent: 'space-between',
  },
  containerWrapper1: {
    display: 'flex', 
    justifyContent: 'space-between', 
    marginBottom: '8px',
  }
}));
