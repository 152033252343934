import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { DetailsStyles } from './styles/detailsStyles';

interface IProps {
  classes: ClassNameMap;
  title: string;
  amount: string;
  duration: string;
  styleClass: string;
}
const SessionListItem = ({
  title,
  classes,
  amount,
  styleClass,
  duration,
}: IProps) => {
  return (
    <Grid container className={classes.list} justifyContent='space-between'>
      <Grid item>
        <Typography className={styleClass}>{title}</Typography>
        {duration && <Typography className={styleClass}>{duration}</Typography>}
      </Grid>
      <Grid item>
        <Typography className={styleClass}>{amount}</Typography>
      </Grid>
    </Grid>
  );
};

SessionListItem.defaultProps = {
  duration: '',
};

export default withStyles(DetailsStyles)(SessionListItem);
