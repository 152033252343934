export const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: 'Inter',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#818181',
      },
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};
