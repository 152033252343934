import { CSSProperties } from 'react';

export const styles: Record<string, CSSProperties> = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
  },
  wrapper: {
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderLeftColor: "rgba(0, 0, 0, 0.12)" ,
    // borderRightColor: "rgba(0, 0, 0, 0.12)" ,
    // backgroundColor: "#F7F7F7",
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 425,
    height: '100%',
    margin: 'auto',
  },
  sectionDivider: {
    marginTop: '16px',
    borderColor: '#F7F7F7',
    borderTopWidth: 10,
    borderStyle: 'solid',
  },
  divider: {
    backgroundColor: '#F2F4F6',
    height: '8px',
  },
};

export const defaultTheme: any = {
  typography: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    h6: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '36px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  palette: {
    text: {
      primary: '#202223',
      secondary: '#6B7684',
    },
  },
};
