import { Grid, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { UserPersonalInfo } from '../../../stores/types/user.types';
import CustomTextField from '../../react-native-material-wrapper/text-field.component';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import { ViewWrapper } from '../../ui-components/view-wrapper.component';
import ActionButton from '../action-button.component';
import { userStyles } from '../styles';
import { UserMode } from '../types/user-mode.enum';
import { ClassNameMap } from '@mui/styles';

interface IProps {
  setView: Function;
  currentUser: UserPersonalInfo;
  classes: ClassNameMap;
}

export const UserName = ({ classes, setView, currentUser }: IProps) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(currentUser);
  const auth = useAuth();

  const onClickBack = () => {
    setView(UserMode.NONE);
  };

  const onSave = () => {
    auth.updateUserName(user.given_name, user.family_name);
    setView(UserMode.NONE);
  };

  const handleSaveValidation = () => {
    return !user.given_name || !user.family_name;
  };
  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('name')}
        onBackArrowClicked={onClickBack}
      />
      <Grid container className={classes.formWrapper}>
        <Grid
          xs={12}
          className={clsx(classes.paddingBottom8, classes.paddingTop)}
        >
          <Typography className={clsx(classes.labelM, classes.paddingBottom8)}>
            {i18n.t('first_name')}
          </Typography>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user.given_name}
            onChange={(e) => setUser({ ...user, given_name: e.target.value })}
            variant='filled'
            required
            autoFocus
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid
          xs={12}
          className={clsx(classes.paddingBottom8, classes.paddingTop)}
        >
          <Typography className={clsx(classes.labelM, classes.paddingBottom8)}>
            {i18n.t('last_name')}
          </Typography>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={user.family_name}
            onChange={(e) => setUser({ ...user, family_name: e.target.value })}
            variant='filled'
            required
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      </Grid>
      <ActionButton
        label={i18n.t('save')}
        action={onSave}
        isDisabled={handleSaveValidation()}
      />
    </ViewWrapper>
  );
};

export default withStyles(userStyles)(UserName);
