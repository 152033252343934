import {
  BrowserRouter,
  Link,
  Route,
  Routes as ReactRouterRoutes,
  Outlet,
  NavLink,
  useNavigate,
  useLocation,
  Navigate,
  redirect,
} from 'react-router-dom';
import React from 'react';
import { routes } from './config/routes.config';
import { ROUTES } from './types/routes.enum';
import { useAuth } from './hooks/useAuth';
import { useDomain } from './hooks/useDomain';
import { Charger } from './component/charger/charger.component';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  const { domain } = useDomain();
  if (auth.isLogined) {
    return children;
  }
  return (
    <Navigate
      to={`/app/${domain}/${ROUTES.LOGIN}`}
      state={{ from: location }}
      replace
    />
  );
};

const RenderRoute = ({ name, Component, isProtected }) => {
  let res;
  if (isProtected) {
    res = (
      <ProtectedRoute>
        <Component />
      </ProtectedRoute>
    );
  } else {
    res = <Component />;
  }
  return res;
};

const RedirectRoute = () => {
  const auth = useAuth();
  const { domain } = useDomain();
  if (auth.isLogined) {
    return <Navigate to={`/app/${domain}/${ROUTES.ACCOUNT}`} replace />;
  } else {
    return <Navigate to={`/app/${domain}/${ROUTES.LOGIN}`} replace />;
  }
};

const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRouterRoutes>
        <Route path='/app/:subdomain' element={<RedirectRoute />} />
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RenderRoute
                name={route.name}
                Component={route.component}
                isProtected={route.isProtected}
              />
            }
          />
        ))}
      </ReactRouterRoutes>
    </BrowserRouter>
  );
};

export default Routes;
