export enum ScheduleType {
  PER_DAY = "PER_DAY",
  WEEKDAYS_WEEKEND = "WEEKDAYS_WEEKEND",
  SAME_EVERYDAY = "SAME_EVERYDAY",
}

export enum RuleTypes {
  FREE = "FREE",
  TIME_OF_USE = "TIME_OF_USE",
  TIERED = "TIERED",
}

export enum PricingType {
  BILLED_BY_EFFECTIVE_CHARGING_TIME = "BILLED_BY_EFFECTIVE_CHARGING_TIME",
  BILLED_BY_TIME_PLUGGED_IN = "BILLED_BY_TIME_PLUGGED_IN",
  BILLED_BY_KWH = "BILLED_BY_KWH",
  FREE = 'FREE',
  IDLE = "IDLE_RATE"
}

export enum DurationType {
  HOUR = "HOUR",
  KWH = "KWH",
}

export enum DurationUnit {
  SECONDS = 'SECONDS',
  JOULES = 'JOULES',
}
