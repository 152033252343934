import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { CustomBuildCircleRoundedIcon } from '../../../assets/icons/icons';

interface Props {
  underMaintenance?: boolean;
  showTechnicianIcon?: boolean;
}

export const ChargerTechnicianView = ({
  underMaintenance = false,
  showTechnicianIcon = false,
}: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      {underMaintenance && showTechnicianIcon && (
        <Grid
          container
          direction='row'
          alignItems='center'
          style={{
            width: 'fit-content',
            height: '24px',
            borderRadius: '24px',
            border: '1px solid #D1D6DB',
            padding: '0px 8px',
          }}
        >
          <CustomBuildCircleRoundedIcon />
          <Typography
            style={{
              color: '#4E5968',
              paddingLeft: '4px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            {i18n.t('charger_tech_view')}
          </Typography>
        </Grid>
      )}
    </>
  );
};
