import { createStyles } from '@mui/styles';

export const ItemStyles = createStyles({
  imageWrapper: {
    width: 36,
    height: 36,
    display: 'flex',
    paddingTop: '0px',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#F2F4F6',
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#6B7684',
  },
  details: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    color: '#202223',
    display: 'flex',
    justifyContent: 'center',
  },
});
