const Bolt = (props) => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 17 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.7643 11.2725C13.8644 11.2725 11.9734 11.2725 10.0734 11.2725C10.3487 9.78553 10.6239 8.30738 10.8991 6.82044C11.2365 5.01675 11.565 3.22186 11.9023 1.41817C11.9023 1.41817 11.9023 1.41817 11.9023 1.40937C12.0266 0.731886 11.201 0.388745 10.7926 0.943049C10.0557 1.96367 9.32768 2.9843 8.59079 4.00492C7.09926 6.08137 5.60773 8.15781 4.1162 10.2255C3.21063 11.4836 2.31393 12.733 1.40836 13.9912C1.12426 14.3871 0.831282 14.7919 0.547181 15.1878C0.396252 15.399 0.245323 15.6101 0.0943947 15.8213C-0.180828 16.1996 0.192054 16.7275 0.618206 16.7275C2.51813 16.7275 4.40918 16.7275 6.30911 16.7275C6.03388 18.2145 5.75866 19.6926 5.48344 21.1796C5.14607 22.9833 4.81758 24.7782 4.48021 26.5818C4.48021 26.5818 4.48021 26.5818 4.48021 26.5906C4.35591 27.2681 5.18158 27.6113 5.58998 27.057C6.32686 26.0363 7.05487 25.0157 7.79176 23.9951C9.28329 21.9186 10.7748 19.8422 12.2664 17.7746C13.1719 16.5164 14.0686 15.267 14.9742 14.0088C15.2583 13.6129 15.5513 13.2081 15.8354 12.8122C15.9863 12.601 16.1372 12.3899 16.2882 12.1787C16.5634 11.7916 16.1905 11.2725 15.7643 11.2725Z'
      fill={props.color || '#000'}
      fill-opacity='0.87'
    />
  </svg>
);

export default Bolt;
