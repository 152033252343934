import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const RECEIPT_API_KEY = 'receipt';
export const receiptApi = createApi({
  reducerPath: RECEIPT_API_KEY,
  baseQuery: fetchBaseQuery({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    baseUrl: `/${RECEIPT_API_KEY}`,
  }),
  endpoints: (builder) => ({
    fetchReceipt: builder.query<any, string>({
      query: (receiptId: string) => ({
        url: receiptId,
        method: 'GET',
        legacyAPIOriginPath: true,
        noAuthNeeded: true,
      }),
    }),
  }),
});

export const { useFetchReceiptQuery, useLazyFetchReceiptQuery } = receiptApi;
