export const settinStyles = {
  container: {
    marginBottom: '1rem',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  primaryTitle: {
    fontSize: 16,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '24px',
    color: '#202223',
    fontWeight: 400,
  },
  secondaryTitle: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#6B7684',
    fontWeight: 400,
  },
  headingFirst: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 18,
    color: '#202223',
    lineHeight: '26px',
    fontWeight: 600,
    marginBottom: 12,
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 18,
    color: '#202223',
    lineHeight: '26px',
    fontWeight: 600,
    marginTop: 12,
    marginBottom: 12,
  },
  itemWrapper: {
    borderRadius: '12px',
    border: '1px solid #E5E8EB',
  },
  itemIcon: {
    alignSelf: 'baseline',
    marginTop: 6,
    minWidth: 32,
  },
  forwardIcon: {
    fontSize: 18,
    color: '#B0B8C1',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  topZeroMargin: {
    marginTop: 0,
  }
};
